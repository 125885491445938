import './ShopeeCorrTab.css'

import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tooltip,
  Upload,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import {
  getDingAllItems,
  getDingShopeeCorr,
  updateDingShopeeCorr,
} from '../../../actions/dingAction'

import { UploadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { uploadJJRelatedData } from '../../../actions/orderProcessAction.js'

const ShopeeItemName = ({ value }) => (
  <span className='ant-form-text'>{value}</span>
)

const { Search } = Input

const ShopeeCorrTab = ({
  uploadJJRelatedData,
  corrs,
  corrtableloading,
  getDingShopeeCorr,
  getDingAllItems,
  updateDingShopeeCorr,
  dingItems,
}) => {
  useEffect(() => {
    getDingAllItems()
  }, [getDingAllItems])
  const [jjModalOpen, setJjModal] = useState(false)
  const [jjModalForm] = Form.useForm()

  const [searchText, setSearchText] = useState('')
  const filterTextRef = useRef(null)
  const filteredCorrs = corrs.filter(
    (ob) =>
      (ob.shopeeItemName[0] && ob.shopeeItemName[0].includes(searchText)) ||
      (ob.shopeeChoiceName[0] && ob.shopeeChoiceName[0].includes(searchText)) ||
      (ob.dingItemId && ob.dingItemId.includes(searchText)) ||
      (ob.dingItemName && ob.dingItemName.includes(searchText)) ||
      (ob.packingName && ob.packingName.includes(searchText)) ||
      (ob.shopeeItemId && ob.shopeeItemId.includes(searchText)) ||
      (ob.shopeeChoiceId && ob.shopeeChoiceId.includes(searchText))
  )

  const corrDataTable = [
    {
      title: '操作',
      width: '70px',
      fixed: 'left',
      align: 'center',
      render: (text, record, index) => (
        <Button
          onClick={() => {
            jjModalForm.setFieldsValue({
              shopeeItemName: record.shopeeItemName[0],
              id: record._id,
              shopeeChoiceName: record.shopeeChoiceName[0],
              dingItemId: record.dingItemId,
              dingItemName: record.dingItemName,
              packingName: record.packingName,
              shopeeItemId: record.shopeeItemId,
              shopeeChoiceId: record.shopeeChoiceId,
              label: record.label,
            })
            setJjModal(true)
          }}
          size='small'
          type='primary'
        >
          編輯
        </Button>
      ),
    },
    {
      title: '蝦皮商品名稱',
      dataIndex: 'shopeeItemName',
      width: '290px',
      render: (text, record, index) => (
        <Tooltip
          title={`商品編號：${record.shopeeItemId}。選項編號：${record.shopeeChoiceId}。${text[0]}`}
        >
          <div className='dot-dot-text'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://shopee.tw/product/18173562/${record.shopeeItemId}`}
            >
              {text[0]}
            </a>
          </div>
        </Tooltip>
      ),
    },
    {
      title: '蝦皮選項名稱',
      dataIndex: 'shopeeChoiceName',
      width: '320px',
      render: (text, record, index) => (
        <Tooltip title={text[0]}>
          <div className='dot-dot-text'>{text[0]}</div>
        </Tooltip>
      ),
    },
    {
      title: '鼎新商品編號',
      dataIndex: 'dingItemId',
      width: '160px',
    },
    {
      title: '鼎新商品名稱',
      dataIndex: 'dingItemName',
      width: '290px',
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div className='dot-dot-text'>{text}</div>
        </Tooltip>
      ),
    },
    {
      title: '揀貨名稱',
      dataIndex: 'packingName',
      width: '1100px',
    },
  ]
  useEffect(() => {
    getDingShopeeCorr()
  }, [getDingShopeeCorr])

  const onCancelJjModal = () => {
    setJjModal(false)
  }

  const onSentJjModal = (values) => {
    updateDingShopeeCorr(values)
    setJjModal(false)
  }
  return (
    <div className='m-4 tab'>
      <Modal
        visible={jjModalOpen}
        onCancel={onCancelJjModal}
        title='修改蝦皮對應資料'
        centered
        width={750}
        footer={[
          <Button key='back' onClick={onCancelJjModal}>
            取消
          </Button>,
          <Button
            key='clearOne'
            type='primary'
            onClick={() => {
              jjModalForm
                .validateFields()
                .then((values) => {
                  let allvals = jjModalForm.getFieldsValue(true)
                  onSentJjModal({ ...allvals, ...values })
                  jjModalForm.resetFields()
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
          >
            確認修改
          </Button>,
        ]}
      >
        <Form
          colon
          form={jjModalForm}
          initialValues={jjModalOpen}
          size='small'
          requiredMark={true}
        >
          <Form.Item label='蝦皮商品名稱' name='shopeeItemName'>
            <ShopeeItemName />
          </Form.Item>
          <Form.Item label='蝦皮選項名稱' name='shopeeChoiceName'>
            <ShopeeItemName />
          </Form.Item>
          <Form.Item label='鼎新商品' name='dingItemName'>
            <Select
              showSearch
              placeholder='輸入鼎新商品名稱或編號'
              options={dingItems.map((ob) => ({
                value: ob.a1name,
                key: ob.a1id,
                a1name: ob.a1name,
                label: `${ob.a1name} @${ob.a1id}`,
              }))}
              onSelect={(text, opt) => {
                jjModalForm.setFieldsValue({ dingItemId: opt.key })
              }}
              filterOption={(inputValue, option) =>
                option.a1name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option.key.toLowerCase().includes(inputValue.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item label='揀貨名稱' name='packingName'>
            <Input placeholder='撿貨用的名稱' />
          </Form.Item>
          <Form.Item label='產品標示' name='label'>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Upload
        className='mx-3'
        name='touch_whale_excel'
        customRequest={uploadJJRelatedData}
      >
        <Button icon={<UploadOutlined />}> 上傳 jj 相關資料</Button>
      </Upload>
      <Search
        placeholder='輸入以搜尋'
        className='my-3'
        ref={filterTextRef}
        onChange={(e) => {
          filterTextRef.current.value = e.target.value
          setTimeout(() => setSearchText(e.target.value), 0)
        }}
      />
      <Table
        loading={corrtableloading}
        size='small'
        hideSelectAll
        bordered
        columns={corrDataTable}
        dataSource={filteredCorrs}
        scroll={{
          x: '1000px',
          y: '1100px',
        }}
        pagination={{ pageSize: 12 }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  corrs: state.ding.dingShopeeCorr,
  corrtableloading:
    state.orderProcess.correspondence.view.dataTableLoading ||
    state.ding.dingAllItems.loading,
  dingItems: state.ding.dingAllItems.data,
})

export default connect(mapStateToProps, {
  uploadJJRelatedData,
  getDingShopeeCorr,
  getDingAllItems,
  updateDingShopeeCorr,
})(ShopeeCorrTab)
