import React from 'react'
import { connect } from 'react-redux'
import { Table, Radio } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { changeNotGooD, deleteNotGood } from '../../actions/picking'

const NotGoodTable = ({ notgoods, changeNotGooD, deleteNotGood }) => {
  const columns = [
    {
      title: '本單問題品項',
      dataIndex: 'A1ItemName',
      width: '40%',
    },
    {
      title: '情況',
      dataIndex: 'condition',
      render: (text, record, index) => (
        <Radio.Group
          value={record.condition}
          onChange={(e) => {
            changeNotGooD('condition', record, e.target.value)
          }}
          size="small"
        >
          <Radio.Button value="ban">半價</Radio.Button>
          <Radio.Button value="sha">瑕疵</Radio.Button>
        </Radio.Group>
      ),
      width: '29%',
    },
    {
      title: '數量',
      dataIndex: 'quty',
      align: 'center',
      width: '17%',
      render: (text, record, index) => (
        <div>
          <button
            type="button"
            className="btn btn-link p-0 pr-2 buttonMP"
            onClick={() => {
              changeNotGooD('amQuty', record, -1)
            }}
          >
            <FontAwesomeIcon icon={faMinus} transform="down-1" size="sm" />
          </button>

          <input
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            className="number-picker m-0"
            value={record.quty}
            onChange={(e) => {
              changeNotGooD('setQuty', record, e.target.value)
            }}
            onBlur={(e) => {
              changeNotGooD('setQuty_comp', record, e.target.value)
            }}
          ></input>
          <button
            type="button"
            className="btn btn-link p-0 pl-2 buttonMP"
            onClick={() => {
              changeNotGooD('amQuty', record, 1)
            }}
          >
            <FontAwesomeIcon icon={faPlus} transform="up-1" size="sm" />
          </button>
        </div>
      ),
    },
    {
      title: '刪除',
      align: 'center',
      render: (text, record, index) => (
        <button
          className="operation_cell"
          onClick={() => {
            deleteNotGood(record._id)
          }}
        >
          <FontAwesomeIcon icon={faTimes} color="red" />
        </button>
      ),
      width: '14%',
    },
  ]
  return (
    <Table
      className={notgoods.length === 0 ? "auto-scroll-y empty" : "auto-scroll-y"}
      columns={columns}
      dataSource={notgoods}
      pagination={false}
      scroll={{ y: 'calc(100% - 39px)' }}
      size="small"
      bordered
    />
  )
}

NotGoodTable.propTypes = {
  changeNotGooD: PropTypes.func.isRequired,
  notgoods: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  let notgoods = state.picking.not_goods
  notgoods = notgoods.filter(
    (ob) =>
      ob.orderRecord === state.picking.record._id &&
      ob.No === state.picking.entry_on_pick.No
  )

  notgoods = notgoods.map((ob) => {
    if (ob.quty !== null && ob.quty !== undefined && !isNaN(ob.quty)) {
      return {
        ...ob,
        key: ob._id,
      }
    } else if (ob.quty === '') {
      return {
        ...ob,
        key: ob._id,
        quty: '',
      }
    } else {
      return {
        ...ob,
        key: ob._id,
        quty: 1,
      }
    }
  })
  return {
    notgoods,
  }
}

export default connect(mapStateToProps, { changeNotGooD, deleteNotGood })(
  NotGoodTable
)
