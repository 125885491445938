import React from 'react'
import StatisticPanel from '../StatisticPanel'

const Living = () => {
  return (
    <div className='scroll-page w100 py-4'>
      <StatisticPanel title='多商品比較' isMulti={true} />
      <StatisticPanel title='單商品數值觀察' isMulti={false} />
    </div>
  )
}

export default Living
