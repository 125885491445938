import React from 'react'
import { Result, Upload, Button, Image, Spin } from 'antd'
import { connect } from 'react-redux'
import { SmileOutlined, InboxOutlined } from '@ant-design/icons'
import './ItemDisplay.style.css'
import {
  uploadDingItemImagAction,
  deleteDingItemImagAction,
  updateUploadIndividualItems,
  dingItemLoaded,
} from '../../../../../actions/dingAction.js'
import fallback from './fallbackImg'

const { Dragger } = Upload

const ItemDisplay = ({
  item,
  deletingIds,
  uploadFileList,
  uploadDingItemImagAction,
  deleteDingItemImagAction,
  updateUploadIndividualItems,
  dingItemLoaded,
  loading,
}) => {
  const upLoadDingItemImag = (id) => async (ob) =>
    uploadDingItemImagAction({ ...ob, id })
  return (
    <>
      {item ? (
        <div className=' px-1 pt-3 px-md-4 pb-0 d-flex flex-column stretch-col'>
          <h4>{`${item.a1name}`}</h4>
          {item.imagKey ? (
            <>
              <div
                className={`ding-item-image ${loading ? 'image-loading' : ''}`}
              >
                <Image
                  fallback={fallback}
                  src={`https://ding-item-image.s3.us-west-000.backblazeb2.com/${item.imagKey}`}
                  // src={`https://ding-item-image.s3.ap-northeast-2.amazonaws.com/${item.ImagKey}`}
                  className={`smooth-image image-${
                    !loading ? 'visible' : 'hidden'
                  }`}
                  onLoad={dingItemLoaded}
                  onError={dingItemLoaded}
                />

                {loading && <Spin size='large' className='loader' />}
              </div>

              <Button
                danger
                className='my-1'
                type='primary'
                onClick={() => {
                  deleteDingItemImagAction(item.a1id, item.imagKey)
                }}
                loading={deletingIds.includes(item.a1id)}
                disabled={loading}
              >
                刪除圖片
              </Button>
            </>
          ) : (
            <div className='d-flex flex-column dragger-upload-pic mt-sm-4'>
              <Dragger
                name='item-imag-upload'
                customRequest={upLoadDingItemImag(item.a1id)}
                fileList={uploadFileList}
                onChange={({ fileList }) => {
                  updateUploadIndividualItems(
                    fileList.filter((ob) => ob.status !== 'done')
                  )
                }}
                // onRemove={(file)=>{
                //   let index = uploadFileList.indexOf(file)
                //   updateUploadIndividualItems(uploadFileList.splice(index, 1))
                // }}
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>上傳鼎新商品照片</p>
                <p className='ant-upload-hint'>點這邊，或是把圖片拖進來</p>
              </Dragger>
            </div>
          )}
        </div>
      ) : (
        <Result
          className='no-ding-item d-flex flex-column justify-content-center'
          icon={<SmileOutlined />}
          title='選一個鼎新商品來編輯'
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  let selectedId = state.ding.dingIndividualItemSelect.selectedId
  let items = state.ding.dingItems.data
  let item
  if (selectedId) {
    item = items.find((ob) => ob.a1id === selectedId)
  }
  return {
    item,
    deletingIds: state.ding.dingIndividualImag.deletingIds,
    uploadFileList: state.ding.dingIndividualImag.uploadFileList,
    loading: state.ding.dingIndividualImag.loading,
  }
}

export default connect(mapStateToProps, {
  uploadDingItemImagAction,
  deleteDingItemImagAction,
  updateUploadIndividualItems,
  dingItemLoaded,
})(ItemDisplay)
