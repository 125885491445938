import React from 'react'
import './ItemSelection.style.css'
import ItemSelectionTable from './ItemSelectionTable'
import ItemSelectionSearch from './ItemSelectionSearch'

const ItemSelection = () => {
  return (
    <div className='search-group-table d-flex flex-column scroll-search-table pl-1'>
      <ItemSelectionSearch />
      <ItemSelectionTable />
    </div>
  )
}

export default ItemSelection
