import { LOCATION_CHANGE } from 'connected-react-router'
import { LINK } from '../actions/types'
const initialState = {
  picking: ['nav-link'],
  vocab: ['nav-link'],
}

export default function pageNav(state = initialState, action) {
  const pagesClassDefault = {
    picking: ['nav-link'],
    vocab: ['nav-link'],
    store_data: ['nav-link'],
    purchasing: ['nav-link'],
    order_processing: ['nav-link'],
  }
  const { type, payload } = action
  switch (type) {
    case LOCATION_CHANGE:
      if (payload.location.pathname.includes('picking')) {
        return {
          ...pagesClassDefault,
          picking: ['nav-link', 'active'],
        }
      } else if (payload.location.pathname.includes('vocab')) {
        return {
          ...pagesClassDefault,
          vocab: ['nav-link', 'active'],
        }
      } else if (payload.location.pathname.includes('store_data')) {
        return {
          ...pagesClassDefault,
          store_data: ['nav-link', 'active'],
        }
      } else if (payload.location.pathname.includes('purchasing')) {
        return {
          ...pagesClassDefault,
          purchasing: ['nav-link', 'active'],
        }
      } else if (payload.location.pathname.includes('order_processing')) {
        return {
          ...pagesClassDefault,
          order_processing: ['nav-link', 'active'],
        }
      } else {
        return pagesClassDefault
      }
    case LINK:
      if (payload.includes('vocab')) {
        return {
          ...pagesClassDefault,
          vocab: ['nav-link', 'active'],
        }
      } else if (payload.includes('picking')) {
        return {
          ...pagesClassDefault,
          picking: ['nav-link', 'active'],
        }
      } else if (payload.includes('store_data')) {
        return {
          ...pagesClassDefault,
          store_data: ['nav-link', 'active'],
        }
      } else if (payload.includes('purchasing')) {
        return {
          ...pagesClassDefault,
          purchasing: ['nav-link', 'active'],
        }
      } else if (payload.includes('order_processing')) {
        return {
          ...pagesClassDefault,
          order_processing: ['nav-link', 'active'],
        }
      } else {
        return pagesClassDefault
      }
    default:
      return state
  }
}
