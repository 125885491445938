import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  PageHeader,
  Select,
  Table,
  Tooltip,
  Upload,
  message,
} from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import { UploadOutlined } from '@ant-design/icons'
import api from '../../../utils/api'
import axios from 'axios'
import { faFileArchive } from '@fortawesome/free-solid-svg-icons'

const { Option } = Select

const Dashboard = () => {
  const [keywordScheduleForm] = Form.useForm()
  const [keywordScheduleState, setKeywordScheduleState] = useState({
    loading: true,
    schedules: [],
    modalOpen: false,
    savingSchedule: false,
  })
  const fetchKeywordSchedule = useCallback(async () => {
    try {
      setKeywordScheduleState((prev) => ({
        ...prev,
        loading: true,
      }))
      const { data } = await api.get(
        'api/scrapeSchedule/keywords_to_scrape?all=true'
      )
      setKeywordScheduleState((prev) => ({
        ...prev,
        schedules: data.map((ob) => ({ ...ob, key: ob.name })),
      }))
    } catch (err) {
      message.error('有東西出錯了！等等試試。')
    } finally {
      setKeywordScheduleState((prev) => ({
        ...prev,
        loading: false,
      }))
    }
  }, [])
  useEffect(() => {
    fetchKeywordSchedule()
  }, [fetchKeywordSchedule])

  const [ratingState, setRatingState] = useState({
    validForm: false,
    maxtimes: 5,
    shopid: '',
    itemid: '',
    loading: false,
  })

  const [keywords, setKeywords] = useState([])
  const [form] = Form.useForm()
  const [keywordDownlLoading, setKeywordDownlLoading] = useState(false)
  const [keywordTimeRange, setKeywordTimeRange] = useState(undefined)
  const downloadKeyword = async (values) => {
    setKeywordDownlLoading(true)
    let options = {}
    if (keywordTimeRange) {
      options = {
        ...options,
        params: {
          timeRange: keywordTimeRange.map((ob) => ob.toISOString()),
        },
      }
    }
    try {
      await api.download(
        `api/scrapeShopee/download/keyword/${values.keyword}`,
        options
      )
    } catch (err) {
      const msg = await err?.response?.data?.text()
      message.error(msg || '有東西出錯了！等等試試。')
    } finally {
      setKeywordDownlLoading(false)
    }
  }

  const onTestLink = (linktext) => {
    const linkregex1 =
      /https?:\/\/shopee\.\w+\/.+-i\.(?<shop_id>\d+)\.(?<product_id>\d+).*/
    const linkregex2 =
      /https?:\/\/shopee.\w+\/product\/(?<shop_id>\d+)\/(?<product_id>\d+).*/

    const result = linkregex1.exec(linktext) || linkregex2.exec(linktext)

    if (!!result) {
      let shopid = result.groups['shop_id']
      let itemid = result.groups['product_id']
      setRatingState((prev) => ({
        ...prev,
        validForm: true,
        shopid,
        itemid,
      }))
    } else {
      setRatingState((prev) => ({
        ...prev,
        validForm: false,
      }))
    }
  }
  const tokenSourceRef = useRef(null)
  tokenSourceRef.current = api.source()
  const onDownloadRatingScrape = async () => {
    setRatingState((prev) => ({
      ...prev,
      loading: true,
    }))
    try {
      await api.downloadPost(
        '/api/scrapeShopee/item_ratings',
        {
          shopid: ratingState.shopid,
          itemid: ratingState.itemid,
          maxtimes: ratingState.maxtimes,
        },
        { cancelToken: tokenSourceRef.current.token }
      )
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.log('other error')
        message.error('出了一點問題，確認網路以後重新整理看看')
      } else {
        console.log('cancel')
        return
      }
    }
    setRatingState((prev) => ({
      ...prev,
      loading: false,
    }))
  }
  useEffect(() => {
    return () => {
      if (tokenSourceRef.current) {
        tokenSourceRef.current.cancel()
        console.log('canceled')
      }
    }
  }, [])

  const uploadDataCenterExcel = async ({
    file,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) => {
    const formData = new FormData()
    formData.append('purchaseSuggestionCleaned', file)
    try {
      await api.uploadAndDownload('api/purchase/upload/suggestion', formData, {
        withCredentials,
        headers,
        onUploadProgress: ({ total, loaded }) => {
          onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            file
          )
        },
      })
      onSuccess(null, file)
    } catch (error) {
      onError(error)
    }
  }

  useEffect(() => {
    const getKewords = async () => {
      let keywords_got = await api.get('api/scrapeShopee/keywords')
      keywords_got = keywords_got.data
      setKeywords(keywords_got)
      form.setFieldsValue({ keyword: keywords_got[0].name })
    }

    getKewords()
  }, [form])

  return (
    <div className='scroll-page w100 container-xl'>
      <PageHeader
        className='px-3 pb-0 mb-0 pt-4 page-inner-margin-adjust w100'
        ghost={false}
        title='爬蟲資料'
        subTitle='資料庫中的蝦皮資料'
      ></PageHeader>
      <Divider orientation='left'>關鍵字資料下載</Divider>
      <Container fluid className='d-flex justify-content-center'>
        <Form
          form={form}
          name='keyword_generate_excel'
          layout='inline'
          onFinish={downloadKeyword}
          className='ml-4'
        >
          <Form.Item name='keyword' label='爬蟲資料的關鍵字：'>
            <Select
              style={{ width: 120 }}
              className='ml-2'
              size='small'
              disabled={keywordDownlLoading}
            >
              {keywords.map((keyword) => (
                <Option key={keyword.key} value={keyword.name}>
                  {' '}
                  {keyword.name}{' '}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='timeRange' label='爬蟲資料時間範圍：'>
            <DatePicker.RangePicker
              disabled={keywordDownlLoading}
              picker='date'
              value={keywordTimeRange}
              onChange={setKeywordTimeRange}
            />
          </Form.Item>
          <Form.Item>
            <Button
              size='small'
              type='primary'
              shape='round'
              icon={
                <FontAwesomeIcon
                  className='mr-1'
                  icon={faFileArchive}
                  size='sm'
                />
              }
              loading={keywordDownlLoading}
              htmlType='submit'
            >
              下載
            </Button>
          </Form.Item>
        </Form>
      </Container>
      {/* <List
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<a href='https://ant.design'>{item.name.last}</a>}
              description='Ant Design, a design language for background applications, is refined by Ant UED Team'
            />
          </List.Item>
        )}
      /> */}
      <Divider orientation='left'> 下載蝦皮商品評價統計 </Divider>
      <Container>
        <h6>1. 請先在以下的地方貼上蝦皮商品連結，然後點擊生成測試按鈕</h6>
        <Form className='ml-4' layout='inline' size='small'>
          <Form.Item label='最多抓的筆數，每筆50個評價：'>
            <InputNumber
              disabled={ratingState.loading}
              size='small'
              min={1}
              max={100000}
              value={ratingState.maxtimes}
              onChange={(number) =>
                setRatingState((prev) => ({ ...prev, maxtimes: number }))
              }
            />
          </Form.Item>
          <Form.Item label='蝦皮商品網站連結：'>
            <Input.Search
              disabled={ratingState.loading}
              placeholder='貼上蝦皮商品網站連結'
              size='small'
              enterButton={
                <Button type='primary' disabled={ratingState.loading}>
                  生成測試
                </Button>
              }
              onSearch={onTestLink}
              allowClear
            />
          </Form.Item>
        </Form>

        <h6 className='mt-3'>
          2. 點擊以下連結確認是需要收集評價數據的蝦皮商品網址
        </h6>
        <Button
          disabled={!ratingState.validForm}
          type='link'
          href={`https://shopee.tw/product/${ratingState.shopid}/${ratingState.itemid}/`}
          target='_blank'
          loading={ratingState.loading}
        >
          {!ratingState.validForm
            ? '請先在上面 1. 的地方貼上蝦皮商品網址並按下生成測試，如果都做了，請檢查格式是否正確'
            : !ratingState.loading
            ? '請點擊連結測試'
            : '下載中'}
        </Button>
        <h6 className='mt-1'>
          3. 如果測試成功的話，就可以點擊以下按鈕下載評價統計資料
        </h6>
        <Tooltip
          title={
            !ratingState.validForm
              ? '請先在上面 1. 的地方貼上並測試蝦皮網址'
              : ''
          }
        >
          <Button
            className='ml-3'
            size='small'
            type='primary'
            shape='round'
            icon={
              <FontAwesomeIcon
                className='mr-1'
                icon={faFileArchive}
                size='sm'
              />
            }
            disabled={!ratingState.validForm}
            loading={ratingState.loading}
            htmlType='submit'
            onClick={onDownloadRatingScrape}
          >
            {!ratingState.loading
              ? '下載'
              : '下載中，每5筆大概需要2分鐘，離開此頁面會取消此行動'}
          </Button>
        </Tooltip>
      </Container>
      <Divider orientation='left'>每日關鍵字爬蟲排程</Divider>

      <Modal
        title='新增一個關鍵字'
        visible={keywordScheduleState.modalOpen}
        onCancel={() =>
          setKeywordScheduleState((prev) => ({
            ...prev,
            modalOpen: false,
          }))
        }
        onOk={async () => {
          try {
            await keywordScheduleForm.validateFields()
          } catch (err) {
            console.log('Validate Failed:', err)
            return
          }

          try {
            setKeywordScheduleState((prev) => ({
              ...prev,
              savingSchedule: true,
            }))
            await api.put('api/scrapeSchedule/keywords_to_scrape', [
              keywordScheduleForm.getFieldsValue(true),
            ])
            keywordScheduleForm.resetFields()
            setKeywordScheduleState((prev) => ({
              ...prev,
              modalOpen: false,
            }))
            fetchKeywordSchedule()
          } catch (err) {
            console.log(err)
            message.error('有東西出錯了！等等試試。')
          } finally {
            setKeywordScheduleState((prev) => ({
              ...prev,
              savingSchedule: false,
            }))
          }
        }}
      >
        <Form form={keywordScheduleForm}>
          <Form.Item
            name='name'
            label='新關鍵字'
            initialValue=''
            rules={[
              {
                required: true,
                message: '請輸入關鍵字',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='periodInDays' label='每幾天跑一次' initialValue={1}>
            <InputNumber min={1} />
          </Form.Item>
        </Form>
      </Modal>

      <Button
        style={{
          width: '100px',
          marginBottom: '20px',
        }}
        type='primary'
        onClick={() =>
          setKeywordScheduleState((prev) => ({ ...prev, modalOpen: true }))
        }
      >
        新增關鍵字
      </Button>
      <Table
        size='small'
        columns={[
          {
            key: 'name',
            dataIndex: 'name',
            title: '關鍵字',
          },
          {
            key: 'periodInDays',
            dataIndex: 'periodInDays',
            title: '幾天跑一次',
          },
          {
            key: 'lastRun',
            dataIndex: 'lastRun',
            title: '上次成功的時間',
            render: (text, record) => (
              <Moment format='YYYY/MM/DD'>{record.lastRun}</Moment>
            ),
          },
          {
            key: '操作',
            title: '操作',
            render: (_, record) => (
              <Button
                size='small'
                danger
                onClick={async () => {
                  try {
                    await api.delete('api/scrapeSchedule/keywords_to_scrape', [
                      record.name,
                    ])
                    fetchKeywordSchedule()
                  } catch (err) {
                    console.log(err)
                    message.error('沒有刪除成功')
                  }
                }}
              >
                刪除
              </Button>
            ),
          },
        ]}
        dataSource={keywordScheduleState.schedules}
        loading={keywordScheduleState.loading}
      />
      <Divider orientation='left'> Touch Whale 追蹤</Divider>

      <Container fluid className='d-flex justify-content-center mt-2 mb-5'>
        <Upload name='touch_whale_excel' customRequest={uploadDataCenterExcel}>
          <Button icon={<UploadOutlined />}> 上傳數據中心昨日商品表現</Button>
        </Upload>
      </Container>
    </div>
  )
}

export default Dashboard
