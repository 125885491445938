import React from 'react'
import { connect } from 'react-redux'
import { Table, Descriptions } from 'antd'
import PropTypes from 'prop-types'
import { switchEntry } from '../../actions/picking'
import { GiftTwoTone } from '@ant-design/icons'

const selected = 'ant-table-row-selected'

const columns = [
  {
    title: '訂單內容',
    dataIndex: 'A1ItemName',
    width: '80%',
    render: (text, record) => (
      <div>
        {record.giftOriginId ? (
          <GiftTwoTone
            className='picking-record-gift-icon mr-1'
            twoToneColor='#eb2f96'
          />
        ) : null}
        {text}
      </div>
    ),
  },
  {
    title: '數量',
    dataIndex: 'Qty',
    align: 'center',
    width: '20%',
  },
]

const newTabLink = (e, link) => {
  e.preventDefault()
  window.open(link)
}

const DataTable = ({ entries, switchEntry }) => {
  return (
    <Table
      className='auto-scroll-y'
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            // let data_to_change = data.find((ob) => ob.key === record.key)
            // let newdata = [...data]
            // let newClassName = ''
            // if (record.className === selected) {
            //   newClassName = ''
            // } else {
            //   newClassName = selected
            // }
            // newdata = newdata.map((ob) => ({ ...ob, className: '' }))
            // newdata.splice(rowIndex, 1, {
            //   ...data_to_change,
            //   className: newClassName,
            // })
            switchEntry(record)
          }, // click row
          onDoubleClick: (event) => {}, // double click row
          onContextMenu: (event) => {}, // right button click row
          onMouseEnter: (event) => {}, // mouse enter row
          onMouseLeave: (event) => {}, // mouse leave row
        }
      }}
      rowClassName={(record, index) => {
        return record.className
      }}
      columns={columns}
      dataSource={entries}
      expandable={{
        expandedRowRender: (record) => (
          <Descriptions size='small'>
            <Descriptions.Item label='連結' span={3}>
              <div
                type='button'
                onClick={(e) =>
                  newTabLink(
                    e,
                    record.WebItemID
                      ? 'https://shopee.tw/product/18173562/' + record.WebItemID
                      : encodeURI(
                          `https://shopee.tw/search?keyword=${record.ProductName}`
                        )
                  )
                }
              >
                蝦皮商品連結
              </div>
            </Descriptions.Item>
            <Descriptions.Item label='蝦皮商品名稱' span={3}>
              {record.ProductName}
            </Descriptions.Item>
            <Descriptions.Item label='蝦皮商品選項' span={3}>
              {record.Spec}
            </Descriptions.Item>
          </Descriptions>
        ),
        rowExpandable: (record) => !record.giftOriginId,
      }}
      pagination={false}
      scroll={{ y: 'calc(100% - 39px)' }}
      size='small'
      bordered
    />
  )
}

DataTable.propTypes = {
  entries: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  let entry_on_pick = state.picking.entry_on_pick
  let onNo = entry_on_pick.No
  let entries = state.picking.entries.filter((ob) => ob.No === onNo)
  entries = entries.map((ob) => {
    let className = ''
    if (ob._id === entry_on_pick._id) {
      className = selected
    }
    if (typeof ob.A1ItemName === 'string') {
      return {
        ...ob,
        key: ob._id,
        className,
      }
    } else {
      return {
        ...ob,
        key: ob._id,
        A1ItemName: '鼎新尚未對應：' + ob.Spec,
        className,
      }
    }
  })

  return {
    entries,
  }
}

export default connect(mapStateToProps, { switchEntry })(DataTable)
