import api from '../utils/api'
import {
  DOWNLOAD_PURCHASE_SUGGESTION,
  START_DOWNLOAD_PURCHASE_SUGGESTION,
} from './types'
import { message } from 'antd'
import { AxiosError } from 'axios'

const config = {
  'Comtent-Type': 'application/json',
}

export const downloadPurchaseSuggestion = (range) => async (dispatch) => {
  try {
    dispatch({ type: START_DOWNLOAD_PURCHASE_SUGGESTION })
    await api.downloadPost(
      'api/purchase/download/suggestion',
      { range },
      config
    )
    dispatch({ type: DOWNLOAD_PURCHASE_SUGGESTION })
  } catch (err) {
    let errMessage = err.message
    if (err instanceof AxiosError) {
      errMessage = await err.response.data.text()
      console.error(errMessage)
    }
    message.error(`出現了以下問題：${errMessage}`, 15)
    console.log(err)
    dispatch({ type: DOWNLOAD_PURCHASE_SUGGESTION })
  }
}
