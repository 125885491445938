import React from 'react'
import { Route, Switch, NavLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import Suggest from './pages/Suggest.component'
import DingItem from './pages/DingItemPage/DingItem.component'
import './PurchasingPage.style.css'

const PurchasingPage = ({ match }) => {
  let location = useLocation()
  let tabRegs = new RegExp(`${match.path}\\/(?<path>\\w+)`)
  let path = tabRegs.exec(location.pathname).groups.path
  return (
    <div className='d-flex flex-column purchasing-page'>
      <Menu selectedKeys={[path]} mode='horizontal' className='inner-menu'>
        <Menu.Item key='suggest'>
          <NavLink
            className='nav-link'
            to={`${match.path}/suggest`}
            activeClassName='active'
          >
            建議資料
          </NavLink>
        </Menu.Item>
        <Menu.Item key='dingItem'>
          <NavLink
            className='nav-link'
            to={`${match.path}/dingItem`}
            activeClassName='active'
          >
            鼎新商品
          </NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path={`${match.path}/suggest`} component={Suggest} />
        <Route path={`${match.path}/dingItem/:a1id`} component={DingItem} />
        <Route path={`${match.path}/dingItem`} component={DingItem} />
      </Switch>
    </div>
  )
}

export default PurchasingPage
