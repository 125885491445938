// import { } from '../actions/types'
import { LOCATION_CHANGE } from 'connected-react-router'
import {
  TEST,
  DELETE_KEYWORD,
  GET_SCRAPERAPIS,
  DELETE_SCRAPERAPI,
  NEW_SCRAPERAPI,
  UPDATE_SCRAPERAPI,
} from '../actions/types'
const initialState = {
  test: {
    response: {},
  },
  manage: {
    keyword_records: [],
    loading: true,
    scraper_apis: [],
    living_records: [],
  },
}

export default function scrape(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LOCATION_CHANGE:
      // clear data if jump to other block
      if (!payload.location.pathname.includes('picking')) {
        return {
          ...state,
        }
      } else {
        return state
      }
    case TEST:
      return {
        ...state,
        test: {
          ...state.test,
          response: payload,
        },
      }
    case DELETE_KEYWORD:
      return {
        ...state,
        manage: {
          ...state.manage,
          keyword_records: state.manage.keyword_records.filter(
            (ob) => ob.keyword !== payload
          ),
        },
      }
    case GET_SCRAPERAPIS:
      return {
        ...state,
        manage: {
          ...state.manage,
          scraper_apis: payload,
          loading: false,
        },
      }
    case NEW_SCRAPERAPI:
      return {
        ...state,
        manage: {
          ...state.manage,
          scraper_apis: [...state.manage.scraper_apis, payload],
        },
      }
    case UPDATE_SCRAPERAPI:
      return {
        ...state,
        manage: {
          ...state.manage,
          scraper_apis: state.manage.scraper_apis.map((ob) => {
            if (ob._id === payload._id) {
              return payload
            } else {
              return ob
            }
          }),
        },
      }
    case DELETE_SCRAPERAPI:
      return {
        ...state,
        manage: {
          ...state.manage,
          scraper_apis: state.manage.scraper_apis.filter(
            (ob) => ob._id !== payload
          ),
        },
      }
    default:
      return state
  }
}
