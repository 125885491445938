import {
  GET_DING_SHOPEE_ORDER,
  UPLOAD_DING_ITEM_IMAG_SUCCESS,
  DELETE_DING_ITEM_IMAG,
  SUCCESS_DELETE_DING_ITEM_IMAG,
  START_GET_SHOPEE_ORDER_FORM_DING,
  GET_DING_INDIVIDUAL_ITEMS,
  SUCCESS_GET_DING_INDIVIDUAL_ITEMS,
  ERROR_GET_DING_INDIVIDUAL_ITEMS,
  GET_DING_ALL_ITEMS,
  SUCCESS_GET_DING_ALL_ITEMS,
  ERROR_GET_DING_ALL_ITEMS,
  SUCCESS_REQUEST_DING_SHOPEE_CORR,
  SUCCESS_UPDATE_DING_ITEM_SHOPEE_CORR,
  SEARCH_DING_INDIVIDUAL_ITEMS,
  SELECT_DING_INDIVIDUAL_ITEMS,
  UPLOAD_DING_ITEM_IMAG,
  DING_ITEM_IMAG_LOADED,
} from '../actions/types'
const initialState = {
  shopee_orders: { loading: false },
  dingIndividualItemSelect: { searchText: '', selectedId: '' },
  dingItems: { loading: true, data: [] },
  dingAllItems: { loading: true, data: [] },
  dingIndividualImag: { deletingIds: [], uploadFileList: [], loading: true },
  dingShopeeCorr: [],
  test_json: {},
}

export default function dingReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case DING_ITEM_IMAG_LOADED:
      return {
        ...state,
        dingIndividualImag: { ...state.dingIndividualImag, loading: false },
      }
    case START_GET_SHOPEE_ORDER_FORM_DING:
      return {
        ...state,
        shopee_orders: { ...state.shopee_orders, loading: true },
      }
    case GET_DING_SHOPEE_ORDER:
      return {
        ...state,
        shopee_orders: { ...state.shopee_orders, loading: false },
      }
    case SEARCH_DING_INDIVIDUAL_ITEMS:
      if (payload) {
        return {
          ...state,
          dingIndividualItemSelect: {
            ...state.dingIndividualItemSelect,
            searchText: payload,
          },
        }
      } else {
        return {
          ...state,
          dingIndividualItemSelect: {
            ...state.dingIndividualItemSelect,
            searchText: '',
          },
        }
      }
    case SELECT_DING_INDIVIDUAL_ITEMS:
      if (payload === state.dingIndividualItemSelect.selectedId) {
        return state
      }
      if (payload) {
        return {
          ...state,
          dingIndividualItemSelect: {
            ...state.dingIndividualItemSelect,
            selectedId: payload,
          },
          dingIndividualImag: { ...state.dingIndividualImag, loading: true },
        }
      } else {
        return {
          ...state,
          dingIndividualItemSelect: {
            ...state.dingIndividualItemSelect,
            selectedId: '',
          },
          dingIndividualImag: { ...state.dingIndividualImag, loading: false },
        }
      }

    case GET_DING_INDIVIDUAL_ITEMS:
      return {
        ...state,
        dingItems: { ...state.dingItems, loading: true },
      }
    case SUCCESS_GET_DING_INDIVIDUAL_ITEMS:
      return {
        ...state,
        dingItems: { ...state.dingItems, data: payload, loading: false },
      }
    case ERROR_GET_DING_INDIVIDUAL_ITEMS:
      return {
        ...state,
        dingItems: { ...state.dingItems, loading: false },
      }
    case GET_DING_ALL_ITEMS:
      return {
        ...state,
        dingAllItems: { ...state.dingAllItems, loading: true },
      }
    case SUCCESS_GET_DING_ALL_ITEMS:
      return {
        ...state,
        dingAllItems: { ...state.dingAllItems, data: payload, loading: false },
      }
    case ERROR_GET_DING_ALL_ITEMS:
      return {
        ...state,
        dingAllItems: { ...state.dingAllItems, loading: false },
      }
    case UPLOAD_DING_ITEM_IMAG:
      return {
        ...state,
        dingIndividualImag: {
          ...state.dingIndividualImag,
          uploadFileList: payload,
        },
      }
    case UPLOAD_DING_ITEM_IMAG_SUCCESS:
      let { a1id, imagKey } = payload
      return {
        ...state,
        dingItems: {
          ...state.dingItems,
          data: state.dingItems.data.map((ob) =>
            ob.a1id === a1id ? { ...ob, imagKey } : { ...ob }
          ),
        },
        dingIndividualImag: { ...state.dingIndividualImag, loading: true },
      }
    case DELETE_DING_ITEM_IMAG:
      return {
        ...state,
        dingIndividualImag: {
          ...state.dingIndividualImag,
          deletingIds: [...state.dingIndividualImag.deletingIds, payload],
          loading: true,
        },
      }
    case SUCCESS_DELETE_DING_ITEM_IMAG:
      return {
        ...state,
        dingItems: {
          ...state.dingItems,
          data: state.dingItems.data.map((ob) =>
            ob.a1id === payload.a1id ? { ...ob, imagKey: undefined } : { ...ob }
          ),
        },
        dingIndividualImag: {
          ...state.dingIndividualImag,
          deletingIds: state.dingIndividualImag.deletingIds.filter(
            (ob) => ob !== payload.a1id
          ),
          loading: false,
        },
      }
    case SUCCESS_REQUEST_DING_SHOPEE_CORR:
      return {
        ...state,
        dingShopeeCorr: payload.map((ob) => ({
          ...ob,
          key: ob._id,
        })),
      }
    case SUCCESS_UPDATE_DING_ITEM_SHOPEE_CORR:
      return {
        ...state,
        dingShopeeCorr: state.dingShopeeCorr.map((ob) =>
          ob.key === payload._id
            ? {
                ...payload,
                key: payload._id,
              }
            : { ...ob }
        ),
      }
    default:
      return state
  }
}
