import React, { useState, useEffect, useCallback } from 'react'
import { Spin, message, List, Checkbox } from 'antd'
import api from '../../../utils/api'

export default function GiftEventToggle() {
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState(true)
  const getEvents = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(
        'api/orderProcess/events/items?withoutItem=true'
      )
      setEvents(data.sort((a, b) => b.onGoing - a.onGoing))
      setLoading(false)
    } catch (err) {
      setLoading(false)
      message.error('出錯了，通知一下智圓' + err)
    }
  }, [])
  const handleToggleEvent = async (id, onGoing) => {
    try {
      const { data } = await api.patch(`api/orderProcess/events/toggle/${id}`, {
        onGoing,
      })
      const updatedEvent = data.find((d) => d.id === id)
      setEvents((events) => events.map((e) => (e.id === id ? updatedEvent : e)))
      message.success('更新成功')
    } catch (err) {
      message.error('出錯了，通知一下智圓' + err)
    }
  }
  useEffect(() => {
    getEvents()
  }, [getEvents])
  if (loading) {
    return (
      <div className='tab-tab'>
        <Spin className='spin-page' size='large' />
      </div>
    )
  }

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <List
        className='px-3 py-3'
        dataSource={events}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              title={
                <span>
                  {item.name}
                  <Checkbox
                    className='ml-2'
                    checked={item.onGoing}
                    onChange={(e) =>
                      handleToggleEvent(item.id, e.target.checked)
                    }
                  />
                </span>
              }
              description={item.description}
            />
          </List.Item>
        )}
      />
    </div>
  )
}
