import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  PageHeader,
  Select,
  Table,
  Tooltip,
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import {
  deleteKeyword,
  deleteScraperApi,
  getScraperApis,
  newScraperApi,
  updateScraperApi,
} from '../../../actions/scrape'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import api from '../../../utils/api'
import { connect } from 'react-redux'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const { Option } = Select

const Manage = ({
  deleteKeyword,
  getScraperApis,
  loading,
  newScraperApi,
  updateScraperApi,
  deleteScraperApi,
  scraper_apis,
}) => {
  /* ScraperApi */
  useEffect(() => {
    getScraperApis()
  }, [getScraperApis])

  /* Keyword Scraper Records */
  const [keywordScraperRecordsLoading, setKeywordScraperRecordsLoading] =
    useState(true)
  const [keywordScraperRecords, setKeywordScraperRecords] = useState([])
  const [keywordScraperRecordQueryParams, setKeywordScraperRecordQueryParams] =
    useState({
      total: 0,
      current: 1,
      pageSize: 10,
      showSizeChanger: false,
    })
  const {
    pageSize: keywordScraperRecordsPageSize,
    current: keywordScraperRecordsCurrent,
  } = keywordScraperRecordQueryParams
  const fetchKeywordScraperRecords = useCallback(async () => {
    try {
      setKeywordScraperRecordsLoading(true)
      const {
        data: { total, records },
      } = await api.get('api/scrapeShopee/keyword/records', {
        params: {
          pageSize: keywordScraperRecordsPageSize,
          current: keywordScraperRecordsCurrent,
        },
      })
      setKeywordScraperRecords(records)
      setKeywordScraperRecordQueryParams((prev) => ({
        ...prev,
        total,
      }))
    } catch (err) {
      console.error('Get Keyword Scraper Record Error:', err)
      console.assert('Something went wrong open devTool to see error.')
    } finally {
      setKeywordScraperRecordsLoading(false)
    }
  }, [keywordScraperRecordsPageSize, keywordScraperRecordsCurrent])
  useEffect(() => {
    fetchKeywordScraperRecords()
  }, [fetchKeywordScraperRecords])
  const handleKeywordScraperRecordTableChange = (pagination) =>
    setKeywordScraperRecordQueryParams(pagination)
  const deleteKeywordScraperRecord = async (id) => {
    try {
      setKeywordScraperRecordsLoading(true)
      await api.delete(`api/scrapeShopee/keyword/records/${id}`)
      await fetchKeywordScraperRecords()
    } catch (err) {
      console.error('Delete Keyword Scraper Record Error:', err)
      console.assert('Something went wrong open devTool to see error.')
    } finally {
      setKeywordScraperRecordsLoading(false)
    }
  }

  /* Living Scraper Records */
  const [livingRecordsLoading, setLivingRecordsLoading] = useState(true)
  const [livingRecords, setLivingRecords] = useState([])
  const [livingRecordsQueryParams, setLivingRecordsQueryParams] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  })
  const { pageSize: livingRecordsPageSize, current: livingRecordsCurrent } =
    livingRecordsQueryParams
  const fetchLivingRecords = useCallback(async () => {
    try {
      setLivingRecordsLoading(true)
      const {
        data: { total, records },
      } = await api.get('api/scrapeShopee/living/records', {
        params: {
          pageSize: livingRecordsPageSize,
          current: livingRecordsCurrent,
        },
      })
      setLivingRecords(records)
      setLivingRecordsQueryParams((prev) => ({
        ...prev,
        total,
      }))
    } catch (err) {
      console.error('Get Living Scraper Record Error:', err)
      console.assert('Something went wrong open devTool to see error.')
    } finally {
      setLivingRecordsLoading(false)
    }
  }, [livingRecordsPageSize, livingRecordsCurrent])
  useEffect(() => {
    fetchLivingRecords()
  }, [fetchLivingRecords])
  const handleLivingRecordsTableChange = (pagination) =>
    setLivingRecordsQueryParams(pagination)
  const deleteLivingRecords = async (id) => {
    try {
      setLivingRecordsLoading(true)
      await api.delete(`api/scrapeShopee/living/record/${id}`)
      await fetchLivingRecords()
    } catch (err) {
      console.error('Delete Living Scraper Record Error:', err)
      console.assert('Something went wrong open devTool to see error.')
    } finally {
      setLivingRecordsLoading(false)
    }
  }

  /* Delete Keyword Scraper List */
  const [keywordsLoading, setKeywordsLoading] = useState(true)
  const [keywords, setKeywords] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    const getKeywords = async () => {
      try {
        setKeywordsLoading(true)
        let keywords_got = await api.get('api/scrapeShopee/keywords')
        keywords_got = keywords_got.data
        setKeywords(keywords_got)
        form.setFieldsValue({ keyword: keywords_got[0].name })
      } catch (err) {
        console.error('Get Keyword Error:', err)
        console.assert('Something went wrong open devTool to see error.')
      } finally {
        setKeywordsLoading(false)
      }
    }
    getKeywords()
  }, [form])

  const apiModalDefaultState = {
    open: false,
    type: 'new',
    id: '',
  }

  const [apiModalState, setApiModal] = useState(apiModalDefaultState)

  const [apiModalForm] = Form.useForm()

  const apiModalInit = {
    account: '',
    key: '',
    next_renew_date: moment().add(1, 'month'),
    used_number: 0,
    monthly_limit: 1000,
  }

  const onApiModalOpen = (type, api) => {
    if (type !== 'new') {
      apiModalForm.setFieldsValue({
        ...api,
        next_renew_date: moment(api.next_renew_date),
      })
    } else {
      apiModalForm.setFieldsValue(apiModalInit)
    }
    setApiModal({
      type,
      open: true,
      id: api._id,
    })
  }

  const onCancelApiModal = () => {
    setApiModal(apiModalDefaultState)
  }

  const onSentApiModal = (type, api) => {
    if (type === 'new') {
      newScraperApi(api)
      setApiModal({
        type,
        open: false,
      })
    } else {
      updateScraperApi(api._id, api)
      setApiModal({
        type,
        open: false,
      })
    }
  }

  const apiColumns = [
    {
      title: 'API 用的帳號',
      dataIndex: 'account',
      key: 'account',
      width: '23%',
      render: (text, record) =>
        record.current_using ? (
          <Tooltip title='Last used'>
            <div className='text-success'>{text}</div>
          </Tooltip>
        ) : (
          text
        ),
    },
    {
      title: 'API 金鑰',
      dataIndex: 'key',
      key: 'key',
      width: '23%',
      render: (text, record) => text.slice(0, 8) + '...' + text.slice(-3),
    },
    {
      title: '使用額度',
      render: (text, record) =>
        `${record.used_number} / ${record.monthly_limit}`,
      width: '16%',
    },
    {
      title: '下次更新日期',
      dataIndex: 'current_renew_date',
      key: 'current_renew_date',
      render: (text, record) => (
        <Moment format='YYYY/MM/DD'>{record.next_renew_date}</Moment>
      ),
      width: '18%',
    },
    {
      title: '操作',
      render: (text, record) => (
        <div>
          <Button
            size='small'
            className='mr-3'
            onClick={() => deleteScraperApi(record._id)}
            danger
          >
            刪除
          </Button>
          <Button size='small' onClick={() => onApiModalOpen('old', record)}>
            編輯
          </Button>
        </div>
      ),
      width: '20%',
    },
  ]
  const recordColumns = [
    {
      title: '關鍵字',
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: '時間',
      dataIndex: 'date',
      key: 'date',
      width: '35%',
      render: (text, record) => (
        <Moment format='YYYY/MM/DD HH:mm:ss'>{record.date}</Moment>
      ),
    },
    {
      title: '操作',
      width: '15%',
      render: (text, record) => (
        <Button
          size='small'
          onClick={() => deleteKeywordScraperRecord(record._id)}
          danger
        >
          刪除
        </Button>
      ),
    },
  ]
  const livingRecordColumns = [
    {
      title: '時間',
      dataIndex: 'createdAt',
      key: 'time',
      render: (text, record) => (
        <Moment format='YYYY/MM/DD HH:mm:ss'>{record.createdAt}</Moment>
      ),
    },
    {
      title: '爬到的資料量',
      width: '35%',
      dataIndex: 'successEntryNumber',
    },
    {
      title: '操作',
      width: '15%',
      render: (text, record) => (
        <Button
          size='small'
          onClick={() => deleteLivingRecords(record._id)}
          danger
        >
          刪除
        </Button>
      ),
    },
  ]
  return (
    <div className='scroll-page w100 container-xl '>
      <PageHeader
        className='px-3 pb-0 mb-0 pt-4 pb-3 page-inner-margin-adjust w100'
        ghost={false}
        title='管理爬蟲'
        subTitle='爬蟲訓練師的控制頁面'
      ></PageHeader>
      <Divider orientation='left'> ScraperApi 管理</Divider>
      <Button
        onClick={() => {
          onApiModalOpen('new', {})
        }}
        className='mb-3 mx-4'
        size='small'
        type='primary'
      >
        新增 Key
      </Button>
      <Modal
        visible={apiModalState.open}
        onCancel={onCancelApiModal}
        title={
          apiModalState.type === 'new' ? '新增 ScraperApi' : '修改 ScraperApi'
        }
        centered
        footer={[
          <Button key='back' onClick={onCancelApiModal}>
            取消
          </Button>,
          <Button
            key='clearOne'
            type='primary'
            onClick={() => {
              apiModalForm
                .validateFields()
                .then((values) => {
                  onSentApiModal(apiModalState.type, {
                    ...values,
                    _id: apiModalState.id,
                  })
                  apiModalForm.resetFields()
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
          >
            {apiModalState.type === 'new' ? '新增' : '修改'}
          </Button>,
        ]}
      >
        <Form
          form={apiModalForm}
          layout='vertical'
          initialValues={apiModalInit}
          size='small'
          // onValuesChange={onRequiredTypeChange}
          requiredMark={true}
        >
          <Form.Item
            label='帳號'
            name='account'
            rules={[{ required: true, message: '要輸入帳號呦～～' }]}
          >
            <Input placeholder='Google 帳號' />
          </Form.Item>
          <Form.Item
            label='金鑰'
            name='key'
            rules={[{ required: true, message: '要輸入金鑰呦～～' }]}
          >
            <Input placeholder='API key' />
          </Form.Item>
          <Form.Item label='下次更新時間' name='next_renew_date'>
            <DatePicker />
          </Form.Item>
          <Form.Item label='已使用次數' name='used_number'>
            <InputNumber />
          </Form.Item>
          <Form.Item label='每月使用額度' name='monthly_limit'>
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<Button type='link'>沒有金鑰，按一下來新增</Button>}
            onClick={() => {
              onApiModalOpen('new', {})
            }}
          ></Empty>
        )}
      >
        <Table
          dataSource={scraper_apis}
          size='small'
          columns={apiColumns}
          loading={loading}
        />
      </ConfigProvider>
      <Divider orientation='left'> 關鍵字記錄管理</Divider>
      <Table
        dataSource={keywordScraperRecords}
        size='small'
        columns={recordColumns}
        loading={keywordScraperRecordsLoading}
        pagination={keywordScraperRecordQueryParams}
        onChange={handleKeywordScraperRecordTableChange}
      />
      <Divider orientation='left'> 居家生活紀錄</Divider>
      <Table
        dataSource={livingRecords}
        size='small'
        columns={livingRecordColumns}
        loading={livingRecordsLoading}
        pagination={livingRecordsQueryParams}
        onChange={handleLivingRecordsTableChange}
      />
      <Divider orientation='left'> 關鍵字完全清除</Divider>
      <Form
        form={form}
        name='keyword_generate_excel'
        layout='inline'
        onFinish={(val) => deleteKeyword(val.keyword)}
        className='ml-4 pb-2 mb-5'
      >
        <Form.Item name='keyword' label='爬蟲資料的關鍵字：'>
          <Select
            style={{ width: 120 }}
            className='ml-2'
            size='small'
            loading={keywordsLoading}
          >
            {keywords.map((keyword) => (
              <Option key={keyword.key} value={keyword.name}>
                {' '}
                {keyword.name}{' '}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            size='small'
            type='primary'
            shape='round'
            icon={
              <FontAwesomeIcon className='mr-1' icon={faTrashAlt} size='sm' />
            }
            htmlType='submit'
            disabled={keywordsLoading}
            danger
          >
            刪除
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.store_data.manage.loading,
    scraper_apis: state.store_data.manage.scraper_apis,
  }
}

Manage.propTypes = {
  getScraperApis: PropTypes.func.isRequired,
  newScraperApi: PropTypes.func.isRequired,
  updateScraperApi: PropTypes.func.isRequired,
  deleteScraperApi: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  deleteKeyword,
  getScraperApis,
  newScraperApi,
  updateScraperApi,
  deleteScraperApi,
})(Manage)
