import { LINK } from './types'

export const linkTo = (path) => (dispatch) => {
  try {
    dispatch({ type: LINK, payload: path })
  } catch (err) {
    // dispatch({
    //   type: PICKING_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // })
    console.log(err)
  }
}
