import './GiftEventStep.css'

import { Card, Checkbox, Col, Form, Row, Select } from 'antd'

import React from 'react'
import { connect } from 'react-redux'
import { renewGiftSet } from '../../../../actions/orderProcessAction'

const GiftEventStep = ({
  hook_event,
  stainless,
  glass_single_main,
  glass_single_two_one,
  cut,
  tau_get_tau,
  tau_get_glass,
  glass_comb_get_glove,
  straw_glove_get_box,
  renewGiftSet,
  glass_comb_give_tau_every,
  wash_get_storage,
  wan_wan_get_two,
  tau_n_get_n,
  glass_comb_tau_and_box,
  pandora,
  nano_sponge,
  cup_get_sticker,
  cup_get_bottom_tau,
  evey_tau_get_box,
  silicon_get_cut,
  jp_mask_box_get_mask_clip,
  random_three_aroma_clips,
  eight_clips_get_oil,
  four_glasses_get_two_brushes,
  two_get_smile_box,
  three_get_smile_box,
  dia_12mm_big_glove_get_silicon_straw,
  dia_14mm_big_glove_get_silicon_straw,
  small_glove_get_silicon_straw,
  glass_comb_extra,
  no_accumulate_four_glasses_get_two_brushes,
  four_glass_get_bag,
  four_glass_get_edge_tau,
}) => {
  let event = {
    hook_event,
    stainless,
    glass_single_main,
    glass_single_two_one,
    cut,
    glass_comb_get_glove,
    straw_glove_get_box,
    tau_get_tau,
    tau_get_glass,
    glass_comb_give_tau_every,
    wash_get_storage,
    wan_wan_get_two,
    tau_n_get_n,
    glass_comb_tau_and_box,
    pandora,
    nano_sponge,
    cup_get_sticker,
    cup_get_bottom_tau,
    evey_tau_get_box,
    silicon_get_cut,
    jp_mask_box_get_mask_clip,
    random_three_aroma_clips,
    eight_clips_get_oil,
    four_glasses_get_two_brushes,
    two_get_smile_box,
    three_get_smile_box,
    dia_12mm_big_glove_get_silicon_straw,
    dia_14mm_big_glove_get_silicon_straw,
    small_glove_get_silicon_straw,
    glass_comb_extra,
    no_accumulate_four_glasses_get_two_brushes,
    four_glass_get_bag,
    four_glass_get_edge_tau,
  }
  let descriptionClass = 'event-desc'
  return (
    <>
      <Row gutter={8} className='my-4 event-modify-generate'>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2 '
                checked={tau_n_get_n.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    tau_n_get_n: {
                      ...event.tau_n_get_n,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              吸管套買n送n根吸管
            </div>
            <div className={descriptionClass}>
              內容：{tau_n_get_n.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2 '
                checked={jp_mask_box_get_mask_clip.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    jp_mask_box_get_mask_clip: {
                      ...event.jp_mask_box_get_mask_clip,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              日式口罩收納盒送口罩夾
            </div>
            <div className={descriptionClass}>
              內容：{jp_mask_box_get_mask_clip.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2 '
                checked={evey_tau_get_box.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    evey_tau_get_box: {
                      ...event.evey_tau_get_box,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              每個吸管套送對應盒子
            </div>
            <div className={descriptionClass}>
              內容：{evey_tau_get_box.data.description}
            </div>
            <Form size='small' colon={false}>
              <Form.Item label='送的收納盒：'>
                <Select
                  disabled={!evey_tau_get_box.checked}
                  value={evey_tau_get_box.gift}
                  placeholder='送的收納盒'
                  options={evey_tau_get_box.data.options.gifts.map((ob) => ({
                    key: ob.a1id,
                    value: ob.a1id,
                    label: `${ob.name} @ ${ob.a1id}`,
                  }))}
                  onSelect={(text, opt) => {
                    renewGiftSet({
                      ...event,
                      evey_tau_get_box: {
                        ...event.evey_tau_get_box,
                        gift: text,
                      },
                    })
                  }}
                />
              </Form.Item>
            </Form>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={glass_comb_get_glove.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    glass_comb_get_glove: {
                      ...event.glass_comb_get_glove,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              玻璃組合送吸管套
            </div>
            <div className={descriptionClass}>
              內容：
              {glass_comb_get_glove.data.description}
            </div>
            <Form size='small' colon={false}>
              <Form.Item label='送的吸管套：'>
                <Select
                  disabled={!glass_comb_get_glove.checked}
                  value={glass_comb_get_glove.gift}
                  placeholder='送的吸管套'
                  options={glass_comb_get_glove.data.options.gifts.map(
                    (ob) => ({
                      key: ob.a1id,
                      value: ob.a1id,
                      label: `${ob.name} @ ${ob.a1id}`,
                    })
                  )}
                  onSelect={(text, opt) => {
                    renewGiftSet({
                      ...event,
                      glass_comb_get_glove: {
                        ...event.glass_comb_get_glove,
                        gift: text,
                      },
                    })
                  }}
                />
              </Form.Item>
            </Form>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={glass_comb_tau_and_box.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    glass_comb_tau_and_box: {
                      ...event.glass_comb_tau_and_box,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              玻璃組合送吸管套跟小方盒
            </div>
            <div className={descriptionClass}>
              內容：
              {glass_comb_tau_and_box.data.description}
            </div>
            <Form size='small' colon={false}>
              <Form.Item label='送的吸管套：'>
                <Select
                  disabled={!glass_comb_tau_and_box.checked}
                  value={glass_comb_tau_and_box.gift}
                  placeholder='送的吸管套'
                  options={glass_comb_tau_and_box.data.options.gifts.map(
                    (ob) => ({
                      key: ob.a1id,
                      value: ob.a1id,
                      label: `${ob.name} @ ${ob.a1id}`,
                    })
                  )}
                  onSelect={(text, opt) => {
                    renewGiftSet({
                      ...event,
                      glass_comb_tau_and_box: {
                        ...event.glass_comb_tau_and_box,
                        gift: text,
                      },
                    })
                  }}
                />
              </Form.Item>
            </Form>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={dia_12mm_big_glove_get_silicon_straw.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    dia_12mm_big_glove_get_silicon_straw: {
                      ...event.dia_12mm_big_glove_get_silicon_straw,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              12mm粗吸管套送矽膠吸管
            </div>
            <div className={descriptionClass}>
              內容：
              {dia_12mm_big_glove_get_silicon_straw.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={dia_14mm_big_glove_get_silicon_straw.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    dia_14mm_big_glove_get_silicon_straw: {
                      ...event.dia_14mm_big_glove_get_silicon_straw,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              14mm粗吸管套送矽膠吸管
            </div>
            <div className={descriptionClass}>
              內容：
              {dia_14mm_big_glove_get_silicon_straw.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={small_glove_get_silicon_straw.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    small_glove_get_silicon_straw: {
                      ...event.small_glove_get_silicon_straw,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              8mm細吸管套送矽膠吸管
            </div>
            <div className={descriptionClass}>
              內容：
              {small_glove_get_silicon_straw.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={glass_comb_give_tau_every.checked}
                onChange={(e) => {
                  renewGiftSet({
                    ...event,
                    glass_comb_give_tau_every: {
                      ...event.glass_comb_give_tau_every,
                      checked: e.target.checked,
                    },
                  })
                }}
              />
              玻璃組合每個選項送吸管套含彩虹
            </div>
            <div className={descriptionClass}>
              內容：
              {glass_comb_give_tau_every.data.description}
            </div>
            <Form size='small' colon={false}>
              <Form.Item label='送的吸管套：'>
                <Select
                  disabled={!glass_comb_give_tau_every.checked}
                  value={glass_comb_give_tau_every.gift}
                  placeholder='送的吸管套'
                  options={glass_comb_give_tau_every.data.options.gifts.map(
                    (ob) => ({
                      key: ob.a1id,
                      value: ob.a1id,
                      label: `${ob.name} @ ${ob.a1id}`,
                    })
                  )}
                  onSelect={(text, opt) => {
                    renewGiftSet({
                      ...event,
                      glass_comb_give_tau_every: {
                        ...event.glass_comb_give_tau_every,
                        gift: text,
                      },
                    })
                  }}
                />
              </Form.Item>
            </Form>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={straw_glove_get_box.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    straw_glove_get_box: {
                      ...event.straw_glove_get_box,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              吸管套送盒子的活動
            </div>
            <div className={descriptionClass}>
              內容：
              {straw_glove_get_box.data.description}
            </div>
            <Form size='small' colon={false}>
              <Form.Item label='盒子種類：'>
                <Select
                  disabled={!straw_glove_get_box.checked}
                  value={straw_glove_get_box.defaultType}
                  placeholder='送的盒子'
                  options={[
                    {
                      value: '膠囊盒',
                      label: '膠囊盒',
                    },
                    {
                      value: '方盒子',
                      label: '方盒子',
                    },
                  ]}
                  onSelect={(text, opt) => {
                    if (text !== straw_glove_get_box.defaultType) {
                      renewGiftSet({
                        ...event,
                        straw_glove_get_box: {
                          ...event.straw_glove_get_box,
                          defaultType: text,
                          gift: straw_glove_get_box.data.options.gifts.filter(
                            (ob) => ob.identifier.includes(text)
                          )[0].a1id,
                        },
                      })
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label='送的盒子：'>
                <Select
                  disabled={!straw_glove_get_box.checked}
                  value={straw_glove_get_box.gift}
                  placeholder='送的盒子'
                  options={straw_glove_get_box.data.options.gifts
                    .filter((ob) =>
                      ob.identifier.includes(straw_glove_get_box.defaultType)
                    )
                    .map((ob) => ({
                      key: ob.a1id,
                      value: ob.a1id,
                      label: `${ob.name} @ ${ob.a1id}`,
                    }))}
                  onSelect={(text, opt) => {
                    renewGiftSet({
                      ...event,
                      straw_glove_get_box: {
                        ...event.straw_glove_get_box,
                        gift: text,
                      },
                    })
                  }}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={cup_get_sticker.checked}
                onChange={(e) => {
                  renewGiftSet({
                    ...event,
                    cup_get_sticker: {
                      ...event.cup_get_sticker,
                      checked: e.target.checked,
                    },
                  })
                }}
              />
              杯子送貼紙
            </div>
            <div className={descriptionClass}>
              內容：
              {cup_get_sticker.data.description}
            </div>
            <Form size='small' colon={false}>
              <Form.Item label='送的貼紙：'>
                <Select
                  disabled={!cup_get_sticker.checked}
                  value={cup_get_sticker.gift}
                  placeholder='送的貼紙'
                  options={cup_get_sticker.data.options.gifts.map((ob) => ({
                    key: ob.a1id,
                    value: ob.a1id,
                    label: `${ob.name} @ ${ob.a1id}`,
                  }))}
                  onSelect={(text, opt) => {
                    renewGiftSet({
                      ...event,
                      cup_get_sticker: {
                        ...event.cup_get_sticker,
                        gift: text,
                      },
                    })
                  }}
                />
              </Form.Item>
            </Form>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={cup_get_bottom_tau.checked}
                onChange={(e) => {
                  renewGiftSet({
                    ...event,
                    cup_get_bottom_tau: {
                      ...event.cup_get_bottom_tau,
                      checked: e.target.checked,
                    },
                  })
                }}
              />
              杯子送杯底套
            </div>
            <div className={descriptionClass}>
              內容：
              {cup_get_bottom_tau.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={eight_clips_get_oil.checked}
                onChange={(e) => {
                  renewGiftSet({
                    ...event,
                    eight_clips_get_oil: {
                      ...event.eight_clips_get_oil,
                      checked: e.target.checked,
                    },
                  })
                }}
              />
              八個香薰扣送精油，每單最多一
            </div>
            <div className={descriptionClass}>
              內容：
              {eight_clips_get_oil.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2 '
                checked={hook_event.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    hook_event: {
                      ...event.hook_event,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              掛鉤買二送一
            </div>
            <div className={descriptionClass}>
              內容：{hook_event.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2 '
                checked={wash_get_storage.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    wash_get_storage: {
                      ...event.wash_get_storage,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              洗衣凝珠送收納
            </div>
            <div className={descriptionClass}>
              內容：{wash_get_storage.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2 '
                checked={two_get_smile_box.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    two_get_smile_box: {
                      ...event.two_get_smile_box,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              買二送微笑
            </div>
            <div className={descriptionClass}>
              內容：{two_get_smile_box.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2 '
                checked={three_get_smile_box.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    three_get_smile_box: {
                      ...event.three_get_smile_box,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              買三送微笑
            </div>
            <div className={descriptionClass}>
              內容：{three_get_smile_box.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={stainless.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    stainless: {
                      ...event.stainless,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              不鏽鋼單根活動
            </div>
            <div className={descriptionClass}>
              內容：{stainless.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2'
                checked={glass_single_main.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    glass_single_main: {
                      ...event.glass_single_main,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              玻璃單根主活動
            </div>
            <div className={descriptionClass}>
              內容：
              {glass_single_main.data.description}
            </div>
          </Card>
          <Card className='mt-3' size='small'>
            <div className='event-check-box'>
              <Checkbox
                className='mr-2 '
                checked={tau_get_glass.checked}
                onChange={(e) =>
                  renewGiftSet({
                    ...event,
                    tau_get_glass: {
                      ...event.tau_get_glass,
                      checked: e.target.checked,
                    },
                  })
                }
              />
              吸管套送玻璃吸管
            </div>
            <div className={descriptionClass}>
              內容：{tau_get_glass.data.description}
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <Row gutter={8}>
            <Col xs={24} sm={24} md={12} lg={12} xl={24}>
              <Card className='mt-3 special-card' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2'
                    checked={glass_single_two_one.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        glass_single_two_one: {
                          ...event.glass_single_two_one,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  玻璃單根舊活動，買二送一，買四送二
                </div>
                <div className={descriptionClass}>
                  內容：
                  {glass_single_two_one.data.description}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={24}>
              <Card className='mt-3 special-card' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2'
                    checked={silicon_get_cut.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        silicon_get_cut: {
                          ...event.silicon_get_cut,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  矽膠吸管送切口
                </div>
                <div className={descriptionClass}>
                  內容：{silicon_get_cut.data.description}
                </div>
              </Card>
              <Card className='mt-3 special-card' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2'
                    checked={glass_comb_extra.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        glass_comb_extra: {
                          ...event.glass_comb_extra,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  玻璃吸管組合送護嘴套和收納盒
                </div>
                <div className={descriptionClass}>
                  內容：{glass_comb_extra.data.description}
                </div>
                <Form size='small' colon={false}>
                  <Form.Item label='送的護嘴套組合：'>
                    <Select
                      disabled={!glass_comb_extra.checked}
                      value={glass_comb_extra.gift}
                      placeholder='送的護嘴套組合'
                      options={glass_comb_extra.data.options.gifts.map(
                        (ob) => ({
                          key: ob.a1id,
                          value: ob.a1id,
                          label: `${ob.name} @ ${ob.a1id}`,
                        })
                      )}
                      onSelect={(text, opt) => {
                        renewGiftSet({
                          ...event,
                          glass_comb_extra: {
                            ...event.glass_comb_extra,
                            gift: text,
                          },
                        })
                      }}
                    />
                  </Form.Item>
                </Form>
              </Card>
              <Card className='mt-3 special-card' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2'
                    checked={four_glass_get_bag.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        four_glass_get_bag: {
                          ...event.four_glass_get_bag,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  {'(無累計)買四個玻璃吸管送束口袋'}
                </div>
                <div className={descriptionClass}>
                  內容：{four_glass_get_bag.data.description}
                </div>
                <Form size='small' colon={false}>
                  <Form.Item label='送的束口袋：'>
                    <Select
                      disabled={!four_glass_get_bag.checked}
                      value={four_glass_get_bag.gift}
                      placeholder='送的束口袋'
                      options={four_glass_get_bag.data.options.gifts.map(
                        (ob) => ({
                          key: ob.a1id,
                          value: ob.a1id,
                          label: `${ob.name} @ ${ob.a1id}`,
                        })
                      )}
                      onSelect={(text, opt) => {
                        renewGiftSet({
                          ...event,
                          four_glass_get_bag: {
                            ...event.four_glass_get_bag,
                            gift: text,
                          },
                        })
                      }}
                    />
                  </Form.Item>
                </Form>
              </Card>
              <Card className='mt-3 special-card' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2'
                    checked={four_glass_get_edge_tau.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        four_glass_get_edge_tau: {
                          ...event.four_glass_get_edge_tau,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  {'買四個玻璃吸管送防裂嘴套'}
                </div>
                <div className={descriptionClass}>
                  內容：{four_glass_get_edge_tau.data.description}
                </div>
                <Form size='small' colon={false}>
                  <Form.Item label='送的防裂嘴套：'>
                    <Select
                      disabled={!four_glass_get_edge_tau.checked}
                      value={four_glass_get_edge_tau.gift}
                      placeholder='送的防裂嘴套'
                      options={four_glass_get_edge_tau.data.options.gifts.map(
                        (ob) => ({
                          key: ob.a1id,
                          value: ob.a1id,
                          label: `${ob.name} @ ${ob.a1id}`,
                        })
                      )}
                      onSelect={(text, opt) => {
                        renewGiftSet({
                          ...event,
                          four_glass_get_edge_tau: {
                            ...event.four_glass_get_edge_tau,
                            gift: text,
                          },
                        })
                      }}
                    />
                  </Form.Item>
                </Form>
              </Card>
              <Card className='mt-3 special-card' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2'
                    checked={cut.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        cut: {
                          ...event.cut,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  切口贈送
                </div>
                <div className={descriptionClass}>
                  內容：{cut.data.description}
                </div>
              </Card>
              <Card className='mt-3 special-card' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2'
                    checked={wan_wan_get_two.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        wan_wan_get_two: {
                          ...event.wan_wan_get_two,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  波霸彎彎買一送一
                </div>
                <div className={descriptionClass}>
                  內容：{wan_wan_get_two.data.description}
                </div>
              </Card>
              <Card className='mt-3' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2 '
                    checked={tau_get_tau.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        tau_get_tau: {
                          ...event.tau_get_tau,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  吸管套買一送一
                </div>
                <div className={descriptionClass}>
                  內容：{tau_get_tau.data.description}
                </div>
              </Card>
              <Card className='mt-3' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2 '
                    checked={pandora.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        pandora: {
                          ...event.pandora,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  潘朵拉玻璃吸管送吸管套
                </div>
                <div className={descriptionClass}>
                  內容：{pandora.data.description}
                </div>
              </Card>
              <Card className='mt-3' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2 '
                    checked={nano_sponge.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        nano_sponge: {
                          ...event.nano_sponge,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  木紋便當盒送海綿
                </div>
                <div className={descriptionClass}>
                  內容：{nano_sponge.data.description}
                </div>
              </Card>
              <Card className='mt-3' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2 '
                    checked={random_three_aroma_clips.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        random_three_aroma_clips: {
                          ...event.random_three_aroma_clips,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  隨機三個香薰扣選項
                </div>
                <div className={descriptionClass}>
                  內容：{random_three_aroma_clips.data.description}
                </div>
              </Card>
              <Card className='mt-3' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2 '
                    checked={four_glasses_get_two_brushes.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        four_glasses_get_two_brushes: {
                          ...event.four_glasses_get_two_brushes,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  {'(可累計)四玻璃吸管送二刷'}
                </div>
                <div className={descriptionClass}>
                  內容：{four_glasses_get_two_brushes.data.description}
                </div>
              </Card>
              <Card className='mt-3' size='small'>
                <div className='event-check-box'>
                  <Checkbox
                    className='mr-2 '
                    checked={no_accumulate_four_glasses_get_two_brushes.checked}
                    onChange={(e) =>
                      renewGiftSet({
                        ...event,
                        no_accumulate_four_glasses_get_two_brushes: {
                          ...event.no_accumulate_four_glasses_get_two_brushes,
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  {'(無累計)四玻璃吸管送二刷'}
                </div>
                <div className={descriptionClass}>
                  內容：
                  {no_accumulate_four_glasses_get_two_brushes.data.description}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
const mapStateToProps = (state) => {
  let events = state.orderProcess.generate.giftEvents
  return {
    hook_event: {
      data: events.find((ob) => ob.id === 'hook_event'),
      ...state.orderProcess.generate.view.gift.hook_event,
    },
    stainless: {
      data: events.find((ob) => ob.id === 'stainless'),
      ...state.orderProcess.generate.view.gift.stainless,
    },
    glass_single_main: {
      data: events.find((ob) => ob.id === 'glass_single_main'),
      ...state.orderProcess.generate.view.gift.glass_single_main,
    },
    glass_single_two_one: {
      data: events.find((ob) => ob.id === 'glass_single_two_one'),
      ...state.orderProcess.generate.view.gift.glass_single_two_one,
    },
    cut: {
      data: events.find((ob) => ob.id === 'cut'),
      ...state.orderProcess.generate.view.gift.cut,
    },
    glass_comb_get_glove: {
      data: events.find((ob) => ob.id === 'glass_comb_get_glove'),
      ...state.orderProcess.generate.view.gift.glass_comb_get_glove,
    },
    glass_comb_tau_and_box: {
      data: events.find((ob) => ob.id === 'glass_comb_tau_and_box'),
      ...state.orderProcess.generate.view.gift.glass_comb_tau_and_box,
    },
    straw_glove_get_box: {
      data: events.find((ob) => ob.id === 'straw_glove_get_box'),
      ...state.orderProcess.generate.view.gift.straw_glove_get_box,
    },
    tau_get_tau: {
      data: events.find((ob) => ob.id === 'tau_get_tau'),
      ...state.orderProcess.generate.view.gift.tau_get_tau,
    },
    tau_get_glass: {
      data: events.find((ob) => ob.id === 'tau_get_glass'),
      ...state.orderProcess.generate.view.gift.tau_get_glass,
    },
    glass_comb_give_tau_every: {
      data: events.find((ob) => ob.id === 'glass_comb_give_tau_every'),
      ...state.orderProcess.generate.view.gift.glass_comb_give_tau_every,
    },
    wash_get_storage: {
      data: events.find((ob) => ob.id === 'wash_get_storage'),
      ...state.orderProcess.generate.view.gift.wash_get_storage,
    },
    wan_wan_get_two: {
      data: events.find((ob) => ob.id === 'wan_wan_get_two'),
      ...state.orderProcess.generate.view.gift.wan_wan_get_two,
    },
    tau_n_get_n: {
      data: events.find((ob) => ob.id === 'tau_n_get_n'),
      ...state.orderProcess.generate.view.gift.tau_n_get_n,
    },
    pandora: {
      data: events.find((ob) => ob.id === 'pandora'),
      ...state.orderProcess.generate.view.gift.pandora,
    },
    nano_sponge: {
      data: events.find((ob) => ob.id === 'nano_sponge'),
      ...state.orderProcess.generate.view.gift.nano_sponge,
    },
    cup_get_sticker: {
      data: events.find((ob) => ob.id === 'cup_get_sticker'),
      ...state.orderProcess.generate.view.gift.cup_get_sticker,
    },
    cup_get_bottom_tau: {
      data: events.find((ob) => ob.id === 'cup_get_bottom_tau'),
      ...state.orderProcess.generate.view.gift.cup_get_bottom_tau,
    },
    evey_tau_get_box: {
      data: events.find((ob) => ob.id === 'evey_tau_get_box'),
      ...state.orderProcess.generate.view.gift.evey_tau_get_box,
    },
    silicon_get_cut: {
      data: events.find((ob) => ob.id === 'silicon_get_cut'),
      ...state.orderProcess.generate.view.gift.silicon_get_cut,
    },
    glass_comb_extra: {
      data: events.find((ob) => ob.id === 'glass_comb_extra'),
      ...state.orderProcess.generate.view.gift.glass_comb_extra,
    },
    jp_mask_box_get_mask_clip: {
      data: events.find((ob) => ob.id === 'jp_mask_box_get_mask_clip'),
      ...state.orderProcess.generate.view.gift.jp_mask_box_get_mask_clip,
    },
    random_three_aroma_clips: {
      data: events.find((ob) => ob.id === 'random_three_aroma_clips'),
      ...state.orderProcess.generate.view.gift.random_three_aroma_clips,
    },
    eight_clips_get_oil: {
      data: events.find((ob) => ob.id === 'eight_clips_get_oil'),
      ...state.orderProcess.generate.view.gift.eight_clips_get_oil,
    },
    four_glasses_get_two_brushes: {
      data: events.find((ob) => ob.id === 'four_glasses_get_two_brushes'),
      ...state.orderProcess.generate.view.gift.four_glasses_get_two_brushes,
    },
    two_get_smile_box: {
      data: events.find((ob) => ob.id === 'two_get_smile_box'),
      ...state.orderProcess.generate.view.gift.two_get_smile_box,
    },
    three_get_smile_box: {
      data: events.find((ob) => ob.id === 'three_get_smile_box'),
      ...state.orderProcess.generate.view.gift.three_get_smile_box,
    },
    dia_12mm_big_glove_get_silicon_straw: {
      data: events.find(
        (ob) => ob.id === 'dia_12mm_big_glove_get_silicon_straw'
      ),
      ...state.orderProcess.generate.view.gift
        .dia_12mm_big_glove_get_silicon_straw,
    },
    dia_14mm_big_glove_get_silicon_straw: {
      data: events.find(
        (ob) => ob.id === 'dia_14mm_big_glove_get_silicon_straw'
      ),
      ...state.orderProcess.generate.view.gift
        .dia_14mm_big_glove_get_silicon_straw,
    },
    small_glove_get_silicon_straw: {
      data: events.find((ob) => ob.id === 'small_glove_get_silicon_straw'),
      ...state.orderProcess.generate.view.gift.small_glove_get_silicon_straw,
    },
    no_accumulate_four_glasses_get_two_brushes: {
      data: events.find(
        (ob) => ob.id === 'no_accumulate_four_glasses_get_two_brushes'
      ),
      ...state.orderProcess.generate.view.gift
        .no_accumulate_four_glasses_get_two_brushes,
    },
    four_glass_get_bag: {
      data: events.find((ob) => ob.id === 'four_glass_get_bag'),
      ...state.orderProcess.generate.view.gift.four_glass_get_bag,
    },
    four_glass_get_edge_tau: {
      data: events.find((ob) => ob.id === 'four_glass_get_edge_tau'),
      ...state.orderProcess.generate.view.gift.four_glass_get_edge_tau,
    },
  }
}

export default connect(mapStateToProps, { renewGiftSet })(GiftEventStep)
