import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { Row } from 'antd'
import 'moment/locale/zh-tw'
import moment from 'moment'

const LinePlot = ({ lines, dataInput, title, syncId, timeOption }) => {
  let tickFormatter = (date, ind) => {
    let s
    switch (timeOption) {
      case 'monthsInYear':
        s = ind === 0 ? 'YYYY-MMM' : 'MMM'
        break
      case 'daysInMonth':
        s = ind === 0 ? 'YYYY-MMM' : 'Do'
        break
      case 'daysInWeek':
        s = ind === 0 ? 'MMM' : 'Do'
        break
      case 'daysInDays':
        s = ind === 0 ? 'YYYY-MMM' : 'MMMDo'
        break
      case 'monthsInMonths':
        s = ind === 0 ? 'YYYY-MMM' : 'YYYY-MMM'
        break
      case 'yearsInYears':
        s = ind === 0 ? 'YYYY-' : 'YYYY-'
        break
      case 'monthsInTwoYears':
        s = ind === 0 ? 'YYYY-MMM' : 'MMM'
        break
      default:
        s = 'll'
    }
    return moment(date).format(s)
  }
  return (
    <Row>
      {title ? <h5>{title}</h5> : null}
      <ResponsiveContainer width='100%' className='min-h-350' height={300}>
        <LineChart
          width={500}
          height={300}
          data={dataInput}
          margin={{
            top: 20,
            right: 30,
            left: 30,
            bottom: 20,
          }}
          syncId={syncId}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='createdAt' tickFormatter={tickFormatter} />
          <YAxis
            domain={[(dataMin) => dataMin * 0.95, (dataMax) => dataMax * 1.05]}
            tickFormatter={(num) => num.toPrecision(3)}
          />
          <Tooltip labelFormatter={tickFormatter} />
          {lines.length > 1 ? (
            <Legend
              align='center'
              verticalAlign='bottom'
              layout='vertical'
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            />
          ) : null}

          {lines.map((line, i) => (
            <Line
              connectNulls
              type='monotone'
              dataKey={line.name}
              activeDot={{ r: 5 }}
              key={i}
              stroke={line.color}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Row>
  )
}

export default LinePlot
