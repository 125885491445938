import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import picking from './picking'
import pageNav from './pageNav'
import store_data from './scrape'
import ding from './dingReducer'
import purchase from './purchaseReducer'
import orderProcess from './orderProcessReducer'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    picking,
    pageNav,
    store_data,
    ding,
    purchase,
    orderProcess,
  })
export default createRootReducer
