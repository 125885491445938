// picking
export const GET_RECORDS = 'GET_RECORDS'
export const SET_RECORDS = 'SET_RECORDS'
export const PICKING_ERROR = 'PICKING_ERROR'
export const LINK = 'LINK'
export const GET_PICKING_DETAILS = 'GET_PICKING_DETAILS'
export const CLEAR_PICKING_DETAILS = 'CLEAR_PICKING_DETAILS'
export const SWITCH_ORDER = 'SWITCH_ORDER'
export const SWITCH_ENTRY = 'SWITCH_ENTRY'
export const SET_ENTRY_CONDITION = 'SET_ENTRY_CONDITION'
export const SET_ENTRY_QUTY = 'SET_ENTRY_QUTY'
export const MODIFY_NOT_GOOD = 'MODIFY_NOT_GOOD'
export const CHANGE_NOT_GOOD = 'CHANGE_NOT_GOOD'
export const DELETE_ONE_NOT_GOOD = 'CHANGE_ONE_NOT_GOOD'
export const SET_SELECT_NOT_GOOD = 'SET_SELECT_NOT_GOOD'
export const PUSH_DING_NOT_GOOD = 'PUSH_DING_NOT_GOOD'
export const GET_ALL_NOT_GOOD = 'GET_ALL_NOT_GOOD'
export const COMPLETE_NOT_GOOD = 'COMPLETE_ALL_NOT_GOOD'
export const SET_NOT_DATE_RANGE = 'SET_NOT_DATE_RANGE'
export const SET_NOT_FILTER_SOL = 'SET_NOT_FILTER_SOL'
export const SEARCH_NOT_GOOD = 'SEARCH_NOT_GOOD'
export const COPIED_NOT_GOOD = 'COPIED_NOT_GOOD'
export const SELECT_NOT_GOOD = 'SELECT_NOT_GOOD'
export const DELETE_RECORD_ENTRY_MANY = 'DELETE_RECORD_ENTRY_MANY'
export const DELETE_RECORD_ENTRY_ONE = 'DELETE_RECORD_ENTRY_ONE'
export const COPIED_GIFT = 'COPIED_GIFT'
export const GIFT_DONE = 'GIFT_DONE'

// store_data
export const TEST = 'TEST'
export const DELETE_KEYWORD = 'DELETE_KEYWORD'

// ScraperApi
export const GET_SCRAPERAPIS = 'GET_SCRAPERAPIS'
export const NEW_SCRAPERAPI = 'NEW_SCRAPERAPI'
export const UPDATE_SCRAPERAPI = 'UPDATE_SCRAPERAPI'
export const DELETE_SCRAPERAPI = 'DELETE_SCRAPERAPI'

// Ding
export const GET_DING_SHOPEE_ORDER = 'GET_DING_SHOPEE_ORDER'
export const START_GET_SHOPEE_ORDER_FORM_DING =
  'START_GET_SHOPEE_ORDER_FORM_DING'
export const UPLOAD_DING_ITEM_IMAG = 'UPLOAD_DING_ITEM_IMAG'
export const UPLOAD_DING_ITEM_IMAG_SUCCESS = 'UPLOAD_DING_ITEM_IMAG_SUCCESS'
export const DELETE_DING_ITEM_IMAG = 'DELETE_DING_ITEM_IMAG'
export const SUCCESS_DELETE_DING_ITEM_IMAG = 'SUCCESS_DELETE_DING_ITEM_IMAG'

export const GET_DING_INDIVIDUAL_ITEMS = 'GET_DING_INDIVIDUAL_ITEMS'
export const SUCCESS_GET_DING_INDIVIDUAL_ITEMS =
  'SUCCESS_GET_DING_INDIVIDUAL_ITEMS'
export const ERROR_GET_DING_INDIVIDUAL_ITEMS = 'ERROR_GET_DING_INDIVIDUAL_ITEMS'

export const GET_DING_ALL_ITEMS = 'GET_DING_ALL_ITEMS'
export const SUCCESS_GET_DING_ALL_ITEMS = 'SUCCESS_GET_DING_ALL_ITEMS'
export const ERROR_GET_DING_ALL_ITEMS = 'ERROR_GET_DING_ALL_ITEMS'

export const UPDATE_DING_ITEM_SHOPEE_CORR = 'UPDATE_DING_ITEM_SHOPEE_CORR'
export const SUCCESS_UPDATE_DING_ITEM_SHOPEE_CORR =
  'SUCCESS_UPDATE_DING_ITEM_SHOPEE_CORR'
export const ERROR_UPDATE_DING_ITEM_SHOPEE_CORR =
  'ERROR_UPDATE_DING_ITEM_SHOPEE_CORR'

export const SELECT_DING_INDIVIDUAL_ITEMS = 'SELECT_DING_INDIVIDUAL_ITEMS'
export const SEARCH_DING_INDIVIDUAL_ITEMS = 'SEARCH_DING_INDIVIDUAL_ITEMS'
export const DING_ITEM_IMAG_LOADED = 'DING_ITEM_IMAG_LOADED'
// Purchase
export const START_DOWNLOAD_PURCHASE_SUGGESTION =
  'START_DOWNLOAD_PURCHASE_SUGGESTION'
export const DOWNLOAD_PURCHASE_SUGGESTION = 'DOWNLOAD_PURCHASE_SUGGESTION'
export const FAIL_DOWNLOAD_PURCHASE_SUGGESTION =
  'FAIL_DOWNLOAD_PURCHASE_SUGGESTION'

// Order Processing
// Ding Renew database
export const RENEW_ALL_DATA_FROM_DING = 'RENEW_ALL_DATA_FROM_DING'
export const SUCCESS_RENEW_ALL_DATA_FROM_DING =
  'SUCCESS_RENEW_ALL_DATA_FROM_DING'
export const FAIL_RENEW_ALL_DATA_FROM_DING = 'FAIL_RENEW_ALL_DATA_FROM_DING'

// jj manage
export const REQUEST_DING_SHOPEE_CORR = 'REQUEST_DING_SHOPEE_CORR'
export const SUCCESS_REQUEST_DING_SHOPEE_CORR =
  'SUCCESS_REQUEST_DING_SHOPEE_CORR'
export const FAIL_REQUEST_DING_SHOPEE_CORR = 'FAIL_REQUEST_DING_SHOPEE_CORR'

export const CLEAR_GENERATE = 'CLEAR_GENERATE'

// 1-1. upload data from shopee
export const UPLOAD_EXCEL_ORDERS_FROM_SHOPEE = 'UPLOAD_EXCEL_ORDERS_FROM_SHOPEE'
export const SUCCESS_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE =
  'SUCCESS_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE'
export const FAIL_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE =
  'FAIL_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE'

// 1-2. get data from ding
export const REQUEST_ORDERS_FROM_DING = 'REQUEST_ORDERS_FROM_DING'
export const SUCESS_REQUEST_ORDERS_FROM_DING = 'SUCESS_REQUEST_ORDERS_FROM_DING'
export const FAIL_REQUEST_ORDERS_FROM_DING = 'FAIL_REQUEST_ORDERS_FROM_DING'

// 2-1. do correspond
export const DO_GENERATE_CORRESPOND = 'DO_GENERATE_CORRESPOND'
export const GENERATE_ALL_CORRESPOND = 'GENERATE_ALL_CORRESPOND'
export const GENERATE_HAS_NO_CORRESPOND = 'GENERATE_HAS_NO_CORRESPOND'

// 3-1 range
export const RENEW_RANGE = 'RENEW_RANGE'

// 4-1
export const GIFT_SET = 'GIFT_SET'
export const RENEW_GIFT_SET = 'RENEW_GIFT_SET'
export const BACK_TO_RANGE = 'BACK_TO_RANGE'

// 5-1
export const SEND_ORDER_GENERATE_DATA = 'SEND_ORDER_GENERATE_DATA'
export const SUCCESS_SEND_ORDER_GENERATE_DATA =
  'SUCCESS_SEND_ORDER_GENERATE_DATA'
export const FAIL_SEND_ORDER_GENERATE_DATA = 'FAIL_SEND_ORDER_GENERATE_DATA'
export const BACK_TO_GIFT_SET = 'BACK_TO_GIFT_SET'

// event edit
export const SET_UP_EVENT_EDIT = 'SET_UP_EVENT_EDIT'
export const SUCCESS_SET_UP_EVENT_EDIT = 'SUCCESS_SET_UP_EVENT_EDIT'
export const FAIL_SET_UP_EVENT_EDIT = 'FAIL_SET_UP_EVENT_EDIT'

// change event item
export const CHANGE_EVENT_ITEM = ' CHANGE_EVENT_ITEM'
export const SUCCESS_CHANGE_EVENT_ITEM = ' SUCCESS_CHANGE_EVENT_ITEM'
export const FAIL_CHANGE_EVENT_ITEM = ' FAIL_CHANGE_EVENT_ITEM'

export const DO_SHIPPINGNAME_CORR = 'DO_SHIPPINGNAME_CORR'
export const SUCESS_DO_SHIPPINGNAME_CORR = 'SUCESS_DO_SHIPPINGNAME_CORR'
export const FAIL_DO_SHIPPINGNAME_CORR = 'FAIL_DO_SHIPPINGNAME_CORR'
export const UPDATE_ORDER_TEXT = 'UPDATE_ORDER_TEXT'
