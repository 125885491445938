import React from 'react'
import { connect } from 'react-redux'
import { Table, Radio } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faCheck } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import {
  setEntryCondition,
  qutyChange,
  modifyNotGood,
} from '../../actions/picking'

const ItemNotGoodPanel = ({
  items,
  No,
  notgoods,
  setEntryCondition,
  qutyChange,
  orderRecord,
  modifyNotGood,
}) => {
  const columns = [
    {
      title: '內容物',
      dataIndex: 'A1ItemName',
      width: '40%',
    },
    {
      title: '情況',
      dataIndex: 'condition',
      render: (text, record, index) => (
        <Radio.Group
          value={record.condition}
          onChange={(e) => setEntryCondition(record, e.target.value)}
          size="small"
        >
          <Radio.Button value="ban">半價</Radio.Button>
          <Radio.Button value="sha">瑕疵</Radio.Button>
        </Radio.Group>
      ),
      width: '29%',
    },
    {
      title: '數量',
      dataIndex: 'quty',
      align: 'center',
      width: '17%',
      render: (text, record, index) => (
        <div>
          <button
            type="button"
            className="btn btn-link p-0 pr-2 buttonMP  pb-1"
            onClick={() => qutyChange('minus', -1, record)}
          >
            <FontAwesomeIcon icon={faMinus} transform="down-1" size="sm" />
          </button>

          <input
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            className="number-picker m-0"
            value={record.quty}
            onChange={(e) => qutyChange('set', e.target.value, record)}
            onBlur={(e) => qutyChange('set_comp', e.target.value, record)}
          ></input>
          <button
            type="button"
            className="btn btn-link p-0 pl-2 buttonMP"
            onClick={() => qutyChange('add', 1, record)}
          >
            <FontAwesomeIcon icon={faPlus} transform="up-2" size="sm" />
          </button>
        </div>
      ),
    },
    {
      title: '加入',
      align: 'center',
      render: (text, record, index) => (
        <button
          className="operation_cell"
          onClick={() => modifyNotGood(record, No, orderRecord._id, notgoods)}
          type="button"
        >
          <FontAwesomeIcon icon={faCheck} color="green" className="checkAdd" />
        </button>
      ),
      width: '14%',
    },
  ]
  return (
    <Table
      className={items.length === 0 ? "auto-scroll-y empty" : "auto-scroll-y"}
      columns={columns}
      dataSource={items}
      pagination={false}
      bordered
      scroll={{ y: 'calc(100% - 39px)' }}
      size="small"
    />
  )
}

ItemNotGoodPanel.propTypes = {
  items: PropTypes.array.isRequired,
  No: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  let orderRecord = state.picking.record
  let onPick = state.picking.entry_on_pick
  let notgoods = state.picking.not_goods.filter(
    (ob) => ob.orderRecord === orderRecord._id && ob.No === onPick.No
  )
  let type = onPick.Type
  let items = null
  let No = onPick.No
  if (typeof type === 'string') {
    if (type === '1' || (type === '3' && onPick.length === 0)) {
      // not a set
      type = 'single'
      let quty = 1
      let condition = 'sha'
      if (onPick.quty !== undefined) {
        quty = onPick.quty
      }
      if (onPick.condition !== undefined) {
        condition = onPick.condition
      }

      items = [{ ...onPick, condition, quty, type, key: onPick._id }]
    } else if (type === '3') {
      //  set
      type = 'set'
      items = onPick.CombinInfo.map((ob) => {
        let quty = 1
        let condition = 'sha'
        if (ob.quty !== undefined) {
          quty = ob.quty
        }
        if (ob.condition !== undefined) {
          condition = ob.condition
        }
        return {
          ...ob,
          condition,
          quty,
          type,
          key: ob._id,
        }
      })
    }
  } else {
    // unset
    type = 'unset'
    let quty = 1
    let condition = 'sha'
    if (onPick.quty) {
      quty = onPick.quty
    }
    if (onPick.condition) {
      condition = onPick.condition
    }
    items = [
      {
        ...onPick,
        condition,
        quty,
        type,
        A1ItemName: '鼎新尚未對應：' + onPick.Spec,
        key: onPick._id,
      },
    ]
  }

  return {
    notgoods,
    items,
    No,
    orderRecord,
  }
}

export default connect(mapStateToProps, {
  setEntryCondition,
  qutyChange,
  modifyNotGood,
})(ItemNotGoodPanel)
