import { Button, Modal, Radio, Row, Spin, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'

import TextArea from 'antd/lib/input/TextArea'
import api from '../../utils/api'
import { connect } from 'react-redux'
import { setRecord } from '../../actions/picking'

const copyToClipBoard = (data) => {
  navigator.clipboard.writeText(data)
}

const getShippingNickName = (name) => {
  switch (name) {
    case '7-ELEVEN':
      return '7-11'
    case 'OK Mart':
      return 'OK'
    case '蝦皮店到店':
      return '蝦皮'
    default:
      return name
  }
}
const PickingTextModal = ({ numberOfOrder, id, onOk, setRecord, ...rest }) => {
  const visible = rest.visible
  const [textGroupIndex, setTextGroupIndex] = useState(-1)
  const [isInitializing, setIsInitializing] = useState(true)
  const [textGroupByShipping, setTextGroupByShipping] = useState([])
  const [totalText, setTotalText] = useState({ original: '', modified: '' })

  const [loading, setLoading] = useState(false)

  const [isOriginal, setIsOriginal] = useState(false)

  const [modifiedTotalText, setModifiedTotalText] = useState('')

  const [modifiedTextGroupByShipping, setModifiedGroupByShipping] = useState(
    textGroupByShipping.map((ob) => ob.modifiedText)
  )
  useEffect(() => {
    if (visible) {
      async function init() {
        try {
          const { data } = await api.get(`api/orderRecords/records/${id}`)
          if (data?.textGroupByShipping && data?.totalText) {
            const { textGroupByShipping, totalText } = data
            setTextGroupByShipping(textGroupByShipping)
            setTotalText(totalText)
            setModifiedTotalText(totalText?.modified)
            setModifiedGroupByShipping(
              textGroupByShipping.map((ob) => ob.modifiedText)
            )
            setIsInitializing(false)
          }
        } catch (err) {
          message.error('伺服器出現問題。')
        }
      }
      init()
    }
  }, [visible, id])

  if (isInitializing)
    return (
      <Modal
        {...{
          ...rest,
          footer: false,
        }}
        width='850px'
      >
        <Spin
          style={{
            display: 'block',
          }}
        />
      </Modal>
    )

  const handleChangeText = (text) => {
    if (textGroupIndex === -1) {
      setModifiedTotalText(text)
    } else {
      setModifiedGroupByShipping((prev) =>
        prev.map((t, ind) => (ind === textGroupIndex ? text : t))
      )
    }
  }

  const getText = () => {
    let textTarget = ''
    if (isOriginal) {
      if (textGroupIndex === -1) {
        textTarget = totalText.original
      } else {
        textTarget = textGroupByShipping[textGroupIndex]?.originalText
      }
    } else {
      if (textGroupIndex === -1) {
        textTarget = modifiedTotalText
      } else {
        textTarget = modifiedTextGroupByShipping[textGroupIndex]
      }
    }
    return textTarget
  }

  const handleCopy = () => {
    let textTarget = getText()
    copyToClipBoard(textTarget)
    message.success('已複製訂單資料')
  }

  const handlePaste = async () => {
    try {
      setLoading(true)
      let text = await navigator.clipboard.readText()
      if (isOriginal) {
        message.error('不能修改原始資料喔')
      } else {
        if (textGroupIndex === -1) {
          setModifiedTotalText(text)
        } else {
          setModifiedGroupByShipping((prev) =>
            prev.map((t, ind) => (ind === textGroupIndex ? text : t))
          )
        }
        message.success('已經成功貼上剪貼簿中的資料')
      }
      setLoading(false)
    } catch (err) {
      message.error('無法複製剪貼簿中的資料')
      setLoading(false)
    }
  }

  const handleOnSave = async () => {
    try {
      setLoading(true)
      await api.patch(`api/orderRecords/records/${id}`, {
        totalModifiedText: modifiedTotalText,
        modifiedTextArray: modifiedTextGroupByShipping,
      })
      message.success('已經成功儲存資料')
      setLoading(false)
    } catch (error) {
      message.error('無法儲存資料')
      setLoading(false)
    }
  }

  const handleDoShippingCorr = async () => {
    try {
      setLoading(true)
      let { data: shippingcorr } = await api.get(
        'api/orderProcess/orderGenerate/ding_shin_conv'
      )
      const oldText = getText()
      const newText = oldText.replace(
        /(超商單號：)(\S+)(\s+\S+\s+訂單編號：)(\S+)/g,
        (match, pre, conv, middle, no) => {
          let newText
          if (shippingcorr[no]) {
            // has corr
            newText = pre + shippingcorr[no] + middle + no
          } else {
            // no corr
            newText = pre + '沒有生成到，或是已經被顧客取消了' + middle + no
          }
          return newText
        }
      )

      handleChangeText(newText)
      message.success('完成對應囉～～')
      setLoading(false)
    } catch (err) {
      message.error('出了一點問題，無法對應，確認網路以後重新整理看看')
      message.error(err.toString(), 2)
      setLoading(false)
    }
  }

  return (
    <Modal
      {...{
        ...rest,
        onOk: async () => {
          await handleOnSave()
          onOk()
        },
      }}
      width='850px'
    >
      <Row>
        <Typography.Text strong>寄送方式：</Typography.Text>
        <Radio.Group
          disabled={loading}
          className='ml-2'
          value={textGroupIndex}
          onChange={(e) => setTextGroupIndex(e.target.value)}
        >
          <Radio value={-1} key='全部'>
            全部{`(${numberOfOrder})`}
          </Radio>
          {textGroupByShipping.map((group, groupInd) => (
            <Radio value={groupInd} key={`${group.ShippingName}${groupInd}`}>
              {getShippingNickName(group.ShippingName) || '未知'}
              {`(${group.number})`}
            </Radio>
          ))}
        </Radio.Group>
      </Row>
      <Row className='mt-1'>
        <Typography.Text strong>顯示文字的種類：</Typography.Text>
        <Radio.Group
          disabled={loading}
          className='ml-2'
          value={isOriginal}
          onChange={(e) => setIsOriginal(e.target.value)}
        >
          <Radio value={true}> 原始文字 </Radio>
          <Radio value={false}> 修改過或是加過超商單號 </Radio>
        </Radio.Group>
      </Row>
      <Row className='mt-1'>
        <Typography.Text strong>操作：</Typography.Text>
        <Button
          className='ml-2'
          size='small'
          type='primary'
          onClick={handleCopy}
          disabled={loading}
        >
          複製文字
        </Button>
        <Button
          className='ml-1'
          size='small'
          type='primary'
          onClick={handlePaste}
          disabled={isOriginal || loading}
        >
          用剪貼簿裡取代
        </Button>
        <Button
          className='ml-1'
          size='small'
          type='primary'
          disabled={isOriginal}
          loading={loading}
          onClick={handleDoShippingCorr}
        >
          更新超商單號
        </Button>
        <Button
          className='ml-1'
          size='small'
          type='primary'
          disabled={isOriginal}
          loading={loading}
          onClick={handleOnSave}
        >
          全部儲存
        </Button>
      </Row>
      <Row className='mt-3'>
        {isOriginal ? (
          <Typography.Text
            style={{
              maxHeight: '500px',
              overflow: 'auto',
              whiteSpace: 'pre-wrap',
              width: '100%',
            }}
          >
            {textGroupIndex === -1
              ? totalText.original
              : textGroupByShipping[textGroupIndex]?.originalText}
          </Typography.Text>
        ) : (
          <TextArea
            style={{ height: '500px' }}
            disabled={loading}
            value={
              textGroupIndex === -1
                ? modifiedTotalText
                : modifiedTextGroupByShipping[textGroupIndex]
            }
            onChange={(e) => handleChangeText(e.target.value)}
          />
        )}
      </Row>
    </Modal>
  )
}

export default connect(null, { setRecord })(PickingTextModal)
