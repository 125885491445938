export const dateLateFront = (oa, ob) => {
  const dateA = new Date(oa.date)
  const dateB = new Date(ob.date)
  let comp = 0
  if (dateA < dateB) {
    comp = 1
  } else if (dateA > dateB) {
    comp = -1
  }
  return comp
}

export const dateEarlyFront = (oa, ob) => {
  const dateA = new Date(oa.date)
  const dateB = new Date(ob.date)
  let comp = 0
  if (dateA > dateB) {
    comp = 1
  } else if (dateA < dateB) {
    comp = -1
  }
  return comp
}
