import './EventChooser.css'
import React, { useRef, useState } from 'react'
import { List, Table, Input, Checkbox } from 'antd'
const { Search } = Input

const EventChooser = ({ event, corr, change }) => {
  const [itemEditing, setItemEditing] = useState(0)
  const [checkedOnly, setCheckedOnly] = useState(true)
  const [searchText, setSearchText] = useState('')
  const filterTextRef = useRef(null)
  // corr -> shopee items
  let shopeeProducts = new Set()
  let corr_copy = [...corr]
  let tags
  if (event) {
    if (event.categories) {
      tags = event.categories.map((ob) => ob.name)
    }
  }
  corr.forEach((cor) => {
    shopeeProducts.add(cor.shopeeItemId)
  })
  shopeeProducts = Array.from(shopeeProducts)
  shopeeProducts = shopeeProducts.map((shopeeProduct) => {
    let cor = corr_copy.filter((ob) => ob.shopeeItemId === shopeeProduct)
    cor = cor.map((ob) => {
      if (tags && event) {
        let corTag = event.categories.map((cate) =>
          cate.items.includes(ob._id) ? cate.name : ''
        )
        return {
          ...ob,
          inEvent: event ? event.items.includes(ob._id) : false,
          key: ob._id,
          tags: corTag,
        }
      }
      return {
        ...ob,
        inEvent: event ? event.items.includes(ob._id) : false,
        key: ob._id,
      }
    })
    return {
      name: cor[0].shopeeItemName[0],
      shopeeItemId: shopeeProduct,
      inEvent: cor.some((ob) => ob.inEvent),
      cor,
      key: shopeeProduct,
    }
  })
  shopeeProducts = shopeeProducts.filter(
    (ob) =>
      (ob.name.includes(searchText) || ob.shopeeItemId.includes(searchText)) &&
      (checkedOnly ? ob.inEvent : true)
  )

  return (
    <div className='event-chooser d-flex'>
      <div className='item-list pr-lg-3 pr-md-1'>
        <div className='mb-1 ml-1'>
          <Checkbox
            className='mr-1'
            checked={checkedOnly}
            onChange={(e) => setCheckedOnly(e.target.checked)}
          />
          只顯示有參加的
        </div>
        <Search
          ref={filterTextRef}
          onChange={(e) => {
            filterTextRef.current.value = e.target.value
            setTimeout(() => setSearchText(e.target.value), 0)
          }}
          placeholder='輸入商品名稱或商品編號'
        />
        <Table
          className='scroll-table empty-grow-table-without-header'
          hideSelectAll
          style={{
            minHeight: 'calc(100% - 64px)',
          }}
          columns={[
            {
              title: '蝦皮商品',
              dataIndex: 'name',
              render: (value, record, index) => (
                <div style={{ whiteSpace: 'pre' }}>
                  <Checkbox
                    className='mr-1'
                    checked={record.inEvent}
                    onChange={(e) =>
                      change(
                        event.id,
                        e.target.checked,
                        'default',
                        corr
                          .filter(
                            (ob) => ob.shopeeItemId === record.shopeeItemId
                          )
                          .map((ob) => ob._id)
                      )
                    }
                  />{' '}
                  <a
                    className='mr-1'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://shopee.tw/product/18173562/${record.shopeeItemId}`}
                  >
                    {record.shopeeItemId}
                  </a>
                  {value}
                </div>
              ),
            },
          ]}
          showHeader={false}
          rowClassName={(record, index) =>
            index === itemEditing
              ? 'ant-table-row-selected clickable'
              : 'clickable'
          }
          dataSource={shopeeProducts}
          pagination={false}
          onRow={(record, rowIndex) => ({
            onClick: () => setItemEditing(rowIndex),
          })}
          size='small'
        />
      </div>

      <List
        className='choice-list'
        itemLayout='horizontal'
        dataSource={
          shopeeProducts[itemEditing] ? shopeeProducts[itemEditing].cor : []
        }
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <>
                  <Checkbox
                    onChange={(e) =>
                      change(event.id, e.target.checked, 'default', [item._id])
                    }
                    className='mx-3'
                    checked={item.inEvent}
                  />
                  {item.shopeeChoiceName[0]}
                  {tags
                    ? tags.map((tag) => (
                        <div key={tag + item._id} className='tag-div'>
                          <Checkbox
                            className='ml-2 mr-1'
                            checked={
                              item.tags ? item.tags.includes(tag) : false
                            }
                            onChange={(e) =>
                              change(event.id, e.target.checked, tag, [
                                item._id,
                              ])
                            }
                          />
                          {tag}
                        </div>
                      ))
                    : null}
                </>
              }
              description=''
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default EventChooser
