import './OrderProcessTab.css'
import React from 'react'

const OrderProcessTab = () => {
  return (
    <div className="m-4 tab">
      寫單介面，處理出貨單
    </div>
  )
}

export default OrderProcessTab
