import './StepFooter.css'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Tooltip } from 'antd'
import {
  generateNext,
  generatePrev,
} from '../../../../actions/orderProcessAction'

const StepsFooter = ({ prevBtn, nextBtn, generateNext, generatePrev }) => {
  let footerClassName = 'd-flex justify-content-between step-footer mt-3'
  if (!prevBtn.visible) {
    footerClassName = footerClassName + ' prev-hidden'
  }
  if (!nextBtn.visible) {
    footerClassName = footerClassName + ' next-hidden'
  }
  return (
    <div className={footerClassName}>
      <div>
        {prevBtn.tooltip ? (
          <Tooltip title={prevBtn.tooltip}>
            <Button
              type='primary'
              disabled={prevBtn.disable}
              onClick={generatePrev}
            >
              {prevBtn.text}
            </Button>
          </Tooltip>
        ) : (
          <Button
            type='primary'
            disabled={prevBtn.disable}
            onClick={generatePrev}
          >
            {prevBtn.text}
          </Button>
        )}
      </div>
      <div>
        {nextBtn.tooltip ? (
          <Tooltip title={nextBtn.tooltip}>
            <Button
              type='primary'
              disabled={nextBtn.disable}
              onClick={generateNext}
            >
              {nextBtn.text}
            </Button>
          </Tooltip>
        ) : (
          <Button
            type='primary'
            disabled={nextBtn.disable}
            onClick={generateNext}
          >
            {nextBtn.text}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    nextBtn: {
      disable: state.orderProcess.generate.view.nextBtn.disable,
      visible: state.orderProcess.generate.view.nextBtn.visible,
      tooltip: state.orderProcess.generate.view.nextBtn.tooltip,
      text: state.orderProcess.generate.view.nextBtn.text,
    },
    prevBtn: {
      disable: state.orderProcess.generate.view.prevBtn.disable,
      visible: state.orderProcess.generate.view.prevBtn.visible,
      tooltip: state.orderProcess.generate.view.prevBtn.tooltip,
      text: state.orderProcess.generate.view.prevBtn.text,
    },
  }
}

export default connect(mapStateToProps, { generateNext, generatePrev })(
  StepsFooter
)
