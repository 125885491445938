import './App.css'

import { Route, Switch } from 'react-router-dom'
import configureStore, { history } from './configureStore'

import { ConnectedRouter } from 'connected-react-router'
import MyNavbar from './components/layout/MyNavbar'
// Redux
import { Provider } from 'react-redux'
import React from 'react'
import Routes from './components/routing/Routes'

const store = configureStore()

// import setAuthToken from './utils/setAuthToken'

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className='d-flex flex-column h-100'>
        <MyNavbar />
        <Switch>
          <Route component={Routes} />
        </Switch>
      </div>
    </ConnectedRouter>
  </Provider>
)

export default App
