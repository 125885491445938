import React from 'react'
import './homepage.style.css'
import Container from 'react-bootstrap/Container'

const HomePage = () => {
  return (
    <Container className='page' fluid='xl'>
      <div className='page homepage mx-4'>
        <svg
          className='homepage-logo'
          width='974.592'
          height='110.377'
          viewBox='0 0 974.592 101.377'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g
            id='svgGroup'
            strokeLinecap='round'
            fillRule='evenodd'
            fontSize='9pt'
            stroke='#000'
            style={{
              stroke: '#5caabd',
              strokeWidth: '4px',
            }}
          >
            <path
              d='M 30.816 99.504 L 30.816 12.528 L 0 12.528 L 0 1.296 L 73.728 1.296 L 73.728 12.528 L 42.912 12.528 L 42.912 99.504 L 30.816 99.504 Z'
              id='0'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 81.049 39.955 A 73.93 73.93 0 0 0 80.352 50.4 A 88.418 88.418 0 0 0 80.434 54.256 Q 80.676 59.794 81.628 64.586 A 46.56 46.56 0 0 0 84.744 74.592 A 52.84 52.84 0 0 0 88.465 81.519 A 40.06 40.06 0 0 0 95.976 90.288 Q 102.816 96.192 111.24 98.784 Q 119.664 101.376 127.728 101.376 A 50.002 50.002 0 0 0 132.548 101.141 A 56.816 56.816 0 0 0 144.216 98.712 Q 152.64 96.048 159.48 90 A 41.158 41.158 0 0 0 165.443 83.418 A 51.681 51.681 0 0 0 170.712 74.304 A 40.717 40.717 0 0 0 172.088 70.866 Q 173.682 66.305 174.434 60.888 A 75.386 75.386 0 0 0 175.104 50.544 A 93.667 93.667 0 0 0 174.931 44.745 Q 174.599 39.41 173.636 34.842 A 45.318 45.318 0 0 0 170.712 25.632 A 50.68 50.68 0 0 0 166.739 18.353 A 37.548 37.548 0 0 0 159.48 10.152 Q 152.64 4.464 144.216 2.232 A 71.781 71.781 0 0 0 141.592 1.589 A 63.02 63.02 0 0 0 127.728 0 A 50.002 50.002 0 0 0 122.908 0.235 A 56.816 56.816 0 0 0 111.24 2.664 Q 102.816 5.328 95.976 11.232 Q 89.136 17.136 84.744 26.784 A 40.72 40.72 0 0 0 83.522 29.784 Q 81.839 34.43 81.049 39.955 Z M 161.424 51.12 A 67.064 67.064 0 0 0 160.546 39.892 Q 159.527 33.902 157.324 29.143 A 30.427 30.427 0 0 0 152.424 21.6 A 29.683 29.683 0 0 0 132.658 11.742 A 41.576 41.576 0 0 0 128.304 11.52 A 42.597 42.597 0 0 0 120.151 12.272 A 35.057 35.057 0 0 0 114.408 13.896 Q 108.144 16.272 103.608 21.024 A 30.504 30.504 0 0 0 98.423 28.482 A 38.505 38.505 0 0 0 96.552 32.904 A 41.824 41.824 0 0 0 94.682 40.531 Q 94.032 44.781 94.032 49.68 A 67.56 67.56 0 0 0 94.911 60.944 Q 95.953 67.098 98.232 72.006 A 31.692 31.692 0 0 0 103.032 79.488 A 29.58 29.58 0 0 0 123.805 89.723 A 41.246 41.246 0 0 0 127.152 89.856 A 41.309 41.309 0 0 0 136.379 88.857 A 35.784 35.784 0 0 0 140.976 87.48 Q 147.312 85.104 151.848 80.208 Q 156.384 75.312 158.904 68.112 A 42.451 42.451 0 0 0 160.754 60.529 Q 161.319 56.85 161.408 52.681 A 73.701 73.701 0 0 0 161.424 51.12 Z'
              id='1'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 194.4 1.296 L 206.496 1.296 L 206.496 62.64 A 46.617 46.617 0 0 0 206.803 68.158 Q 207.128 70.877 207.796 73.168 A 22.245 22.245 0 0 0 208.872 76.104 A 24.554 24.554 0 0 0 211.528 80.672 A 19.171 19.171 0 0 0 215.136 84.384 Q 219.024 87.408 223.992 88.56 Q 228.96 89.712 234.144 89.712 Q 239.328 89.712 244.224 88.56 Q 249.12 87.408 253.008 84.384 A 19.269 19.269 0 0 0 257.517 79.313 A 25.018 25.018 0 0 0 259.2 76.104 A 24.474 24.474 0 0 0 260.692 71.346 Q 261.504 67.514 261.504 62.64 L 261.504 1.296 L 273.6 1.296 L 273.6 65.664 A 35.188 35.188 0 0 1 271.601 77.582 A 33.355 33.355 0 0 1 270.792 79.632 Q 267.984 86.112 262.8 90.936 A 35.924 35.924 0 0 1 253.382 97.199 A 43.012 43.012 0 0 1 250.272 98.496 A 43.513 43.513 0 0 1 239.434 100.964 A 53.768 53.768 0 0 1 234 101.232 A 63.287 63.287 0 0 1 222.647 100.279 Q 216.331 99.126 211.357 96.577 A 30.936 30.936 0 0 1 204.624 91.944 A 30.234 30.234 0 0 1 195.449 75.759 A 45.87 45.87 0 0 1 194.4 65.664 L 194.4 1.296 Z'
              id='2'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 367.632 4.464 L 365.04 15.12 Q 361.44 13.968 357.408 13.032 Q 353.376 12.096 348.552 11.448 Q 343.728 10.8 338.4 10.8 A 33.203 33.203 0 0 0 331.454 11.491 A 24.18 24.18 0 0 0 323.712 14.544 Q 317.664 18.288 313.848 24.12 Q 310.032 29.952 308.304 37.152 Q 306.576 44.352 306.576 51.408 A 54.723 54.723 0 0 0 307.33 60.678 A 43.754 43.754 0 0 0 309.096 67.608 A 41.378 41.378 0 0 0 312.523 75.106 A 33.93 33.93 0 0 0 316.008 79.992 Q 320.4 85.104 326.232 87.84 Q 332.064 90.576 338.544 90.576 Q 344.592 90.576 349.632 89.928 Q 353.491 89.432 356.505 88.894 A 87.025 87.025 0 0 0 358.272 88.56 Q 362.448 87.696 365.76 86.544 L 368.208 96.768 Q 364.32 98.064 359.568 99.072 A 99.772 99.772 0 0 1 355.792 99.801 Q 353.852 100.138 351.681 100.451 A 173.87 173.87 0 0 1 350.208 100.656 A 78.447 78.447 0 0 1 343.895 101.238 A 100.358 100.358 0 0 1 338.544 101.376 A 58.501 58.501 0 0 1 328.535 100.56 A 43.161 43.161 0 0 1 318.6 97.632 A 39.541 39.541 0 0 1 307.069 90.075 A 37.224 37.224 0 0 1 304.344 87.192 A 43.963 43.963 0 0 1 296.849 74.423 A 51.8 51.8 0 0 1 295.776 71.352 A 62.185 62.185 0 0 1 293.236 58.575 A 76.596 76.596 0 0 1 292.896 51.264 Q 292.896 38.736 296.352 29.16 A 51.255 51.255 0 0 1 300.756 19.859 A 41.545 41.545 0 0 1 305.856 13.104 Q 311.904 6.624 320.256 3.312 Q 328.608 0 338.544 0 Q 344.592 0 349.848 0.648 A 106.183 106.183 0 0 1 355.488 1.491 A 83.287 83.287 0 0 1 359.496 2.304 Q 363.888 3.312 367.632 4.464 Z'
              id='3'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 385.632 99.504 L 385.632 1.296 L 397.728 1.296 L 397.728 43.776 L 447.408 43.776 L 447.408 1.296 L 459.504 1.296 L 459.504 99.504 L 447.408 99.504 L 447.408 54.576 L 397.728 54.576 L 397.728 99.504 L 385.632 99.504 Z'
              id='4'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 531.936 99.504 L 509.184 1.296 L 521.424 1.296 L 540.432 87.984 L 540.576 87.984 L 565.776 1.296 L 581.04 1.296 L 605.952 87.984 L 606.096 87.984 L 624.96 1.296 L 637.2 1.296 L 614.592 99.504 L 599.328 99.504 L 573.408 12.96 L 573.264 12.96 L 547.2 99.504 L 531.936 99.504 Z'
              id='6'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 654.048 99.504 L 654.048 1.296 L 666.144 1.296 L 666.144 43.776 L 715.824 43.776 L 715.824 1.296 L 727.92 1.296 L 727.92 99.504 L 715.824 99.504 L 715.824 54.576 L 666.144 54.576 L 666.144 99.504 L 654.048 99.504 Z'
              id='7'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 743.328 99.504 L 777.024 1.296 L 792 1.296 L 825.696 99.504 L 813.168 99.504 L 804.528 73.296 L 764.64 73.296 L 755.856 99.504 L 743.328 99.504 Z M 768.384 61.776 L 800.64 61.776 L 784.8 12.816 L 784.368 12.816 L 768.384 61.776 Z'
              id='8'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 841.104 99.504 L 841.104 1.296 L 853.2 1.296 L 853.2 87.984 L 897.84 87.984 L 897.84 99.504 L 841.104 99.504 Z'
              id='9'
              vectorEffect='non-scaling-stroke'
            />
            <path
              d='M 912.96 99.504 L 912.96 1.296 L 973.152 1.296 L 973.152 12.384 L 925.056 12.384 L 925.056 43.776 L 969.696 43.776 L 969.696 54.576 L 925.056 54.576 L 925.056 88.416 L 974.592 88.416 L 974.592 99.504 L 912.96 99.504 Z'
              id='10'
              vectorEffect='non-scaling-stroke'
            />
          </g>
        </svg>
      </div>
    </Container>
  )
}

export default HomePage
