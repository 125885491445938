import React from 'react'
import { Input, Table, Radio, Button } from 'antd'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { setSelectNot, pushDingNot, modifyNotGood } from '../../actions/picking'

const AddNot = ({
  dingGoods,
  selectNotGood,
  setSelectNot,
  pushDingNot,
  No,
  notgoods,
  orderRecord,
  modifyNotGood,
}) => {
  const columns = [
    {
      title: '品號',
      dataIndex: 'A1ItemDetailID',
      width: '28%',
    },
    {
      title: '品名',
      dataIndex: 'A1ItemName',
      width: '74%',
    },
  ]

  return (
    <div className="one-hundred">
      <Input.Group className="my-1">
        <div className="row">
          <div className="col col-58  col-sm-59 col-md-54 col-lg-71 col-xl-76">
            <Input
              size="small"
              allowClear
              placeholder="搜尋鼎新或鍵入"
              value={selectNotGood.text}
              onChange={(e) => {
                setSelectNot('setText', e.target.value, selectNotGood)
              }}
            />
          </div>
          <div className="col col-39 col-sm-37 col-md-40 col-lg-32 col-xl-28">
            <Radio.Group
              value={selectNotGood.condition}
              onChange={(e) =>
                setSelectNot('condition', e.target.value, selectNotGood)
              }
              size="small"
              className="ml-lg-1 ml-sm-1"
            >
              <Radio.Button value="ban">半價</Radio.Button>
              <Radio.Button value="sha">瑕疵</Radio.Button>
            </Radio.Group>
          </div>
          <div className="col col-12 col-sm-21 col-md-23 col-lg-17 col-xl-16">
            <button
              type="button"
              className="btn btn-link p-0 buttonMP pb-1"
              onClick={() => setSelectNot('mpQ', -1, selectNotGood)}
            >
              <FontAwesomeIcon icon={faMinus} transform="" size="sm" />
            </button>

            <input
              inputMode="numeric"
              pattern="[0-9]*"
              type="text"
              className="number-picker m-0"
              value={selectNotGood.quty}
              onChange={(e) =>
                setSelectNot('setQ', e.target.value, selectNotGood)
              }
              onBlur={(e) =>
                setSelectNot('comSetQ', e.target.value, selectNotGood)
              }
            ></input>
            <button
              type="button"
              className="btn btn-link p-0 buttonMP pb-1"
              onClick={() => setSelectNot('mpQ', 1, selectNotGood)}
            >
              <FontAwesomeIcon icon={faPlus} transform="up-1" size="sm" />
            </button>
          </div>
          <div className="col col-18 col-sm-16 col-md-16 col-lg-16 col-xl-16">
            <Button
              size="small"
              type="primary"
              onClick={() => {
                modifyNotGood(selectNotGood, No, orderRecord._id, notgoods)
              }}
            >
              加入
            </Button>
          </div>
        </div>
      </Input.Group>

      <Table
        dataSource={dingGoods}
        columns={columns}
        className="auto-scroll-y-input"
        showHeader={false}
        bordered
        size="small"
        pagination={false}
        scroll={{ y: '100%' }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              pushDingNot(record)
            },
          }
        }}
      />
    </div>
  )
}

AddNot.propTypes = {
  setSelectNot: PropTypes.func.isRequired,
  modifyNotGood: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  let orderRecord = state.picking.record
  let onPick = state.picking.entry_on_pick
  let notgoods = state.picking.not_goods.filter(
    (ob) => ob.orderRecord === orderRecord._id && ob.No === onPick.No
  )
  let No = onPick.No
  let fil = state.picking.selectNotGood.filText
  return {
    No,
    notgoods,
    orderRecord,
    dingGoods: state.picking.dingGoods
      .map((ob) => ({ ...ob, key: ob._id }))
      .filter(
        (ob) =>
          ob.Type !== '3' &&
          (ob.A1ItemDetailID.includes(fil) || ob.A1ItemName.includes(fil))
      ),
    selectNotGood: state.picking.selectNotGood,
  }
}

export default connect(mapStateToProps, {
  setSelectNot,
  pushDingNot,
  modifyNotGood,
})(AddNot)
