import React from 'react'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import ReactJson from 'react-json-view'
import { connect } from 'react-redux'
import { getShopeeOrderFromDing } from '../../actions/dingAction'
import { reNewAllDataFromDing } from '../../actions/orderProcessAction'
import api from '../../utils/api'
import { Divider } from 'antd'

const Ding = ({
  test_json,
  getShopeeOrderFromDing,
  shopeedataFromdingLoading,
  allDataFromDingLoading,
  reNewAllDataFromDing,
}) => {
  const dowloadJsonTxt = async () => {
    const jsonGot = await getShopeeOrderFromDing()
    api.downloadJson(jsonGot, 'j.txt')
  }
  return (
    <>
      <div className='my-5 mx-3'>
        <Button
          className='mb-2 mx-5'
          loading={shopeedataFromdingLoading}
          icon={<DownloadOutlined className='' />}
          onClick={dowloadJsonTxt}
        >
          提取鼎新那邊的蝦皮訂單資料 {' => '} j.txt，不包含組合資訊
        </Button>
        <Divider orientation='left' plain className='mx-4'>
          {' '}
          我每天早上六點會更新鼎新資料，如果更新過鼎新商品、組合、鼎新的蝦皮對應，可以在下面跑一下我{' '}
        </Divider>
        <div className='mb-4 mx-5'>
          <Button
            loading={allDataFromDingLoading}
            onClick={reNewAllDataFromDing}
          >
            從鼎新下載商品編號、組合資訊、對應資訊，寫單可以用一次，大概要10分鐘
          </Button>
        </div>
        <div className='mx-5'>
          <ReactJson
            collapseStringsAfterLength={100}
            name={false}
            collapsed={1}
            src={test_json}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    shopeedataFromdingLoading: state.ding.shopee_orders.loading,
    test_json: state.ding.test_json,
    allDataFromDingLoading: state.orderProcess.dingRenewAll.view.loading,
  }
}

export default connect(mapStateToProps, {
  getShopeeOrderFromDing,
  reNewAllDataFromDing,
})(Ding)
