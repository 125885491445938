import React, { useState, useRef, useEffect } from 'react'
import api from '../../utils/api'
import {
  Button,
  PageHeader,
  Form,
  Select,
  Modal,
  DatePicker,
  Input,
  Table,
  InputNumber,
  message,
} from 'antd'

const { Option } = Select
const itemStyle = { marginBottom: '8px' }
const GraphPanel = ({
  title,
  isMulti,
  index,
  indexList,
  setIndex,
  timeOption,
  setTimeOption,
  setData,
  timeOptionList,
  timeSelect,
  setTimeSelect,
  disabledDate,
  hasData,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const initialSearchState = {
    shopid: '',
    itemid: '',
    text: '',
    limit: null,
    timeRange: undefined,
    index: 'month_sales',
    indexType: 'absolute',
    sequence: 'descending',
    loading: false,
    loadingTotal: false,
    data: [],
    selectedKeys: [],
  }
  const [searchState, setSearchState] = useState(initialSearchState)

  const tokenSourceRef = useRef(null)
  tokenSourceRef.current = api.source()
  const onSearch = async () => {
    setSearchState((prev) => ({ ...prev, loading: true }))
    try {
      let timeRange
      if (searchState.timeRange) {
        timeRange = [
          searchState.timeRange[0].toISOString(),
          searchState.timeRange[1].toISOString(),
        ]
      }
      const { data } = await api.get('api/scrapeShopee/getLivingList', {
        params: {
          shopid: searchState.shopid,
          itemid: searchState.itemid,
          text: searchState.text,
          limit: searchState.limit,
          timeRange: timeRange,
          index: searchState.index,
          indexType: searchState.indexType,
          sequence: searchState.sequence,
        },
        cancelToken: tokenSourceRef.current.token,
      })
      if (Array.isArray(data)) {
        setSearchState((prev) => ({
          ...prev,
          data: data.map((ob) => ({ ...ob, key: ob._id })),
        }))
      } else {
        message.error('出了一些問題')
      }
    } catch (error) {
      message.error('出了一些問題')
      console.error(error)
    }
    setSearchState((prev) => ({ ...prev, loading: false }))
  }

  const tokenSourceRefGet = useRef(null)
  tokenSourceRefGet.current = api.source()
  const onGetData = async () => {
    if (searchState.selectedKeys.length === 0) {
      message.warning('沒有選擇任何商品，請勾選一些之後再確認喔～～')
      return
    }
    setSearchState((prev) => ({ ...prev, loadingTotal: true, loading: true }))
    try {
      let timeRange
      if (searchState.timeRange) {
        timeRange = [
          searchState.timeRange[0].toISOString(),
          searchState.timeRange[1].toISOString(),
        ]
      }
      let selected = searchState.data
        .filter((data) => searchState.selectedKeys.includes(data._id))
        .map((data) => ({ ...data, _id: data._id }))

      const { data } = await api.post(
        'api/scrapeShopee/getLivingTotal',
        {
          selected,
          timeRange: timeRange,
        },
        {
          cancelToken: tokenSourceRefGet.current.token,
        }
      )
      setData(data)
    } catch (error) {
      message.error('出了一些問題')
      console.error(error)
    }
    setSearchState((prev) => ({ ...prev, loadingTotal: false, loading: false }))
    setModalOpen(false)
  }

  useEffect(() => {
    return () => {
      if (tokenSourceRef.current) {
        tokenSourceRef.current.cancel()
      }
      if (tokenSourceRefGet.current) {
        tokenSourceRefGet.current.cancel()
      }
    }
  }, [])

  let timeSelectionUI
  let timeSelectionUIClassName = 'ml-2'
  let pickerProps = {
    className: 'ml-2',
    disabledDate: hasData ? disabledDate : undefined,
  }
  switch (timeOption) {
    case 'monthsInYear':
      timeSelectionUI = (
        <DatePicker
          {...pickerProps}
          picker='year'
          onChange={(date) => {
            setTimeSelect((prev) => {
              return {
                ...prev,
                monthsInYear: date,
              }
            })
          }}
          value={timeSelect.monthsInYear}
        />
      )
      break
    case 'daysInMonth':
      timeSelectionUI = (
        <DatePicker
          {...pickerProps}
          onChange={(date) => {
            setTimeSelect((prev) => {
              return {
                ...prev,
                daysInMonth: date,
              }
            })
          }}
          value={timeSelect.daysInMonth}
          picker='month'
        />
      )
      break
    case 'daysInWeek':
      timeSelectionUI = (
        <DatePicker
          {...pickerProps}
          picker='week'
          onChange={(date) => {
            setTimeSelect((prev) => {
              return {
                ...prev,
                daysInWeek: date,
              }
            })
          }}
          value={timeSelect.daysInWeek}
          format={(date) =>
            `${date?.startOf('week').format('YYYY-MM-DD')} - ${date
              ?.endOf('week')
              .format('YYYY-MM-DD')}`
          }
        />
      )
      break
    case 'daysInDays':
      timeSelectionUI = (
        <DatePicker.RangePicker
          {...pickerProps}
          onChange={(date) => {
            setTimeSelect((prev) => {
              return {
                ...prev,
                daysInDays: date,
              }
            })
          }}
          value={timeSelect.daysInDays}
        />
      )
      break
    case 'monthsInMonths':
      timeSelectionUI = (
        <DatePicker.RangePicker
          {...pickerProps}
          picker='month'
          onChange={(date) => {
            setTimeSelect((prev) => {
              return {
                ...prev,
                monthsInMonths: date,
              }
            })
          }}
          value={timeSelect.monthsInMonths}
        />
      )
      break
    case 'yearsInYears':
      timeSelectionUI = (
        <DatePicker.RangePicker
          picker='year'
          {...pickerProps}
          onChange={(date) => {
            setTimeSelect((prev) => {
              return {
                ...prev,
                yearsInYears: date,
              }
            })
          }}
          value={timeSelect.yearsInYears}
        />
      )
      break
    case 'monthsInTwoYears':
      timeSelectionUI = (
        <>
          <Form.Item
            style={{
              display: 'inline-block',
              marginBottom: 0,
            }}
          >
            <DatePicker
              {...pickerProps}
              picker='year'
              onChange={(date) => {
                setTimeSelect((prev) => {
                  let second =
                    prev.monthsInTwoYears && prev.monthsInTwoYears.length === 2
                      ? prev.monthsInTwoYears[1]
                      : null
                  if (prev.monthsInTwoYears?.length === 2) {
                    return {
                      ...prev,
                      monthsInTwoYears: [date, second],
                    }
                  } else {
                    return {
                      ...prev,
                      monthsInTwoYears: [date, null],
                    }
                  }
                })
              }}
              value={
                timeSelect.monthsInTwoYears &&
                timeSelect.monthsInTwoYears.length === 2
                  ? timeSelect.monthsInTwoYears[0]
                  : null
              }
            />
          </Form.Item>
          <span
            className='mx-2'
            style={{
              display: 'inline-block',
              width: '24px',
              lineHeight: '24px',
              textAlign: 'center',
              marginBottom: 0,
            }}
          >
            和
          </span>
          <Form.Item
            style={{
              display: 'inline-block',
              marginBottom: 0,
            }}
          >
            <DatePicker
              {...pickerProps}
              className=''
              picker='year'
              onChange={(date) => {
                setTimeSelect((prev) => {
                  if (prev.monthsInTwoYears?.length === 2) {
                    let first = prev.monthsInTwoYears
                      ? prev.monthsInTwoYears[0]
                      : null
                    return {
                      ...prev,
                      monthsInTwoYears: [first, date],
                    }
                  } else {
                    return {
                      ...prev,
                      monthsInTwoYears: [null, date],
                    }
                  }
                })
              }}
              value={
                timeSelect.monthsInTwoYears
                  ? timeSelect.monthsInTwoYears[1]
                  : null
              }
            />
          </Form.Item>
        </>
      )
      break
    case 'monthsInYears':
      timeSelectionUI = (
        <DatePicker
          {...pickerProps}
          format='MMMM'
          picker='month'
          className={timeSelectionUIClassName}
          onChange={(date) => {
            setTimeSelect((prev) => {
              return {
                ...prev,
                monthsInYears: date,
              }
            })
          }}
          value={timeSelect.monthsInYears}
        />
      )
      break
    default:
      timeSelectionUI = <div></div>
      console.log('Unrecognized time option.')
  }

  const onCancelGetDataModal = () => {
    setModalOpen(false)
    setSearchState((prev) => ({ ...prev, loading: false }))
    if (tokenSourceRef.current) {
      tokenSourceRef.current.cancel()
    }
  }

  let searchTableColumn = [
    {
      title: '商品名稱',
      key: 'name',
      ellipsis: true,
      width: 350,
      render: (data, record) => (
        <a
          href={`https://shopee.tw/product/${record.ids.shopid}/${record.ids.itemid}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {record.ids.name}
        </a>
      ),
    },
  ]
  const render = (digit) => (data) =>
    (
      <div>
        <div>{Math.round(data.avg * 10 ** digit) / 10 ** digit}</div>
        <div>
          {typeof data.slope === 'number'
            ? `日趨勢：${Math.round(data.slope * 10 ** digit) / 10 ** digit}`
            : null}
        </div>
      </div>
    )
  const sorter = (index) => (a, b) => {
    const sortingIndex = searchState.indexType === 'absolute' ? 'avg' : 'slope'
    return a[index][sortingIndex] - b[index][sortingIndex]
  }
  const commondColumn = (index, title) => ({
    render: render(2),
    sorter: sorter(index),
    key: index,
    dataIndex: index,
    title,
  })

  const indexSearchTableConlumn = [
    commondColumn('rank', '熱銷排名'),
    commondColumn('month_sales', '月銷量'),
    commondColumn('conversion_rate', '轉化率'),
    commondColumn('total_sales', '總銷量'),
    commondColumn('liked_count', '喜歡數'),
    commondColumn('cmt_count', '評價數'),
    commondColumn('view_count', '瀏覽量'),
    {
      ...commondColumn('item_rating', '評價星數'),
      render: render(4),
    },
  ]

  searchTableColumn = searchTableColumn.concat(
    indexSearchTableConlumn.sort(
      (a, b) =>
        +(b.dataIndex === searchState.index) -
        +(a.dataIndex === searchState.index)
    )
  )

  return (
    <>
      <Modal
        visible={modalOpen}
        onCancel={onCancelGetDataModal}
        title={isMulti ? '從伺服器取資料（複選）' : '從伺服器取資料（單選）'}
        centered
        width={1500}
        okText='確認勾選'
        cancelText='取消'
        onOk={onGetData}
        okButtonProps={{ loading: searchState.loadingTotal }}
      >
        <Form layout='inline' size='small'>
          <Form.Item label='搜尋時間範圍' style={itemStyle}>
            <DatePicker.RangePicker
              disabled={searchState.loading}
              picker='date'
              value={searchState.timeRange}
              onChange={(values) =>
                setSearchState((prev) => ({ ...prev, timeRange: values }))
              }
            />
          </Form.Item>
          <Form.Item
            label='文字搜尋'
            style={itemStyle}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
          >
            <Input
              disabled={searchState.loading}
              allowClear
              placeholder='請輸入文字或是商品網址'
              style={{ width: '325px' }}
              value={searchState.text}
              onChange={(e) => {
                let text = e.target.value
                setSearchState((prev) => ({
                  ...prev,
                  text: e.target.value,
                  shipid: '',
                  itemid: '',
                }))
                if (text && text.includes('http')) {
                  // regex check
                  const linkregex1 =
                    /https?:\/\/shopee\.\w+\/.+-i\.(?<shop_id>\d+)\.(?<product_id>\d+).*/
                  const linkregex2 =
                    /https?:\/\/shopee.\w+\/product\/(?<shop_id>\d+)\/(?<product_id>\d+).*/

                  const result = linkregex1.exec(text) || linkregex2.exec(text)
                  if (!!result) {
                    let shopid = result.groups['shop_id']
                    let itemid = result.groups['product_id']
                    setSearchState((prev) => ({
                      ...prev,
                      shopid,
                      itemid,
                    }))
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item label='最多取的商品數' style={itemStyle}>
            <InputNumber
              disabled={searchState.loading}
              min={1}
              value={searchState.limit}
              placeholder='留白表示不限數量'
              style={{ width: '140px' }}
              onChange={(number) =>
                setSearchState((prev) => ({ ...prev, limit: number }))
              }
            />
          </Form.Item>
          <Form.Item label='排序指標' style={itemStyle}>
            <Select
              disabled={searchState.loading}
              style={{ width: '125px' }}
              value={searchState.index}
              onChange={(value) =>
                setSearchState((prev) => ({ ...prev, index: value }))
              }
            >
              {indexList.map((index) => (
                <Option key={index.label} id={index.label}>
                  {index.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='指標排名方式' style={itemStyle}>
            <Select
              disabled={searchState.loading}
              style={{ width: '180px' }}
              value={searchState.indexType}
              onChange={(value) =>
                setSearchState((prev) => ({ ...prev, indexType: value }))
              }
            >
              <Option key='absolute'>絕對數值</Option>
              <Option key='relative'>成長趨勢（超久）</Option>
            </Select>
          </Form.Item>
          <Form.Item label='排序方向' style={itemStyle}>
            <Select
              disabled={searchState.loading}
              style={{ width: '135px' }}
              value={searchState.sequence}
              onChange={(value) =>
                setSearchState((prev) => ({ ...prev, sequence: value }))
              }
            >
              <Option key='descending'>數值大的在前面</Option>
              <Option key='ascending'>數值小的在前面</Option>
            </Select>
          </Form.Item>

          <Form.Item style={itemStyle}>
            <Button
              type='primary'
              onClick={onSearch}
              loading={searchState.loading && !searchState.loadingTotal}
              disabled={searchState.loadingTotal}
            >
              搜尋
            </Button>
            <Button
              type='default'
              className='ml-2'
              onClick={() => setSearchState(initialSearchState)}
              disabled={searchState.loading}
            >
              重設
            </Button>
          </Form.Item>
        </Form>
        <Table
          rowSelection={{
            type: isMulti ? 'checkbox' : 'radio',
            onChange: (selectedKeys, selectedRows) => {
              setSearchState((prev) => ({ ...prev, selectedKeys }))
            },
            selectedRowKeys: searchState.selectedKeys,
          }}
          size='small'
          loading={searchState.loading}
          dataSource={searchState.data}
          columns={searchTableColumn}
        />
      </Modal>
      <PageHeader title={title} />
      <Form
        layout='horizontal'
        className='ml-1'
        size='small'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
      >
        <Form.Item style={itemStyle} label='下載資料'>
          <Button
            type='dashed'
            block
            className='ml-2'
            size='small'
            onClick={() => setModalOpen(true)}
          >
            {isMulti ? '從伺服器取資料（複選）' : '從伺服器取資料（單選）'}
          </Button>
        </Form.Item>
        <Form.Item style={itemStyle} label='觀察指標'>
          <Select
            allowClear={!isMulti}
            mode={isMulti ? null : 'multiple'}
            className='ml-2 multiselect'
            value={index}
            onChange={(value) => setIndex(value)}
            size='small'
          >
            {indexList.map((index) => (
              <Option key={index.label} id={index.label}>
                {index.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={itemStyle} label='統計方式'>
          <Select
            className='ml-2'
            value={timeOption}
            onChange={(value) => setTimeOption(value)}
            size='small'
          >
            {timeOptionList.map((index) => (
              <Option key={index.label} id={index.label}>
                {index.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={itemStyle} label='時間範圍'>
          {timeSelectionUI}
        </Form.Item>
      </Form>
    </>
  )
}

export default GraphPanel
