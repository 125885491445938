import api from '../utils/api'
import {
  TEST,
  DELETE_KEYWORD,
  GET_SCRAPERAPIS,
  NEW_SCRAPERAPI,
  UPDATE_SCRAPERAPI,
  DELETE_SCRAPERAPI,
} from './types'

const config = {
  'Comtent-Type': 'application/json',
}

export const testApi = (url, referer) => async (dispatch) => {
  try {
    const res = await api.post(
      'api/scrapeShopee/test',
      { url, referer },
      config
    )
    dispatch({ type: TEST, payload: res.data })
  } catch (err) {
    console.log(err)
  }
}

export const deleteKeyword = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_KEYWORD,
      payload: keyword,
    })
    await api.delete(`api/scrapeShopee/keyword/${keyword}`)
  } catch (err) {
    console.log(err)
  }
}

export const getScraperApis = () => async (dispatch) => {
  try {
    const res = await api.get('api/scrapeShopee/scraperApi')
    dispatch({
      type: GET_SCRAPERAPIS,
      payload: res.data,
    })
  } catch (err) {
    console.log(err)
  }
}

export const newScraperApi = (new_api) => async (dispatch) => {
  try {
    const res = await api.post('api/scrapeShopee/scraperApi', new_api, config)
    dispatch({
      type: NEW_SCRAPERAPI,
      payload: res.data,
    })
  } catch (err) {
    console.log(err)
  }
}

export const updateScraperApi = (id, modification) => async (dispatch) => {
  try {
    const res = await api.put(
      `api/scrapeShopee/scraperApi/${id}`,
      modification,
      config
    )
    dispatch({
      type: UPDATE_SCRAPERAPI,
      payload: res.data,
    })
  } catch (err) {
    console.log(err)
  }
}

export const deleteScraperApi = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SCRAPERAPI,
      payload: id,
    })
    await api.delete(`api/scrapeShopee/scraperApi/${id}`)
  } catch (err) {
    console.log(err)
  }
}
