import './OrderRangeStep.css'
import React from 'react'
import { connect } from 'react-redux'
import { Form, Select, InputNumber } from 'antd'
import { renewRange } from '../../../../actions/orderProcessAction'

const OrderRangeStep = ({ orders, range, renewRange }) => {
  return (
    <Form
      colon={false}
      className='m-3 mt-5'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 20,
      }}
    >
      <Form.Item label='第一張訂單：'>
        <Select
          value={range.from}
          showSearch
          placeholder='輸入以選擇訂單'
          options={orders.map((order) => ({
            value: order.No,
            key: order.No,
            label: `${order.No} @${order.ReceiverName} ${order.BuyAccount} ${order.ShippingName} $${order.TotalAmount}`,
          }))}
          onSelect={(text, opt) => {
            renewRange(opt.value, range.to, range.startNum)
          }}
        />
      </Form.Item>
      <Form.Item label='最後一張訂單：'>
        <Select
          showSearch
          value={range.to}
          placeholder='輸入以選擇訂單'
          options={orders.map((order) => ({
            value: order.No,
            key: order.No,
            label: `${order.No} @${order.ReceiverName} ${order.BuyAccount} ${order.ShippingName} $${order.TotalAmount}`,
          }))}
          onSelect={(text, opt) => {
            renewRange(range.from, opt.value, range.startNum)
          }}
        />
      </Form.Item>
      <Form.Item label='從幾號開始編：'>
        <InputNumber
          min={1}
          value={range.startNum}
          onChange={(val) => {
            renewRange(range.from, range.to, val)
          }}
        />
      </Form.Item>
    </Form>
  )
}
const mapStateToProps = (state) => {
  return {
    orders: state.orderProcess.generate.orders, // [{No,BuyAccount,TotalAmount,ReceiverName,ReceiverPhone,ShippingName,OrderDate}]
    range: state.orderProcess.generate.view.range,
  }
}

export default connect(mapStateToProps, { renewRange })(OrderRangeStep)
