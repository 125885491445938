import axios from 'axios'
import api from './api'

/**
 *
 * @param {string} recordId
 */
export const downloadLabelPdf = async (
  recordId,
  { onUnknown, onStarted, onFailed, onWillProcess } = {
    onUnknown: () => {},
    onStarted: () => {},
    onFailed: () => {},
    onWillProcess: () => {},
  }
) => {
  try {
    // check status or recordId
    const {
      data: { labelPdfStatus },
    } = await api.get(`api/orderRecords/records/${recordId}/status`)
    switch (labelPdfStatus) {
      case 'unknown':
        onUnknown()
        return
      case 'will process':
        onWillProcess()
        return
      case 'process started':
        onStarted()
        return
      case 'process failed':
        onFailed()
        return
      case 'process succeed':
        const response = await axios({
          url: `https://order-record-labels.s3.us-west-000.backblazeb2.com/${recordId}.pdf`,
          method: 'GET',
          responseType: 'blob',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', '商品標示資料.pdf') //or any other extension
        document.body.appendChild(link)
        link.click()
        return
      default:
        onFailed()
        return
    }
  } catch (err) {
    console.error(err)
    onFailed()
  }
}
