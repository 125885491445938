import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PickingPage from '../picking/PickingPage'
import VocabPage from '../vocabulary/VocabPage'
import OrderRecord from '../picking/OrderRecord'
import StoreDataPage from '../scrape/StoreDataPage'
import PurchaingPage from '../purchasing/PurchasingPage'
import OrderProcessingPage from '../order_processing/OrderProcessingPage'
import HomePage from '../homepage/Homepage.component'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

const Routes = ({ record_is_picking }) => {
  return (
    
      <Switch>
        <Route path='/' exact component={HomePage} />
        <Route path='/picking' component={PickingPage} />
        <Route path='/vocab' component={VocabPage} />
        <Route path='/picking_details/:Id' component={OrderRecord} />
        <Route path='/store_data' component={StoreDataPage} />
        <Route path='/purchasing' component={PurchaingPage} />
        <Route path='/order_processing' component={OrderProcessingPage} />
      </Switch>
  )
}

// Routes.propTypes = {
//   record_is_picking: PropTypes.object.isRequired,
// }

const mapStateToProps = (state) => ({
  record_is_picking: state.picking.record,
})

export default connect(mapStateToProps)(Routes)
