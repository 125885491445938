import React, { Fragment, useEffect } from 'react';

import { Container } from 'react-bootstrap';
import OrderRecordItem from './OrderRecordItem';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { getRecords } from '../../actions/picking';

const PickingRecords = ({ getRecords, picking: { records, loading } }) => {
  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return loading ? (
    <Container fluid='xl' className='justify-content-center my-4'>
      <Spinner animation='border' variant='primary' />
    </Container>
  ) : (
    <Fragment>
      <Container fluid='xxl' className='my-3 d-flex over-flow'>
        <div
          className='row row-cols-1 row-cols-md-2 row-cols-xl-3 
          record-container'
        >
          {records.map((record, ind) => (
            <OrderRecordItem
              key={record._id}
              record={record}
              recordIndex={ind}
            />
          ))}
        </div>
      </Container>
    </Fragment>
  );
};

PickingRecords.propTypes = {
  getRecords: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  picking: state.picking,
});

export default connect(mapStateToProps, { getRecords })(PickingRecords);
