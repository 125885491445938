import { dateLateFront } from '../utils/sorting'
import api from '../utils/api'
import {
  CLEAR_PICKING_DETAILS,
  GET_RECORDS,
  SET_RECORDS,
  GET_PICKING_DETAILS,
  PICKING_ERROR,
  SWITCH_ORDER,
  SWITCH_ENTRY,
  SET_ENTRY_CONDITION,
  SET_ENTRY_QUTY,
  MODIFY_NOT_GOOD,
  CHANGE_NOT_GOOD,
  DELETE_ONE_NOT_GOOD,
  SET_SELECT_NOT_GOOD,
  PUSH_DING_NOT_GOOD,
  GET_ALL_NOT_GOOD,
  SET_NOT_DATE_RANGE,
  SET_NOT_FILTER_SOL,
  SEARCH_NOT_GOOD,
  COPIED_NOT_GOOD,
  SELECT_NOT_GOOD,
  COMPLETE_NOT_GOOD,
  DELETE_RECORD_ENTRY_MANY,
  DELETE_RECORD_ENTRY_ONE,
  COPIED_GIFT,
  GIFT_DONE,
} from './types'
import { history } from '../configureStore'

const config = {
  'Comtent-Type': 'application/json',
}

export const getRecords = () => async (dispatch) => {
  try {
    const res = await api.get('api/orderRecords/records')
    dispatch({ type: GET_RECORDS, payload: res.data.sort(dateLateFront) })
  } catch (err) {
    console.log(err)
  }
}

export const setRecord = (record) => (dispatch) => {
  dispatch({ type: SET_RECORDS, payload: record })
}

export const getPickingDetails = (id) => async (dispatch) => {
  try {
    const res = await api.get(`api/orderRecords/entry/${id}`)
    if (res.data.record_to_pick) {
      dispatch({ type: GET_PICKING_DETAILS, payload: res.data })
    } else {
      dispatch({ type: PICKING_ERROR, payload: res.data })
      history.push('/picking/record')
    }
  } catch (err) {
    console.log(err)
  }
}

export const clearPickingDetails = () => (dispatch) => {
  dispatch({ type: CLEAR_PICKING_DETAILS, payload: null })
}

export const switchOrder = (No) => (dispatch) => {
  dispatch({ type: SWITCH_ORDER, payload: No })
}

export const switchEntry = (entry) => (dispatch) => {
  dispatch({ type: SWITCH_ENTRY, payload: entry })
}

export const setEntryCondition = (record, value) => (dispatch) => {
  dispatch({
    type: SET_ENTRY_CONDITION,
    payload: { ...record, condition: value },
  })
}

export const qutyChange = (method, value, record) => (dispatch) => {
  let numberVal = parseInt(value)
  let reVal = record.quty
  switch (method) {
    case 'add':
      if (isNaN(numberVal)) {
        return null
      }
      if (reVal === '') {
        reVal = 0
      }
      dispatch({
        type: SET_ENTRY_QUTY,
        payload: { ...record, quty: reVal + 1 },
      })
      break
    case 'minus':
      if (isNaN(numberVal)) {
        return null
      }
      if (reVal === '') {
        reVal = 0
      }
      if (reVal - 1 < 1) {
        reVal = 2
      }
      dispatch({
        type: SET_ENTRY_QUTY,
        payload: { ...record, quty: reVal - 1 },
      })
      break
    case 'set':
      if (value !== '' && isNaN(numberVal)) {
        return null
      }
      if (value === '') {
        dispatch({ type: SET_ENTRY_QUTY, payload: { ...record, quty: value } })
        return null
      }
      if (numberVal < 1) {
        dispatch({ type: SET_ENTRY_QUTY, payload: { ...record, quty: 1 } })
        return null
      }
      dispatch({
        type: SET_ENTRY_QUTY,
        payload: { ...record, quty: numberVal },
      })
      break
    case 'set_comp':
      if (value !== '' && isNaN(numberVal)) {
        return null
      }
      if (value === '') {
        dispatch({ type: SET_ENTRY_QUTY, payload: { ...record, quty: 1 } })
        return null
      }
      if (numberVal < 1) {
        dispatch({ type: SET_ENTRY_QUTY, payload: { ...record, quty: 1 } })
        return null
      }
      dispatch({
        type: SET_ENTRY_QUTY,
        payload: { ...record, quty: numberVal },
      })
      break

    default:
  }
}

export const modifyNotGood =
  (record, No, orderRecord, notgoods) => async (dispatch) => {
    // see if has the same kind
    let recordIdentify = ''
    switch (record.type) {
      case 'set':
      case 'single':
        recordIdentify = record.A1ItemDetailID
        break
      case 'unset':
        recordIdentify = record.A1ItemName
        break
      default:
        return
    }
    let samekind = notgoods.find((ob) => {
      return (
        ob.No === No &&
        orderRecord === ob.orderRecord &&
        record.condition === ob.condition &&
        ob.IdentifyString === recordIdentify
      )
    })

    if (!samekind) {
      // create
      let notgood
      switch (record.type) {
        case 'set':
        case 'single':
          notgood = {
            IdentifyString: record.A1ItemDetailID,
            A1ItemDetailID: record.A1ItemDetailID,
            A1ItemName: record.A1ItemName,
            A1orNot: true,
          }
          break
        case 'unset':
          if (record.A1ItemName === '') {
            return
          }
          notgood = {
            A1ItemName: record.A1ItemName,
            IdentifyString: record.A1ItemName,
            A1orNot: false,
          }
          break
        default:
          return
      }
      notgood = {
        ...notgood,
        orderRecord,
        No,
        condition: record.condition,
        quty: record.quty,
      }
      const res = await api.post('api/orderRecords/notGood/', notgood, config)
      dispatch({ type: MODIFY_NOT_GOOD, payload: res.data })
    } else {
      // modify
      const res = await api.post(
        `api/orderRecords/notGood/${samekind._id}`,
        record,
        config
      )
      dispatch({ type: MODIFY_NOT_GOOD, payload: res.data })
    }
  }

export const changeNotGooD = (field, record, value) => async (dispatch) => {
  const config = {
    'Comtent-Type': 'application/json',
  }
  let numberVal = parseInt(value)
  let reVal = record.quty
  let payload
  let sentRemote = true
  switch (field) {
    case 'amQuty':
      if (isNaN(numberVal)) {
        return null
      }
      if (reVal === '') {
        reVal = 0
      }
      if (reVal + numberVal < 1) {
        return null
      }
      payload = { ...record, quty: reVal + numberVal }
      break
    case 'setQuty':
      if (value !== '' && isNaN(numberVal)) {
        return null
      }
      if (value === '') {
        payload = { ...record, quty: value }
        sentRemote = false
      } else {
        if (numberVal < 1) {
          payload = { ...record, quty: 1 }
          return null
        }
        payload = { ...record, quty: numberVal }
      }
      break
    case 'setQuty_comp':
      if (isNaN(numberVal)) {
        payload = { ...record, quty: 1 }
      }
      if (numberVal < 1) {
        payload = { ...record, quty: 1 }
        return null
      }
      payload = { ...record, quty: numberVal }
      break
    case 'condition':
      if (value !== 'ban' && value !== 'sha') {
        return null
      }
      payload = { ...record, condition: value }
      break
    default:
      return
  }
  if (sentRemote) {
    await api.post('api/orderRecords/notGood_change', payload, config)
  }
  dispatch({ type: CHANGE_NOT_GOOD, payload })
}

export const deleteNotGood = (id) => async (dispatch) => {
  try {
    await api.delete(`api/orderRecords/notGood/${id}`)
    dispatch({ type: DELETE_ONE_NOT_GOOD, payload: id })
  } catch (err) {}
}

export const setSelectNot = (method, value, select) => (dispatch) => {
  let payload = {}
  let numQuty = parseInt(select.quty)
  if (isNaN(numQuty)) {
    numQuty = 0
  }
  let numValue = parseInt(value)
  switch (method) {
    case 'mpQ':
      if (numQuty + value < 1) {
        payload = { ...select, quty: 1 }
      } else {
        payload = { ...select, quty: numQuty + value }
      }
      break
    case 'setQ':
      if (value !== '' && isNaN(numValue)) {
        return
      }
      if (value === '') {
        payload = { ...select, quty: value }
      } else {
        payload = { ...select, quty: numValue }
      }
      break
    case 'comSetQ':
      if (isNaN(numValue)) {
        payload = { ...select, quty: 1 }
      } else {
        payload = { ...select, quty: numValue }
      }
      break
    case 'condition':
      payload = { ...select, condition: value }
      break
    case 'setText':
      payload = { ...select, text: value, A1ItemName: value }
      break
    default:
      break
  }
  dispatch({ type: SET_SELECT_NOT_GOOD, payload })
}

export const pushDingNot = (record) => (dispatch) => {
  dispatch({
    type: PUSH_DING_NOT_GOOD,
    payload: {
      A1ItemDetailID: record.A1ItemDetailID,
      A1ItemName: record.A1ItemName,
    },
  })
}

export const getAllNot = () => async (dispatch) => {
  const notgoods = await api.get('api/orderRecords/notGood')
  dispatch({ type: GET_ALL_NOT_GOOD, payload: notgoods.data })
}

export const setNotGoodRange = (moments) => (dispatch) => {
  dispatch({ type: SET_NOT_DATE_RANGE, payload: moments })
}

export const setNotFilterSol = (fil) => (dispatch) => {
  dispatch({ type: SET_NOT_FILTER_SOL, payload: fil })
}

export const searchNot = (range, sol) => async (dispatch) => {
  const notgoods = await api.post(
    'api/orderRecords/search/notGood',
    { range, sol },
    config
  )
  dispatch({ type: SEARCH_NOT_GOOD, payload: notgoods.data })
}

export const copiedNotGood = (record, field) => (dispatch) => {
  dispatch({ type: COPIED_NOT_GOOD, payload: { record, field } })
}

export const selectNotGood = (keys) => (dispatch) => {
  dispatch({ type: SELECT_NOT_GOOD, payload: keys })
}

export const completeNotGood = (total, keys) => async (dispatch) => {
  if (keys.length === 0) {
    console.log('no selection, no aciton')
    return
  }
  let total_filter = total.filter((ob) => keys.includes(ob.key))
  let total_need_complete = total_filter
    .reduce((prev, curr) => {
      return prev.concat(curr.members)
    }, [])
    .map((ob) => ob._id)
  await api.post(
    'api/orderRecords/completeMany/notgood',
    total_need_complete,
    config
  )
  dispatch({ type: COMPLETE_NOT_GOOD, payload: total_filter })
}

export const deleteRecord = (id, time, all) => async (dispatch) => {
  try {
    let ids = await api.post(`api/orderRecords/clearRecord/${id}`, {
      all,
      time,
    })
    ids = ids.data
    if (all) {
      dispatch({ type: DELETE_RECORD_ENTRY_MANY, payload: ids })
    } else {
      dispatch({ type: DELETE_RECORD_ENTRY_ONE, payload: id })
    }
  } catch (err) {
    console.log(err.message)
  }
}

export const copiedGift = (record, index, field) => (dispatch) => {
  dispatch({ type: COPIED_GIFT, payload: { rec: record, index, fie: field } })
}

export const doneGift = (record) => async (dispatch) => {
  try {
    let ii = record._id
    await api.get(`api/orderRecords/giftsDone/${ii}`)
    dispatch({ type: GIFT_DONE, payload: record })
  } catch (err) {
    console.log(err.message)
  }
}
