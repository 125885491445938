import './OrderGenerateTab.css'
import React from 'react'
import { connect } from 'react-redux'
import { Steps } from 'antd'
import DataCollectStep from './DataCollect/DataCollectStep'
import DataCorrStep from './DataCorrespond/DataCorrStep'
import OrderRangeStep from './OrderRange/OrderRangeStep'
import GiftEventStep from './GiftEvent/GiftEventStep'
import QtyRemindStep from './QtyRemind/QtyRemindStep'
import StepFooter from '../components/StepsFooter/StepsFooter'
import { LoadingOutlined } from '@ant-design/icons'

const { Step } = Steps

const stepClassName = 'px-5'
const stepsComponents = [
  <DataCollectStep className={stepClassName} />,
  <DataCorrStep />,
  <OrderRangeStep />,
  <GiftEventStep />,
  <QtyRemindStep />,
  
]

const OrderGenerateTab = ({ steps, current, loading }) => {
  return (
    <div className='m-4 tab'>
      <Steps className='mt-4 px-md-5' current={current}>
        {steps.map((item, ind) => (
          <Step
            key={item.title}
            title={item.title}
            status={item.status}
            icon={ind === current && loading ? <LoadingOutlined /> : null}
          />
        ))}
      </Steps>
      <div className='generate-tab w100 px-md-5 mt-3'>
        {stepsComponents[current]}
      </div>
      <StepFooter />
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    steps: state.orderProcess.generate.steps,
    current: state.orderProcess.generate.view.current,
    loading: state.orderProcess.generate.view.loading,
  }
}

export default connect(mapStateToProps, null)(OrderGenerateTab)
