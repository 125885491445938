import React from 'react'
import './DataCollectStep.css'
import { connect } from 'react-redux'
import { Divider, Button, Upload } from 'antd'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import {
  requestOrdersFromDing,
  uploadOrdersFromShopeeExcel,
} from '../../../../actions/orderProcessAction'

const DataCollectStep = ({
  className,
  requestOrdersFromDing,
  uploadOrdersFromShopeeExcel,
  disable,
  dingLoading,
  shopeeExcelUploading,
}) => {
  return (
    <div className={className}>
      <Divider className='mt-5' orientation='left' plain>
        從鼎新下載資料
      </Divider>
      <Button
        className='mx-5 mt-3'
        loading={dingLoading}
        icon={<DownloadOutlined />}
        onClick={requestOrdersFromDing}
        disabled={disable}
      >
        提取鼎新上的蝦皮訂單資料（記得鼎新要先下載訂單過優）
      </Button>
      <Divider className='mt-5' orientation='left' plain>
        上傳蝦皮匯出的 Excel
      </Divider>
      <Upload
        customRequest={uploadOrdersFromShopeeExcel}
        showUploadList={false}
      >
        <Button
          className='mx-5 mt-3'
          icon={<UploadOutlined />}
          disabled={disable}
          loading={shopeeExcelUploading}
        >
          {' '}
          上傳蝦皮匯出的 Excel
        </Button>
      </Upload>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    disable: state.orderProcess.generate.view.loading,
    dingLoading: state.orderProcess.generate.view.collect.dingLoading,
    shopeeExcelUploading:
      state.orderProcess.generate.view.collect.shopeeExcelUploading,
  }
}
export default connect(mapStateToProps, {
  requestOrdersFromDing,
  uploadOrdersFromShopeeExcel,
})(DataCollectStep)
