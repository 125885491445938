import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  PageHeader,
  Button,
  Descriptions,
  DatePicker,
  Table,
  Radio,
  Tooltip,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faTrashAlt,
  faLaptopMedical,
  faCopy,
} from '@fortawesome/free-solid-svg-icons'
import {
  getAllNot,
  setNotGoodRange,
  setNotFilterSol,
  searchNot,
  copiedNotGood,
  selectNotGood,
  completeNotGood,
} from '../../actions/picking'

const { RangePicker } = DatePicker

const copyToClipBoard = (data) => {
  navigator.clipboard.writeText(data)
}

const TotalNotGoods = ({
  getAllNot,
  setNotGoodRange,
  setNotFilterSol,
  searchNot,
  copiedNotGood,
  selectNotGood,
  completeNotGood,
  total_not_good: { loading, data, dateRange, Solved, selections },
}) => {
  const rowSelection = {
    columnWidth: '32px',
    selectedRowKeys: selections,
    onChange: (selectedRowKeys, _) => {
      selectNotGood(selectedRowKeys)
    },
  }
  const columns = [
    {
      title: '鼎新編號',
      dataIndex: 'A1ItemDetailID',
      width: '35%',
      render: (_0, record, _1) => (
        <div style={record.A1ItemDetailID_copied ? { color: '#26a7de' } : null}>
          <Tooltip title={`已複製「${record.A1ItemDetailID}」`} trigger="click">
            <Button
              className="mr-1"
              type="link"
              size="small"
              icon={<FontAwesomeIcon icon={faCopy} size="sm" color="#2ba32b" />}
              onClick={() => {
                copiedNotGood(record, 'A1ItemDetailID')
                copyToClipBoard(record.A1ItemDetailID)
              }}
            ></Button>
          </Tooltip>

          {record.A1ItemDetailID}
        </div>
      ),
    },
    {
      title: '數量',
      dataIndex: 'quty',
      align: 'center',
      width: '15%',
      render: (_0, record, _1) => (
        <div style={record.quty_copied ? { color: '#26a7de' } : null}>
          <Tooltip title={`已複製「${-record.quty}」`} trigger="click">
            <Button
              className="mr-1"
              type="link"
              size="small"
              icon={<FontAwesomeIcon icon={faCopy} size="sm" color="#2ba32b" />}
              onClick={() => {
                copyToClipBoard(-record.quty)
                copiedNotGood(record, 'quty')
              }}
            ></Button>
          </Tooltip>
          {record.quty}
        </div>
      ),
    },
    {
      title: '鼎新商品名',
      dataIndex: 'A1ItemName',
      width: '40%',
      render: (_0, record, _1) => (
        <div style={record.A1ItemName_copied ? { color: '#26a7de' } : null}>
          <Tooltip title={`已複製「${record.A1ItemName}」`} trigger="click">
            <Button
              className="mr-1"
              type="link"
              size="small"
              icon={<FontAwesomeIcon icon={faCopy} size="sm" color="#2ba32b" />}
              onClick={() => {
                copiedNotGood(record, 'A1ItemName')
                copyToClipBoard(record.A1ItemName)
              }}
            ></Button>
          </Tooltip>
          {record.A1ItemName}
        </div>
      ),
    },
    {
      title: '狀態',
      dataIndex: 'quty',
      align: 'center',
      width: '10%',
      render: (_0, record, _1) => {
        if (record.Solved) {
          return '歸檔'
        } else {
          return '待歸'
        }
      },
    },
  ]
  useEffect(() => {
    getAllNot()
  }, [getAllNot])
  return (
    <div className="d-flex flex-column fix-page not-good-container container-xl w100">
      <PageHeader
        className="px-3 pb-0 mb-0 pt-4 page-inner-margin-adjust w100"
        ghost={false}
        title="不良品資料"
        subTitle="將撿貨時發現的瑕疵品與半價，從鼎新庫存中開一張調整單扣除"
      >
        <Descriptions column={5} className="m-2">
          <Descriptions.Item size="small" label="日期" span={3}>
            <RangePicker
              size="middle"
              onChange={(dates) => {
                setNotGoodRange(dates)
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item size="small" label="種類" span={2}>
            <Radio.Group
              size="small"
              onChange={(e) => {
                setNotFilterSol(e.target.value)
              }}
              defaultValue={false}
            >
              <Radio.Button value={false}>未歸檔</Radio.Button>
              <Radio.Button value={true}>已歸檔</Radio.Button>
              <Radio.Button value="all">全部</Radio.Button>
            </Radio.Group>
          </Descriptions.Item>
          <Descriptions.Item size="small" label="操作" span={3}>
            <Button
              type="primary"
              size="small"
              className="ml-2"
              icon={
                <FontAwesomeIcon className="mr-2" icon={faSearch} size="sm" />
              }
              onClick={() => {
                searchNot(dateRange, Solved)
              }}
            >
              搜尋
            </Button>
            <Button
              type="primary"
              size="small"
              className="ml-2"
              icon={
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faLaptopMedical}
                  size="sm"
                />
              }
              onClick={() => completeNotGood(data, selections)}
            >
              歸檔
            </Button>
            <Button
              type="danger"
              size="small"
              className="ml-2"
              icon={
                <FontAwesomeIcon className="mr-2" icon={faTrashAlt} size="sm" />
              }
            >
              刪除
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Table
        className={
          data.length === 0
            ? 'auto-scroll-y-complete w100 empty'
            : 'auto-scroll-y-complete w100'
        }
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 'calc(100% - 39px)' }}
        size="small"
        bordered
        loading={loading}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    total_not_good: state.picking.total_not_good,
  }
}

export default connect(mapStateToProps, {
  getAllNot,
  setNotGoodRange,
  setNotFilterSol,
  searchNot,
  copiedNotGood,
  selectNotGood,
  completeNotGood,
})(TotalNotGoods)
