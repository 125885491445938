import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Table } from 'antd'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectIndividualItems } from '../../../../../actions/dingAction'
const columns = [
  {
    title: '鼎新品號',
    dataIndex: 'a1id',
    width: '40%',
    render: (text, record, index) => (
      <div>
        {record.imagKey ? (
          <FontAwesomeIcon
            icon={faChartArea}
            className='mr-1'
            size='sm'
            color='#5caabd'
          />
        ) : null}
        {text}
      </div>
    ),
  },
  {
    title: '鼎新品號',
    dataIndex: 'a1name',
    width: '60%',
  },
]
const ItemSelectionTable = ({ items, itemLoading, selectIndividualItems }) => {
  let { a1id } = useParams()

  const selectedId = useRef(a1id ? a1id : '')
  const a1items = useRef({})
  const rowClickHandler = (a1id_select) => {
    if (selectedId.current) {
      a1items.current[selectedId.current].className = 'clickable'
    }
    a1items.current[a1id_select].className = 'ant-table-row-selected clickable'
    selectedId.current = a1id_select
    selectIndividualItems(a1id_select)
  }

  useEffect(() => {
    const scrolltoUrl = () => {
      if (a1id && a1items.current[a1id] && a1id in a1items.current) {
        a1items.current[a1id].scrollIntoView()
        a1items.current[a1id].className = 'ant-table-row-selected clickable'
        selectedId.current = a1id
        selectIndividualItems(a1id)
      }
    }
    scrolltoUrl()
  })

  return (
    <Table
      hideSelectAll
      // rowClassName={(record, index) => record.className}
      loading={itemLoading}
      pagination={false}
      className={
        items.length === 0 || itemLoading
          ? 'table-column empty'
          : 'table-column'
      }
      onRow={(record, rowIndex) => ({
        ref: (element) => {
          a1items.current[record.a1id] = element
        },
        onClick: (event) => {
          rowClickHandler(record.a1id)
        }, // click row
      })}
      size='small'
      scroll={{ y: 'calc(100% - 39px)' }}
      style={{
        overflowY: 'auto',
      }}
      columns={columns}
      dataSource={items}
      bordered
    />
  )
}
const mapStateToProps = (state) => {
  let searchText = state.ding.dingIndividualItemSelect.searchText
  // let selectedId = state.ding.dingIndividualItemSelect.selectedId
  let items = state.ding.dingItems.data
  if (searchText) {
    items = items.filter(
      (ob) => ob.a1id.includes(searchText) || ob.a1name.includes(searchText)
    )
  }
  return {
    items,
    itemLoading: state.ding.dingItems.loading,
  }
}

export default connect(mapStateToProps, { selectIndividualItems })(
  ItemSelectionTable
)
