import { Button, Modal, Table, Tooltip, message } from 'antd'
import React, { useState } from 'react'
import { copiedGift, deleteRecord, doneGift } from '../../actions/picking'
import { faCopy, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { downloadLabelPdf } from '../../utils/pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import PickingTextModal from './PickingTextModal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const OrderRecordItem = ({
  record,
  deleteRecord,
  copiedGift,
  doneGift,
  recordIndex,
}) => {
  const [clearRecordModalState, setClearRecordModal] = useState({
    visible: false,
  })

  const { gifts, giftsDone, numberOfOrder } = record

  const [giftModalState, setGiftModal] = useState(false)
  const [pickTextModalOpen, setPickTextModalOpen] = useState(false)

  const { visible } = clearRecordModalState

  const onClearMany = () => {
    setClearRecordModal({ ...clearRecordModalState, visible: false })
    deleteRecord(record._id, record.date, true)
  }

  const onClearOne = () => {
    setClearRecordModal({ ...clearRecordModalState, visible: false })
    deleteRecord(record._id, record.date, false)
  }

  const onCancel = () => {
    setClearRecordModal({ ...clearRecordModalState, visible: false })
  }

  const onOpen = () => {
    setClearRecordModal({ ...clearRecordModalState, visible: true })
  }

  const onOpenGiftModal = () => {
    setGiftModal(true)
  }

  const onCancelGiftModal = () => {
    setGiftModal(false)
  }

  const copyToClipBoard = (data) => {
    navigator.clipboard.writeText(data)
  }

  const onGiftDone = () => {
    doneGift(record)
    setGiftModal(false)
  }

  const columns = [
    {
      title: '鼎新編號',
      dataIndex: 'dingName',
      width: '35%',
      render: (_0, gift, index) => (
        <div style={gift.dingName_copied ? { color: '#26a7de' } : null}>
          <Tooltip title={`已複製「${gift.dingName}」`} trigger='click'>
            <Button
              className='mr-1'
              type='link'
              size='small'
              icon={<FontAwesomeIcon icon={faCopy} size='sm' color='#2ba32b' />}
              onClick={() => {
                copiedGift(record, index, 'dingName')
                copyToClipBoard(gift.dingName)
              }}
            ></Button>
          </Tooltip>

          {gift.dingName}
        </div>
      ),
    },
    {
      title: '數量',
      dataIndex: 'quantity',
      align: 'center',
      width: '15%',
      render: (_0, gift, index) => (
        <div style={gift.quantity_copied ? { color: '#26a7de' } : null}>
          <Tooltip title={`已複製「${gift.quantity}」`} trigger='click'>
            <Button
              className='mr-1'
              type='link'
              size='small'
              icon={<FontAwesomeIcon icon={faCopy} size='sm' color='#2ba32b' />}
              onClick={() => {
                copyToClipBoard(gift.quantity)
                copiedGift(record, index, 'quantity')
              }}
            ></Button>
          </Tooltip>
          {gift.quantity}
        </div>
      ),
    },
    {
      title: '商品名',
      dataIndex: 'name',
      width: '40%',
      render: (_0, gift, index) => (
        <div style={gift.name_copied ? { color: '#26a7de' } : null}>
          <Tooltip title={`已複製「${gift.name}」`} trigger='click'>
            <Button
              className='mr-1'
              type='link'
              size='small'
              icon={<FontAwesomeIcon icon={faCopy} size='sm' color='#2ba32b' />}
              onClick={() => {
                copiedGift(record, index, 'name')
                copyToClipBoard(gift.name)
              }}
            ></Button>
          </Tooltip>
          {gift.name}
        </div>
      ),
    },
  ]

  return (
    <div className='mb-4 col recordCol'>
      <PickingTextModal
        visible={pickTextModalOpen}
        title='揀貨文字'
        onCancel={() => setPickTextModalOpen(false)}
        onOk={() => setPickTextModalOpen(false)}
        centered
        numberOfOrder={numberOfOrder}
        id={record._id}
      />
      {gifts ? (
        <Modal
          visible={giftModalState}
          onCancel={() => onCancelGiftModal()}
          title='贈品銷貨列表：'
          centered
          footer={[
            <Button key='back' onClick={() => onCancelGiftModal()}>
              取消
            </Button>,
            <Button key='clearOne' type='primary' onClick={() => onGiftDone()}>
              銷貨完成
            </Button>,
          ]}
        >
          <Table
            className='auto-scroll-y-complete w100'
            columns={columns}
            dataSource={gifts}
            pagination={false}
            scroll={{ y: 'calc(100% - 39px)' }}
            size='small'
            bordered
            // loading={loading}
          />
        </Modal>
      ) : (
        <Modal
          visible={giftModalState}
          onCancel={() => onCancelGiftModal()}
          onOk={() => onCancelGiftModal()}
          title='沒有贈品資料喔'
          footer={[
            <Button key='back' onClick={() => onCancelGiftModal()}>
              了解
            </Button>,
          ]}
        >
          這張撿貨單沒有贈送品項的相關資料，可能是因為是用舊版的檔案跑的，或是因為程式出了問題喔。
        </Modal>
      )}
      <Modal
        visible={visible}
        centered
        title='刪除揀貨單紀錄'
        onCancel={() => onCancel()}
        footer={[
          <Button key='back' onClick={() => onCancel()}>
            取消
          </Button>,
          <Button key='clearOne' type='primary' onClick={() => onClearOne()}>
            刪除這個
          </Button>,
          <Tooltip
            className='ml-2'
            title={recordIndex <= 100 ? '100筆以上才可以點此按鈕' : ''}
          >
            <Button
              key='clearAll'
              danger
              type='primary'
              onClick={() => onClearMany()}
              disabled={recordIndex <= 100}
            >
              刪除這個以及更早的
            </Button>
          </Tooltip>,
        ]}
      >
        將刪除以下這個撿貨單紀錄：
        <div className='my-3 ml-3'>
          <div>揀貨單建立時間：</div>
          <div>
            <Moment format='YYYY/MM/DD HH:mm:ss'>{record.date}</Moment>
          </div>
          <div className='mt-1'>記錄中的第一單：</div>
          <div className='mx-2'>
            <div>{record.firstOrder}.</div>
            <div>收件姓名: {record.firstBuyer}</div>
            <div>訂單編號: {record.firstOrderNo}</div>
            <div>買家帳號: {record.firstBuyerAccount}</div>
          </div>
        </div>
        請問只要刪除這個紀錄，還是要刪除這個以及所有比這個更早的紀錄（這個操作無法復原喔）。
      </Modal>
      <div className='card px-md-3 cardRecord'>
        <div className='card-body'>
          <h5 className='card-title'>
            <div className='d-flex flex-row justify-content-between'>
              <div>
                {recordIndex > 100 ? '(100筆以上)' : null}
                揀貨單建立時間：
              </div>

              <button className='icon-button' onClick={() => onOpen()}>
                <FontAwesomeIcon icon={faTrashAlt} color='red' />
              </button>
            </div>
            <Moment format='YYYY/MM/DD HH:mm:ss'>{record.date}</Moment>
          </h5>
          <h6 className='card-subtitle text-muted'>
            訂單數：{record.numberOfOrder}
          </h6>
          <h6 className='mb-2  text-muted'>
            {gifts ? null : '無贈品銷貨資料'}
            {gifts && giftsDone ? '贈品銷貨完成' : null}
            {gifts && !giftsDone ? '贈品尚未銷貨' : null}
          </h6>
          <h6 className='mt-2'>記錄中的第一單：</h6>
          <div className='mx-1'>
            <h6>{record.firstOrder}.</h6>
            <h6>收件姓名: {record.firstBuyer}</h6>
            <h6>訂單編號: {record.firstOrderNo}</h6>
            <h6>買家帳號: {record.firstBuyerAccount}</h6>
          </div>

          <div className='d-flex mt-3 justify-content-around'>
            <Link
              type='button'
              className='btn btn-info'
              to={{
                pathname: `/picking_details/${record._id}`,
                record_id: record._id,
              }}
            >
              揀貨
            </Link>
            <button
              type='button'
              className='btn btn-warning'
              onClick={() => setPickTextModalOpen(true)}
            >
              文字
            </button>
            <button
              type='button'
              className='btn btn-success'
              onClick={() => onOpenGiftModal()}
            >
              贈品
            </button>
            {record?.labelPdfStatus != null &&
            record.labelPdfStatus !== 'unknown' ? (
              <button
                type='primary'
                className='btn btn-info'
                onClick={() =>
                  downloadLabelPdf(record._id, {
                    onUnknown: () => {
                      message.error('生成pdf的過程失敗，通知一下智圓')
                    },
                    onStarted: () => {
                      message.info('生成pdf的過程還在進行，可能要再等一下喔')
                    },
                    onFailed: () => {
                      message.error('生成pdf的過程失敗，通知一下智圓')
                    },
                    onWillProcess: () => {
                      message.info('生成pdf的過程還在進行，可能要再等一下喔')
                    },
                  })
                }
              >
                標示
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

OrderRecordItem.propTypes = {
  deleteRecord: PropTypes.func.isRequired,
  copiedGift: PropTypes.func.isRequired,
  doneGift: PropTypes.func.isRequired,
}

export default connect(null, { deleteRecord, copiedGift, doneGift })(
  OrderRecordItem
)
