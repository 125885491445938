import {
  GET_RECORDS,
  SET_RECORDS,
  PICKING_ERROR,
  GET_PICKING_DETAILS,
  CLEAR_PICKING_DETAILS,
  SWITCH_ORDER,
  SWITCH_ENTRY,
  SET_ENTRY_CONDITION,
  SET_ENTRY_QUTY,
  MODIFY_NOT_GOOD,
  CHANGE_NOT_GOOD,
  DELETE_ONE_NOT_GOOD,
  SET_SELECT_NOT_GOOD,
  PUSH_DING_NOT_GOOD,
  GET_ALL_NOT_GOOD,
  SET_NOT_FILTER_SOL,
  SET_NOT_DATE_RANGE,
  SEARCH_NOT_GOOD,
  COPIED_NOT_GOOD,
  SELECT_NOT_GOOD,
  COMPLETE_NOT_GOOD,
  DELETE_RECORD_ENTRY_MANY,
  DELETE_RECORD_ENTRY_ONE,
  COPIED_GIFT,
  GIFT_DONE,
} from '../actions/types'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
  records: [],
  record: null,
  entries: [],
  loading: true,
  details_loading: true,
  entry_on_pick: null,
  not_goods: [],
  dingGoods: [],
  selectNotGood: {
    filText: '',
    text: '',
    condition: 'sha',
    quty: 1,
    A1ItemDetailID: '',
    A1ItemName: '',
    A1orNot: false,
    type: 'unset',
  },
  error: {},
  total_not_good: {
    loading: true,
    data: [],
    dateRange: null,
    Solved: false,
    selections: [],
  },
}

export default function picking(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LOCATION_CHANGE:
      if (!payload.location.pathname.includes('picking')) {
        return {
          ...state,
          record: null,
          entries: [],
          details_loading: true,
          entry_on_pick: null,
          selectNotGood: {
            filText: '',
            text: '',
            condition: 'ban',
            quty: 1,
            A1ItemDetailID: '',
            A1ItemName: '',
            A1orNot: false,
          },
        }
      } else {
        return state
      }
    case GET_RECORDS:
      return {
        ...state,
        loading: false,
        records: payload.map((record) => {
          if (record.hasOwnProperty('gifts')) {
            if (record.gifts.hasOwnProperty('name')) {
              let ob = record.gifts
              return {
                ...record,
                gifts: [
                  {
                    ...ob,
                    key: ob.name,
                    dingName_copied: false,
                    quantity_copied: false,
                    name_copied: false,
                  },
                ],
              }
            } else if (Array.isArray(record.gifts)) {
              return {
                ...record,
                gifts: record.gifts.map((ob) => ({
                  ...ob,
                  key: ob.name,
                  dingName_copied: false,
                  quantity_copied: false,
                  name_copied: false,
                })),
              }
            } else {
              return {
                ...record,
              }
            }
          } else {
            return record
          }
        }),
      }

    case SET_RECORDS:
      const { orderRecord } = payload
      return {
        ...state,
        records: state.records.map(record => record._id === orderRecord._id ? orderRecord : record)
      }
    case PICKING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case GET_PICKING_DETAILS:
      // payload
      // {
      //   record_to_pick: record,
      //   entries: orderEntries,
      //   notgoods,
      //   modify_picking_record,
      //   dingGoods,
      // }
      return {
        ...state,
        record: payload.record_to_pick,
        details_loading: false,
        entries: payload.entries,
        entry_on_pick: payload.entries[0],
        not_goods: payload.notgoods,
        dingGoods: payload.dingGoods,
      }
    case CLEAR_PICKING_DETAILS:
      return {
        ...state,
        record: null,
        details_loading: true,
        entries: [],
        entry_on_pick: null,
      }
    case SWITCH_ORDER:
      if (!state.entries) {
        return {
          ...state,
        }
      }
      let entries_of_order = state.entries
      let onPick = entries_of_order.find((ob) => ob.No === payload)
      return {
        ...state,
        entry_on_pick: onPick,
      }
    case SWITCH_ENTRY:
      if (!state.entries) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        entry_on_pick: payload,
      }
    case SET_ENTRY_CONDITION:
      switch (payload.type) {
        case 'single':
        case 'unset':
          return {
            ...state,
            entry_on_pick: {
              ...state.entry_on_pick,
              condition: payload.condition,
            },
          }
        case 'set':
          return {
            ...state,
            entry_on_pick: {
              ...state.entry_on_pick,
              CombinInfo: state.entry_on_pick.CombinInfo.map((ob) => {
                if (ob._id === payload._id) {
                  return payload
                } else {
                  return ob
                }
              }),
            },
          }
        default:
          return {
            ...state,
          }
      }
    case SET_ENTRY_QUTY:
      switch (payload.type) {
        case 'single':
        case 'unset':
          return {
            ...state,
            entry_on_pick: {
              ...state.entry_on_pick,
              quty: payload.quty,
            },
          }
        case 'set':
          return {
            ...state,
            entry_on_pick: {
              ...state.entry_on_pick,
              CombinInfo: state.entry_on_pick.CombinInfo.map((ob) => {
                if (ob._id === payload._id) {
                  return payload
                } else {
                  return ob
                }
              }),
            },
          }
        default:
          return {
            ...state,
          }
      }
    case MODIFY_NOT_GOOD:
      let exist = state.not_goods.find((ob) => ob._id === payload._id)
      if (exist) {
        // modify
        return {
          ...state,
          not_goods: state.not_goods.map((ob) => {
            if (ob._id === payload._id) {
              return payload
            } else {
              return ob
            }
          }),
        }
      } else {
        return {
          ...state,
          not_goods: [...state.not_goods, payload],
        }
      }
    case DELETE_ONE_NOT_GOOD:
      return {
        ...state,
        not_goods: state.not_goods.filter((ob) => ob._id !== payload),
      }
    case CHANGE_NOT_GOOD:
      return {
        ...state,
        not_goods: state.not_goods.map((ob) => {
          if (ob._id === payload._id) {
            return payload
          } else {
            return ob
          }
        }),
      }
    case SET_SELECT_NOT_GOOD:
      if (payload.text !== state.selectNotGood.text) {
        return {
          ...state,
          selectNotGood: {
            ...payload,
            A1orNot: false,
            A1ItemDetailID: '',
            type: 'unset',
            A1ItemName: payload.text,
            filText: payload.text,
          },
        }
      } else {
        return {
          ...state,
          selectNotGood: payload,
        }
      }
    case PUSH_DING_NOT_GOOD:
      return {
        ...state,
        selectNotGood: {
          ...state.selectNotGood,
          A1ItemDetailID: payload.A1ItemDetailID,
          A1ItemName: payload.A1ItemName,
          text: payload.A1ItemName,
          type: 'single',
          A1orNot: true,
          filText: '',
        },
      }
    case GET_ALL_NOT_GOOD:
      if (!payload) {
        return {
          ...state,
        }
      }
      // filter Solved
      let uniqueId = [
        ...new Set(
          payload
            .filter((ob) => ob.Solved === false)
            .map((ob) => ob.IdentifyString)
        ),
      ]
      uniqueId = uniqueId.map((ob) => {
        let sameSetOfNotGood = payload.filter(
          (ob1) => ob1.IdentifyString === ob
        )
        let sumQuty = sameSetOfNotGood
          .map((ob2) => ob2.quty)
          .reduce((prev, next) => prev + next)
        return {
          IdentifyString: ob,
          quty: sumQuty,
          A1ItemDetailID: sameSetOfNotGood[0].A1ItemDetailID,
          key: sameSetOfNotGood[0]._id,
          A1ItemName: sameSetOfNotGood[0].A1ItemName,
          A1ItemDetailID_copied: false,
          A1ItemName_copied: false,
          quty_copied: false,
          Solved: sameSetOfNotGood[0].Solved,
          members: sameSetOfNotGood,
        }
      })
      return {
        ...state,
        total_not_good: {
          ...state.total_not_good,
          data: uniqueId,
          loading: false,
        },
      }
    case SET_NOT_DATE_RANGE:
      return {
        ...state,
        total_not_good: {
          ...state.total_not_good,
          dateRange: payload,
        },
      }
    case SET_NOT_FILTER_SOL:
      return {
        ...state,
        total_not_good: {
          ...state.total_not_good,
          Solved: payload,
        },
      }
    case SEARCH_NOT_GOOD:
      if (!payload) {
        return {
          ...state,
        }
      }
      // filter Solved
      let uniqueID = [...new Set(payload.map((ob) => ob.IdentifyString))]
      let ansatz = []
      uniqueID.forEach((ob) => {
        ansatz.push({ IdentifyString: ob, Solved: true, members: [], quty: 0 })
        ansatz.push({ IdentifyString: ob, Solved: false, members: [], quty: 0 })
      })
      payload.forEach((ob) => {
        for (let i = 0; i < ansatz.length; i++) {
          if (
            ob.IdentifyString === ansatz[i].IdentifyString &&
            ob.Solved === ansatz[i].Solved
          ) {
            ansatz[i].members.push(ob)
            ansatz[i].quty = ansatz[i].quty + ob.quty
            break
          }
        }
      })

      ansatz = ansatz
        .filter((ob) => ob.members.length !== 0)
        .map((ob) => {
          return {
            ...ob,
            A1ItemDetailID: ob.members[0].A1ItemDetailID,
            key: ob.members[0]._id,
            A1ItemName: ob.members[0].A1ItemName,
            A1ItemDetailID_copied: false,
            A1ItemName_copied: false,
            quty_copied: false,
          }
        })
      return {
        ...state,
        total_not_good: {
          ...state.total_not_good,
          data: ansatz,
        },
      }
    case COPIED_NOT_GOOD:
      let { record, field } = payload
      switch (field) {
        case 'quty':
          return {
            ...state,
            total_not_good: {
              ...state.total_not_good,
              data: state.total_not_good.data.map((ob) => {
                if (ob.key === record.key) {
                  return {
                    ...ob,
                    quty_copied: true,
                  }
                }
                return ob
              }),
            },
          }
        case 'A1ItemDetailID':
          return {
            ...state,
            total_not_good: {
              ...state.total_not_good,
              data: state.total_not_good.data.map((ob) => {
                if (ob.key === record.key) {
                  return {
                    ...ob,
                    A1ItemDetailID_copied: true,
                  }
                }
                return ob
              }),
            },
          }
        case 'A1ItemName':
          return {
            ...state,
            total_not_good: {
              ...state.total_not_good,
              data: state.total_not_good.data.map((ob) => {
                if (ob.key === record.key) {
                  return {
                    ...ob,
                    A1ItemName_copied: true,
                  }
                }
                return ob
              }),
            },
          }
        default:
          return {
            ...state,
          }
      }
    case SELECT_NOT_GOOD:
      return {
        ...state,
        total_not_good: {
          ...state.total_not_good,
          selections: payload,
        },
      }
    case COMPLETE_NOT_GOOD:
      let newData = [...state.total_not_good.data]
      for (let i = 0; i < payload.length; i++) {
        for (let j = 0; j < newData.length; j++) {
          if (payload[i].key === newData[j].key) {
            newData[j].Solved = true
            newData[j].members = newData[j].members.map((ob) => ({
              ...ob,
              Solved: true,
            }))
            break
          }
        }
      }

      return {
        ...state,
        total_not_good: {
          ...state.total_not_good,
          data: newData,
        },
      }
    case DELETE_RECORD_ENTRY_ONE:
      return {
        ...state,
        records: state.records.filter((ob) => ob._id !== payload),
      }
    case DELETE_RECORD_ENTRY_MANY:
      return {
        ...state,
        records: state.records.filter((ob) => !payload.includes(ob._id)),
      }
    case COPIED_GIFT:
      let { rec, index, fie } = payload
      let ff = ''
      switch (fie) {
        case 'quantity':
          ff = 'quantity_copied'
          break
        case 'dingName':
          ff = 'dingName_copied'
          break
        case 'name':
          ff = 'name_copied'
          break
        default:
          ff = ''
      }
      return {
        ...state,
        records: state.records.map((ob) => {
          if (ob._id === rec._id) {
            return {
              ...ob,
              gifts: ob.gifts.map((gift, ind) => {
                if (ind === index) {
                  return {
                    ...gift,
                    [ff]: true,
                  }
                } else {
                  return gift
                }
              }),
            }
          } else {
            return ob
          }
        }),
      }
    case GIFT_DONE:
      return {
        ...state,
        records: state.records.map((ob) => {
          if (ob._id === payload._id) {
            return {
              ...ob,
              giftsDone: true,
            }
          } else {
            return ob
          }
        }),
      }

    default:
      return state
  }
}
