import React, { useState } from 'react'
import { Row, Col } from 'antd'
import GraphPanel from './GraphPanel'
import distinctColors from 'distinct-colors'
import moment from 'moment'
import LinePlot from './LinePlot'

const StatisticPanel = ({ isMulti, title }) => {
  // state setting
  let initialIndex

  let indexList = [
    {
      label: 'rank',
      name: '最熱銷排名',
    },
    {
      label: 'month_sales',
      name: '月銷量',
    },
    {
      label: 'conversion_rate',
      name: '轉化率',
    },
    {
      label: 'total_sales',
      name: '總銷量',
    },
    {
      label: 'liked_count',
      name: '喜歡數',
    },
    {
      label: 'cmt_count',
      name: '評價數量',
    },
    {
      label: 'view_count',
      name: '瀏覽數',
    },
    {
      label: 'item_rating',
      name: '平均評價星數',
    },
  ]

  let timeOptionList = [
    {
      label: 'monthsInYear',
      name: '按年份',
    },
    {
      label: 'daysInMonth',
      name: '按月',
    },
    {
      label: 'daysInWeek',
      name: '按週',
    },
    {
      label: 'daysInDays',
      name: '選擇兩天之間',
    },
    {
      label: 'monthsInMonths',
      name: '選擇兩個月之間',
    },
    {
      label: 'yearsInYears',
      name: '選擇兩年之間',
    },
  ]
  // initiate time option
  let initialTimeOption = 'daysInMonth'
  if (isMulti) {
    // initiate index
    initialIndex = 'month_sales'
    // initiate time option
  } else {
    // initiate index
    initialIndex = ['month_sales']

    timeOptionList = timeOptionList.concat([
      {
        label: 'monthsInTwoYears',
        name: '比較兩年',
      },
      {
        label: 'monthsInYears',
        name: '歷年同月',
      },
    ])
  }

  const [index, setIndex] = useState(initialIndex)
  const [timeOption, setTimeOption] = useState(initialTimeOption)
  const [data, setData] = useState([])
  const [timeSelect, setTimeSelect] = useState({})

  // do the graph calculation

  // transform and get data time Limit
  let syncId = isMulti
  let maxTime, minTime, dataToDraw
  let hasData = data.length !== 0
  if (hasData) {
    let times = []
    dataToDraw = data.map((itemData) => {
      let parsedItemData = { ...itemData }
      indexList.forEach((indexItem) => {
        let index = indexItem.label
        parsedItemData[index] = parsedItemData[index].map((ob) => {
          let time = moment(ob.createdAt)
          times.push(time)
          return ob
        })
      })
      return parsedItemData
    })
    maxTime = moment.max(times).clone().endOf('day')
    minTime = moment.min(times).clone().startOf('day')
  }

  let timeRange = timeSelect[timeOption]
  let timeList = []
  let startEndFunctionCreator = (s, e) => (date) =>
    s.isSameOrBefore(date) && e.isAfter(date)

  let level, start, end
  let graphUIs = []
  if (hasData) {
    // first prepare time range: list of moment
    let timeIndex, timeEnd
    switch (timeOption) {
      case 'monthsInYear':
        level = 'month'
        timeIndex = !!timeRange ? moment(timeRange) : minTime
        timeIndex = timeIndex.clone().startOf('year')
        timeEnd = timeIndex.clone().endOf('year')
        while (timeEnd > timeIndex) {
          timeList.push({
            moment: timeIndex.clone().toString(),
            judge: startEndFunctionCreator(
              timeIndex.clone(),
              timeIndex.clone().endOf('month')
            ),
          })
          timeIndex.add(1, 'month').startOf('month')
        }
        break
      case 'daysInMonth':
        level = 'day'
        timeIndex = !!timeRange ? moment(timeRange) : minTime
        timeIndex = timeIndex.clone().startOf('month')
        timeEnd = timeIndex.clone().endOf('month')
        while (timeEnd > timeIndex) {
          timeList.push({
            moment: timeIndex.clone().toString(),
            judge: startEndFunctionCreator(
              timeIndex.clone(),
              timeIndex.clone().endOf('day')
            ),
          })
          timeIndex.add(1, 'day').startOf('day')
        }
        break
      case 'daysInWeek':
        level = 'week'
        timeIndex = !!timeRange ? moment(timeRange) : minTime
        timeIndex = timeIndex.clone().startOf('week')
        timeEnd = timeIndex.clone().endOf('week')
        while (timeEnd > timeIndex) {
          timeList.push({
            moment: timeIndex.clone().toString(),
            judge: startEndFunctionCreator(
              timeIndex.clone(),
              timeIndex.clone().endOf('day')
            ),
          })
          timeIndex.add(1, 'day').startOf('day')
        }
        break
      case 'daysInDays':
        level = 'day'
        start = timeRange ? timeRange[0] : minTime
        end = timeRange ? timeRange[1] : maxTime
        timeIndex = start.clone().startOf('day')
        timeEnd = end.clone().endOf('day')
        while (timeEnd > timeIndex) {
          timeList.push({
            moment: timeIndex.clone().toString(),
            judge: startEndFunctionCreator(
              timeIndex.clone(),
              timeIndex.clone().endOf('day')
            ),
          })
          timeIndex.add(1, 'day').startOf('day')
        }
        break
      case 'monthsInMonths':
        level = 'month'
        start = timeRange ? timeRange[0] : minTime
        end = timeRange ? timeRange[1] : maxTime
        timeIndex = start.clone().startOf('month')
        timeEnd = end.clone().endOf('month')
        while (timeEnd > timeIndex) {
          timeList.push({
            moment: timeIndex.clone().toString(),
            judge: startEndFunctionCreator(
              timeIndex.clone(),
              timeIndex.clone().endOf('month')
            ),
          })
          timeIndex.add(1, 'month').startOf('month')
        }
        break
      case 'yearsInYears':
        level = 'year'
        start = timeRange ? timeRange[0] : minTime
        end = timeRange ? timeRange[1] : maxTime
        timeIndex = start.clone().startOf('year')
        timeEnd = end.clone().endOf('year')
        while (timeEnd > timeIndex) {
          timeList.push({
            moment: timeIndex.clone().toString(),
            judge: startEndFunctionCreator(
              timeIndex.clone(),
              timeIndex.clone().endOf('year')
            ),
          })
          timeIndex.add(1, 'year').startOf('year')
        }
        break
      case 'monthsInTwoYears':
        level = 'year'
        let months = [...Array(12).keys()]
        timeList = months.map((month) => {
          return {
            moment: moment().month(month).toString(),
            judge: (date) => {
              let dateToJudge = moment(date)
              return dateToJudge.month() === month
            },
          }
        })

        break
      case 'monthsInYears':
        level = 'month'
        timeIndex = minTime.clone().startOf('year')
        timeEnd = maxTime.clone().endOf('year')
        while (timeEnd > timeIndex) {
          timeList.push({
            moment: timeIndex.clone().toString(),
            judge: startEndFunctionCreator(
              timeIndex.clone(),
              timeIndex.clone().endOf('year')
            ),
          })
          timeIndex.add(1, 'year').startOf('year')
        }
        break
      default:
        throw Error('Invalid Time Option')
    }
    let colors
    if (Array.isArray(index)) {
      // render a list of graphs with each one correspond to a index
      dataToDraw = dataToDraw[0]
      if (timeOption === 'monthsInTwoYears') {
        // timeSelection x item
        index.forEach((indexName, indexInd) => {
          let lines = []
          let dataPoints = dataToDraw[indexName]
          let year1 = timeRange[0]
          let year2 = timeRange[1]
          colors = distinctColors({ count: 2 }).map((ob) => ob.toString())
          lines = lines.concat([
            {
              name: `${year1.format('YYYY')}-${dataToDraw.name}`,
              color: colors[0],
              year: year1,
            },
            {
              name: `${year2.format('YYYY')}-${dataToDraw.name}`,
              color: colors[1],
              year: year2,
            },
          ])
          let dataInput = timeList.map((timeListItem) => {
            let point = {
              createdAt: timeListItem.moment,
            }
            lines.forEach((line) => {
              // collect point
              let matchPoints = dataPoints.filter(
                (ob) =>
                  ob.createdAt.year() === line.year.year() &&
                  timeListItem.judge(ob.createdAt)
              )
              // avg
              let validPoints = matchPoints.filter((ob) => !!ob.data)
              let avg =
                validPoints.reduce((pre, cur) => pre + cur.data, 0) /
                validPoints.length
              point[line.name] = avg
            })
            return point
          })
          graphUIs.push(
            <LinePlot
              syncId={syncId}
              lines={lines}
              dataInput={dataInput}
              key={indexInd}
              title={indexList.find((ob) => ob.label === indexName).name}
              timeOption={timeOption}
            />
          )
        })
      } else {
        // item
        index.forEach((indexName, indexInd) => {
          let dataPoints = dataToDraw[indexName]
          colors = distinctColors({ count: 1 }).map((ob) => ob.toString())
          let lines = [
            {
              name: dataToDraw.name,
              color: colors[0],
            },
          ]
          let dataInput = timeList.map((timeListItem) => {
            let point = {
              createdAt: timeListItem.moment,
            }
            // collect point
            let matchPoints = dataPoints.filter((ob) =>
              timeListItem.judge(ob.createdAt)
            )
            // avg
            let validPoints = matchPoints.filter((ob) => !!ob.data)
            let avg =
              validPoints.reduce((pre, cur) => pre + cur.data, 0) /
              validPoints.length
            // into field
            point[dataToDraw.name] = avg
            return point
          })
          graphUIs.push(
            <LinePlot
              title={indexList.find((ob) => ob.label === indexName).name}
              syncId={syncId}
              lines={lines}
              dataInput={dataInput}
              key={indexInd}
              timeOption={timeOption}
            />
          )
        })
      }
    } else {
      // render a single graph with the specified index
      colors = distinctColors({ count: dataToDraw.length }).map((ob) =>
        ob.toString()
      )
      let lines = dataToDraw.map((ob, ind) => ({
        name: ob.name,
        color: colors[ind],
        ind,
      }))
      let dataInput = timeList.map((timeListItem) => {
        let point = {
          createdAt: timeListItem.moment,
        }
        lines.forEach((line) => {
          let dataPoints = dataToDraw[line.ind][index]
          // collect point
          let matchPoints = dataPoints.filter((ob) =>
            timeListItem.judge(ob.createdAt)
          )
          // avg
          let validPoints = matchPoints.filter((ob) => !!ob.data)
          let avg =
            validPoints.reduce((pre, cur) => pre + cur.data, 0) /
            validPoints.length
          // into field
          point[line.name] = avg
        })
        return point
      })
      graphUIs.push(
        <LinePlot
          syncId={syncId}
          lines={lines}
          dataInput={dataInput}
          key={1}
          timeOption={timeOption}
        />
      )
    }
  }
  let disabledDate = (current) => {
    if (hasData) {
      return (
        current &&
        (current.clone().startOf(level).isBefore(minTime) ||
          current.clone().endOf(level).isAfter(maxTime))
      )
    } else {
      return false
    }
  }

  return (
    <Row>
      <Col span={6}>
        <GraphPanel
          title={title}
          isMulti={isMulti}
          index={index}
          indexList={indexList}
          setIndex={setIndex}
          timeOption={timeOption}
          setTimeOption={setTimeOption}
          setData={setData}
          timeOptionList={timeOptionList}
          timeSelect={timeSelect}
          setTimeSelect={setTimeSelect}
          disabledDate={disabledDate}
          hasData={hasData}
        />
      </Col>
      <Col span={18}>
        {graphUIs.length === 0 ? (
          <LinePlot dataInput={[]} lines={[]} />
        ) : (
          graphUIs
        )}
      </Col>
    </Row>
  )
}

export default StatisticPanel
