import React, { useState, useEffect } from 'react'
// import { Route, Switch } from 'react-router-dom'
// import PickingPage from '../picking/PickingPage'
import { Button, Input, Form, Select } from 'antd'
import Container from 'react-bootstrap/Container'
import { connect } from 'react-redux'
import api from '../../utils/api'

const { Option } = Select

const VocabPage = ({ match }) => {
  const onTest = async () => {
    try {
      const res = await api.get('/api/test/test1')
      console.log(res.data)
    } catch (err) {
      console.log(err)
      console.log(err.response)
    }
  }
  const [text, setText] = useState(
    `學號：12-150
姓名：陳智圓
10：05 安全回報
個人手機：0933024656
目前所在地：家
陪同者：無
陪同者電話：無
無流感症狀：無
體溫 ：36.4
⭕️是否有感冒、身體不適：無
⭕️是否有接觸國外人員：無`
  )
  const [loading, setLoading] = useState(true)
  const [groupId, setGroupId] = useState('')
  const [groups, setGroups] = useState([])
  useEffect(() => {
    try {
      async function getGroups() {
        const { data: groups } = await api.get('/api/line_bot')
        setGroups(groups)
        if (groups.length > 0) {
          setGroupId(groups[0].groupId)
        }
      }
      getGroups()
    } catch (error) {}
    setLoading(false)
    // return () => {
    //   // cleanup
    // }
  }, [])

  const onSentLine = async () => {
    setLoading(true)
    try {
      await api.post('/api/line_bot', {
        text,
        groupId,
      })
    } catch (error) {}
    setLoading(false)
  }

  return (
    <Container className='page m-4 d-flex flex-column'>
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <Form.Item label='傳送群組'>
          <Select
            loading={loading}
            value={groupId}
            onChange={(val) => setGroupId(val)}
          >
            {groups.map((group) => (
              <Option value={group.groupId} key={group.groupId}>
                {group.groupName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='訊息內容'>
          <Input.TextArea
            disabled={loading}
            autoSize
            value={text}
            onChange={(e) => setText(e.target.value)}
          ></Input.TextArea>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 20,
          }}
        >
          <Button type='primary' disabled={loading} onClick={onSentLine}>
            傳出
          </Button>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 20,
          }}
        >
          <Button type='default' onClick={onTest}>
            Test Button
          </Button>
        </Form.Item>
      </Form>
    </Container>
  )
}

export default connect()(VocabPage)
