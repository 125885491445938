import React from 'react'
import { Input, Form, Button } from 'antd'
import { testApi } from '../../../actions/scrape'
import { connect } from 'react-redux'
import ReactJson from 'react-json-view'

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 20,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 2,
    span: 20,
  },
}

const TestApi = ({ testApi, response }) => {
  const onFinish = (values) => {
    testApi(values.url, values.referer)
  }
  return (
    <div className='scroll-page w100 container-xl '>
      <div className='ml-4 mt-3 d-flex flex-column'>
        <h5 className='my-3'>關鍵字</h5>
        <div className='my-3'>
          URL:{' '}
          https://shopee.tw/api/v2/search_items/?by=sales&keyword=玻璃吸管&limit=50&newest=0&order=desc&page_type=search&version=2
        </div>
        <div className='my-3'>
          Referer: https://shopee.tw/search?keyword=玻璃吸管&page=0&sortBy=sales
        </div>
        <h5 className='my-3'>賣場前台</h5>
        <div className='my-3'>
          URL:{' '}
          https://shopee.tw/api/v2/search_items/?by=sales&limit=30&match_id=18173562&newest=0&order=desc&page_type=shop&version=2
        </div>
        <div className='my-3'>Referer: https://shopee.tw/sixstar</div>
        <h5 className='my-3'>居家生活</h5>
        <div className='my-3'>
          URL:{' '}
          https://shopee.tw/api/v2/search_items/?by=sales&fe_categoryids=73&limit=50&newest=0&order=desc&page_type=search&version=2
        </div>
        <div className='my-3'>
          Referer: https://shopee.tw/居家生活-cat.73?page=0&sortBy=sales
        </div>
      </div>
      <Form {...layout} name='Api test' onFinish={onFinish} className='mt-4'>
        <Form.Item
          label='Url'
          name='url'
          rules={[
            {
              required: true,
              message: '請輸入 api 的 url ~',
            },
          ]}
        >
          <Input placeholder='請輸入 api 的 url ~' />
        </Form.Item>

        <Form.Item
          label='Referer'
          name='referer'
          rules={[
            {
              required: true,
              message: '請輸入 api 的 referer ~',
            },
          ]}
        >
          <Input placeholder='請輸入 api 的 referer ~' />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit'>
            Request
          </Button>
        </Form.Item>
      </Form>

      <ReactJson
        collapseStringsAfterLength={100}
        name={false}
        collapsed={1}
        src={response}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    response: state.store_data.test.response,
  }
}

export default connect(mapStateToProps, { testApi })(TestApi)
