import React from 'react'
import { Route, Switch, NavLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import Ding from './Ding'
import OrderProcessTab from './OrderProcess/OrderProcessTab.jsx'
import OrderGenerateTab from './OrderGenerate/OrderGenerateTab.jsx'
import ShopeeCorrTab from './ShpeeCorr/ShopeeCorrTab.jsx'
import GiftEventTab from './GiftEvent/GiftEventTab.jsx'
import GiftEventItemSearch from './GiftEventItemSearch/GiftEventItemSearch.jsx'
import GiftEventToggle from './GiftEventToggle/GiftEventToggle.jsx'
import './OrderProcessingPage.css'

const OrderProcessingPage = ({ match }) => {
  let location = useLocation()
  let tabRegs = new RegExp(`${match.path}\\/(?<path>\\w+)`)
  let path = tabRegs.exec(location.pathname).groups.path
  return (
    <div className='d-flex flex-column order-process-page'>
      <Menu selectedKeys={[path]} mode='horizontal' className='inner-menu'>
        <Menu.Item key='ding'>
          <NavLink
            className='nav-link'
            to={`${match.path}/ding`}
            activeClassName='active'
          >
            鼎新資料
          </NavLink>
        </Menu.Item>
        <Menu.Item key='shopee_corr'>
          <NavLink
            className='nav-link'
            to={`${match.path}/shopee_corr`}
            activeClassName='active'
          >
            蝦皮對應
          </NavLink>
        </Menu.Item>

        <Menu.Item key='order_generate'>
          <NavLink
            className='nav-link'
            to={`${match.path}/order_generate`}
            activeClassName='active'
          >
            出貨單生成
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key='order_process'>
          <NavLink
            className='nav-link'
            to={`${match.path}/order_process`}
            activeClassName='active'
          >
            出貨單處理
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key='gift_event'>
          <NavLink
            className='nav-link'
            to={`${match.path}/gift_event`}
            activeClassName='active'
          >
            活動適用
          </NavLink>
        </Menu.Item>
        <Menu.Item key='gift_event_item_search'>
          <NavLink
            className='nav-link'
            to={`${match.path}/gift_event_item_search`}
            activeClassName='active'
          >
            活動查詢
          </NavLink>
        </Menu.Item>
        <Menu.Item key='gift_event_item_toggle'>
          <NavLink
            className='nav-link'
            to={`${match.path}/gift_event_item_toggle`}
            activeClassName='active'
          >
            活動開關
          </NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path={`${match.path}/ding`} component={Ding} />
        <Route path={`${match.path}/shopee_corr`} component={ShopeeCorrTab} />
        <Route
          path={`${match.path}/order_process`}
          component={OrderProcessTab}
        />
        <Route
          path={`${match.path}/order_generate`}
          component={OrderGenerateTab}
        />
        <Route path={`${match.path}/gift_event`} component={GiftEventTab} />
        <Route
          path={`${match.path}/gift_event_item_search`}
          component={GiftEventItemSearch}
        />
        <Route
          path={`${match.path}/gift_event_item_toggle`}
          component={GiftEventToggle}
        />
      </Switch>
    </div>
  )
}

export default OrderProcessingPage
