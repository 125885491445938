import React from 'react'
import { connect } from 'react-redux'
import { Input } from 'antd'
import { searchTextIndividualItems } from '../../../../../actions/dingAction'

const { Search } = Input

const ItemSelectionSearch = ({ itemLoading, searchTextIndividualItems }) => {
  return (
    <Search
      placeholder='搜尋鼎新商品'
      allowClear
      disabled={itemLoading}
      onChange={(e) => {
        let text = e.target.value
        setTimeout(() => {
          searchTextIndividualItems(text)
        }, 0.01)
      }}
      onSearch={(text) => {
        searchTextIndividualItems(text)
      }}
      className='my-3'
    />
  )
}
const mapStateToProps = (state) => ({
  itemLoading: state.ding.dingItems.loading,
})

export default connect(mapStateToProps, {
  searchTextIndividualItems,
})(ItemSelectionSearch)
