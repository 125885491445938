import React, { useEffect } from 'react';
import {
  clearPickingDetails,
  getPickingDetails,
  switchOrder,
} from '../../actions/picking';

import AddNot from './AddNot';
import { Container } from 'react-bootstrap';
import DataTable from './DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemNotGoodPanel from './ItemNotGoodPanel';
import { Link } from 'react-router-dom';
import NotGoodTable from './NotGoodTable';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const OrderRecord = ({
  history,
  match,
  getPickingDetails,
  clearPickingDetails,
  switchOrder,
  orders,
  loading,
}) => {
  let { Id } = match.params;
  useEffect(() => {
    const result = getPickingDetails(Id);
    if (!result) {
      history.push('/picking');
    }
    return () => clearPickingDetails();
  }, [Id, getPickingDetails, history, clearPickingDetails]);

  const switch_order = (No) => {
    switchOrder(No);
  };

  return loading ? (
    <Container className='page' fluid='xl'>
      <Container fluid='md' className='justify-content-center my-4'>
        <Spinner animation='border' variant='primary' />
      </Container>
    </Container>
  ) : (
    <Container className='page' fluid='xl'>
      <div className='row picking-page-container'>
        <div className='col-md-12 no_extend d-flex flex-column p-0 number-list'>
          <Link
            className='ml-3 ml-md-4 mb-1 mt-1 d-flex pl-1'
            to='/picking/record'
          >
            <FontAwesomeIcon className='pt-1' size='lg' icon={faArrowLeft} />
          </Link>
          <div className='flex-grow-1 d-flex flex-column over-flow pl-lg-2 pl-xl-3'>
            <div className='record-button-container flex-wrap pl-md-1'>
              {orders.map((order) => (
                <button
                  key={order.No}
                  type='button'
                  className={order.className}
                  onClick={(e) => switch_order(order.No)}
                >
                  {order.Sequence}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className='col-md-52 no_extend d-flex flex-column px-1 stretch-col'>
          <div className='innergrid no_extend'>
            <DataTable />
          </div>
          <div className='innergrid no_extend'>
            <AddNot />
          </div>
        </div>
        <div className='col-md-72 no_extend d-flex flex-column p-0 stretch-col'>
          <div className='innergrid no_extend'>
            <ItemNotGoodPanel />
          </div>
          <div className='innergrid no_extend'>
            <NotGoodTable />
          </div>
        </div>
      </div>
    </Container>
  );
};
OrderRecord.propTypes = {
  getPickingDetails: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  orders: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  let entries = state.picking.entries;
  let onPick = state.picking.entry_on_pick;
  let orders = [...new Set(entries.map((ob) => ob.No))];
  orders = orders.map((ob) => {
    let className = 'btn btn-outline-info m-1 btn_pick_dan';
    if (ob === onPick.No) {
      className = 'btn btn-outline-info m-1 btn_pick_dan active';
    }
    return {
      No: ob,
      Sequence: entries.find((en) => en.No === ob).SortingNum,
      items: entries.find((en) => en.No === ob),
      className,
    };
  });
  return {
    orders,
    loading: state.picking.details_loading,
  };
};

export default connect(mapStateToProps, {
  getPickingDetails,
  switchOrder,
  clearPickingDetails,
})(OrderRecord);
