import axios from 'axios'

let config = {}
let URL = ''
let timeout = 99999999

if (process.env.NODE_ENV === 'production') {
  URL = process.env.REACT_APP_URL
} else {
  URL = 'http://localhost:3000'
}
config = { baseURL: URL, timeout }

class api {
  static source() {
    return axios.CancelToken.source()
  }
  static async get(url, cof) {
    return await axios.get(url, { ...cof, ...config })
  }
  static async post(url, data, cof) {
    return await axios.post(url, data, { ...cof, ...config })
  }
  static async delete(url, data, headers) {
    return await axios.delete(url, {
      ...config,
      data,
      headers: { 'content-type': 'application/json', ...headers },
    })
  }
  static async put(url, data, cof) {
    return await axios.put(url, data, { ...cof, ...config })
  }
  static async patch(url, data, cof) {
    return await axios.patch(url, data, { ...cof, ...config })
  }

  static async uploadAndDownload(url_to_go, body, cof) {
    const response = await axios({
      url: url_to_go,
      method: 'POST',
      responseType: 'blob',
      baseURL: URL,
      data: body,
      ...cof,
      ...config,
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    let fileName = decodeURI(response.headers.filename)
    link.setAttribute('download', fileName) //or any other extension
    document.body.appendChild(link)
    link.click()
  }

  static async download(url_to_download, cof) {
    const response = await axios({
      url: url_to_download,
      method: 'GET',
      responseType: 'blob',
      baseURL: URL,
      ...cof,
      ...config,
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    let fileName = decodeURI(response.headers.filename)
    link.setAttribute('download', fileName) //or any other extension
    document.body.appendChild(link)
    link.click()
  }

  static async downloadPost(url_to_download, body, cof) {
    const response = await axios({
      url: url_to_download,
      method: 'POST',
      responseType: 'blob',
      baseURL: URL,
      ...cof,
      data: body,
      ...config,
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    let fileName = decodeURI(response.headers.filename)
    link.setAttribute('download', fileName) //or any other extension
    document.body.appendChild(link)
    link.click()
  }
  static async downloadJson(jsonToDownload, fileName) {
    let dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(jsonToDownload))
    const link = document.createElement('a')
    link.setAttribute('href', dataStr)
    link.setAttribute('download', fileName)
    link.click()
  }
  static genURL(url) {
    return `${URL}/${url}`
  }
  static url = `${URL}`
}

export default api
