import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import './DingItem.style.css'
import { getDingIndividualItems } from '../../../../actions/dingAction'
import ItemSelection from './ItemSelection/ItemSelection.component'
import ItemDisplay from './ItemDisplay/ItemDisplay.component'

const DingItem = ({ getDingIndividualItems }) => {
  useEffect(() => {
    getDingIndividualItems()
  }, [getDingIndividualItems])
  return (
    <>
      <Row className='stretch-tab'>
        <Col span={12} className='no_extend'>
          <ItemSelection />
        </Col>
        <Col span={12} className='no_extend '>
          <ItemDisplay />
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { getDingIndividualItems })(DingItem)
