import './QtyRemind.css'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Table, message, Divider, Upload } from 'antd'
import {
  downloadStockWarning,
  doShippingNameCorrFromDing,
  updateOrderText,
  getShippingCorrFromShopeeExcel,
} from '../../../../actions/orderProcessAction'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { downloadLabelPdf } from '../../../../utils/pdf'

const { TextArea } = Input

const warningColumns = [
  {
    title: '鼎新商品編號',
    dataIndex: 'a1id',
    // width: '160px',
  },
  {
    title: '鼎新商品名稱',
    dataIndex: 'a1name',
    // width: '290px',
  },
  {
    title: '安全庫存',
    dataIndex: 'safeQty',
  },
  {
    title: '鼎新庫存',
    dataIndex: 'qty',
  },
]
const copyToClipBoard = (data) => {
  navigator.clipboard.writeText(data)

  message.success('已複製訂單資料')
}
const getTextFromClipBoard = async () => {
  let text = await navigator.clipboard.readText()
  return text
}

const predictedLabelPdfGenSecs = 150

const QtyRemindStep = ({
  totalText,
  warning,
  downloadStockWarning,
  doShippingNameCorrFromDing,
  getShippingCorrFromShopeeExcel,
  updateOrderText,
  origin,
  shippingNameLoading,
  orderRecordId,
  loading,
}) => {
  const [remainSecsForLabelPdfGen, setRemainSecsForLabelPdfGen] = useState(
    predictedLabelPdfGenSecs
  )
  useEffect(() => {
    let intervalId
    if (remainSecsForLabelPdfGen === predictedLabelPdfGenSecs) {
      intervalId = setInterval(() => {
        setRemainSecsForLabelPdfGen((pre) => {
          if (pre === 1) {
            clearInterval(intervalId)
          }
          return pre - 1
        })
      }, 1000)
    }
    return () => {
      if (remainSecsForLabelPdfGen <= 0) {
        clearInterval(intervalId)
      }
    }
  }, [remainSecsForLabelPdfGen])
  return (
    <div className='p-2'>
      <Divider orientation='left'>鼎新文字資料</Divider>
      <Button
        disabled={loading || shippingNameLoading}
        className='mt-1'
        size='small'
        type='primary'
        onClick={() => {
          copyToClipBoard(totalText)
        }}
      >
        點我複製訂單文字
      </Button>
      <Button
        disabled={loading || shippingNameLoading}
        className='mt-1 ml-2'
        size='small'
        type='primary'
        onClick={async () => {
          let text = await getTextFromClipBoard()
          updateOrderText(text)
          message.success('成功用剪貼簿裡的文字取代進去啦')
        }}
      >
        點我用剪貼簿裡的文字取代
      </Button>
      <Button
        disabled={loading || shippingNameLoading}
        loading={origin === 'ding' && shippingNameLoading}
        className='mt-1 ml-2'
        size='small'
        type='default'
        onClick={() => {
          doShippingNameCorrFromDing(totalText)
        }}
      >
        點我用鼎新訂單資料更新超商單號
      </Button>
      <Upload
        customRequest={(prop) =>
          getShippingCorrFromShopeeExcel({ ...prop, oldText: totalText })
        }
        showUploadList={false}
      >
        <Button
          loading={origin === 'shopee' && shippingNameLoading}
          disabled={loading || shippingNameLoading}
          icon={<UploadOutlined />}
          className='mt-1 ml-2'
          size='small'
          type='default'
        >
          點我上傳蝦皮Excel來更新超商單號
        </Button>
      </Upload>

      <TextArea
        className='mt-3'
        disabled={loading || shippingNameLoading}
        value={totalText}
        onChange={(e) => {
          updateOrderText(e.target.value)
        }}
      />
      <Divider orientation='left' className='mt-5'>
        商品標示資料
      </Divider>
      <Button
        disabled={
          loading || shippingNameLoading || remainSecsForLabelPdfGen > 0
        }
        type='primary'
        onClick={() =>
          downloadLabelPdf(orderRecordId, {
            onUnknown: () => {
              message.error('生成pdf的過程失敗，通知一下智圓')
            },
            onStarted: () => {
              message.info('生成pdf的過程還在進行，可能要再等一下喔')
              setRemainSecsForLabelPdfGen(predictedLabelPdfGenSecs)
            },
            onFailed: () => {
              message.error('生成pdf的過程失敗，通知一下智圓')
            },
            onWillProcess: () => {
              message.warn(
                '生成pdf的過程還沒有開始，可能出現問題，通知一下智圓'
              )
            },
          })
        }
      >
        下載標示資料
        {remainSecsForLabelPdfGen > 0
          ? ` (生成pdf中，可能還剩${remainSecsForLabelPdfGen})秒 `
          : null}
      </Button>

      {warning.length === 0 ? (
        <Divider orientation='left' className='mt-5'>
          沒有庫存警告資料
        </Divider>
      ) : (
        <>
          <Divider orientation='left' className='mt-5'>
            以下為庫存警告資料
          </Divider>
          <Button
            icon={<DownloadOutlined />}
            size='small'
            className='mt-1'
            danger
            onClick={downloadStockWarning}
          >
            下載庫存警告
          </Button>
        </>
      )}

      <Table
        className='mt-4 mb-1'
        columns={warningColumns}
        dataSource={warning}
        size='small'
      />
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    totalText: state.orderProcess.generate.view.check.text,
    orderRecordId: state.orderProcess.generate.view.check.orderRecordId,
    warning: state.orderProcess.generate.view.check.warning,
    loading: state.orderProcess.generate.view.loading,
    shippingNameLoading:
      state.orderProcess.generate.view.check.textCorresponding,
    origin: state.orderProcess.generate.view.check.origin,
  }
}

export default connect(mapStateToProps, {
  downloadStockWarning,
  doShippingNameCorrFromDing,
  updateOrderText,
  getTextFromClipBoard,
  getShippingCorrFromShopeeExcel,
})(QtyRemindStep)
