import {
  GET_DING_SHOPEE_ORDER,
  UPLOAD_DING_ITEM_IMAG_SUCCESS,
  DELETE_DING_ITEM_IMAG,
  SUCCESS_DELETE_DING_ITEM_IMAG,
  START_GET_SHOPEE_ORDER_FORM_DING,
  GET_DING_INDIVIDUAL_ITEMS,
  SUCCESS_GET_DING_INDIVIDUAL_ITEMS,
  ERROR_GET_DING_INDIVIDUAL_ITEMS,
  SEARCH_DING_INDIVIDUAL_ITEMS,
  SELECT_DING_INDIVIDUAL_ITEMS,
  UPLOAD_DING_ITEM_IMAG,
  DING_ITEM_IMAG_LOADED,
  GET_DING_ALL_ITEMS,
  SUCCESS_GET_DING_ALL_ITEMS,
  ERROR_GET_DING_ALL_ITEMS,
  SUCCESS_REQUEST_DING_SHOPEE_CORR,
  REQUEST_DING_SHOPEE_CORR,
  FAIL_REQUEST_DING_SHOPEE_CORR,
  ERROR_UPDATE_DING_ITEM_SHOPEE_CORR,
  UPDATE_DING_ITEM_SHOPEE_CORR,
  SUCCESS_UPDATE_DING_ITEM_SHOPEE_CORR,
} from './types'
import axios from 'axios'
import api from '../utils/api'
import { message } from 'antd'
import imageCompression from 'browser-image-compression'

export const getShopeeOrderFromDing = () => async (dispatch) => {
  try {
    dispatch({ type: START_GET_SHOPEE_ORDER_FORM_DING })
    const res = await api.get('api/ding/shopee_data')
    dispatch({ type: GET_DING_SHOPEE_ORDER, payload: res.data })
    return res.data
  } catch (err) {
    // dispatch({
    //   type: PICKING_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // })
    console.log(err)
  }
}
export const updateUploadIndividualItems = (fileList) => (dispatch) => {
  dispatch({ type: UPLOAD_DING_ITEM_IMAG, payload: fileList })
}

export const searchTextIndividualItems = (text) => async (dispatch) => {
  dispatch({ type: SEARCH_DING_INDIVIDUAL_ITEMS, payload: text })
}

export const selectIndividualItems = (a1id) => async (dispatch) => {
  dispatch({ type: SELECT_DING_INDIVIDUAL_ITEMS, payload: a1id })
}

export const getDingIndividualItems = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DING_INDIVIDUAL_ITEMS })
    const res = await api.get('api/ding/items?type=individual&neglected=false')
    dispatch({
      type: SUCCESS_GET_DING_INDIVIDUAL_ITEMS,
      payload: res.data,
    })
  } catch (err) {
    message.error('無法載入鼎新品項資料，確認網路連線後重新整理試試')
    console.log(err)
    dispatch({ type: ERROR_GET_DING_INDIVIDUAL_ITEMS })
  }
}
export const getDingAllItems = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DING_ALL_ITEMS })
    const res = await api.get('api/ding/items?type=all')
    dispatch({
      type: SUCCESS_GET_DING_ALL_ITEMS,
      payload: res.data,
    })
  } catch (err) {
    message.error('無法載入鼎新品項資料，確認網路連線後重新整理試試')
    console.log(err)
    dispatch({ type: ERROR_GET_DING_ALL_ITEMS })
  }
}

export const getDingShopeeCorr = () => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_DING_SHOPEE_CORR })
    const { data } = await api.get('api/ding/shopee_corrs/')
    dispatch({ type: SUCCESS_REQUEST_DING_SHOPEE_CORR, payload: data })
  } catch (error) {
    message.error('無法載入鼎新蝦皮對應資料，確認網路連線後重新整理試試')
    console.log(error)
    dispatch({ type: FAIL_REQUEST_DING_SHOPEE_CORR })
  }
}

export const updateDingShopeeCorr = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_DING_ITEM_SHOPEE_CORR })
    const res = await api.put(`api/ding/shopee_corr`, values)
    dispatch({
      type: SUCCESS_UPDATE_DING_ITEM_SHOPEE_CORR,
      payload: res.data,
    })
  } catch (error) {
    message.error('無法更新鼎新蝦皮對應資料，請確認網路後重新整理試試')
    console.log(error)
    dispatch({ type: ERROR_UPDATE_DING_ITEM_SHOPEE_CORR })
  }
}

export const uploadDingItemImagAction =
  ({ file, onError, onProgress, onSuccess, withCredentials, id }) =>
  async (dispatch) => {
    try {
      if (!id) {
        throw new Error('沒有選擇鼎新商品')
      }
      const { data } = await api.get(`/upload/dingItemImag/${id}`)
      file = await imageCompression(file, {
        maxSizeMB: 0.5,
        onProgress: (percentCompression) =>
          onProgress(
            { percent: Math.round((percentCompression * 2) / 3).toFixed(2) },
            file
          ),
      })
      await axios.put(data.url, file, {
        withCredentials,
        headers: {
          'Content-Type': encodeURI(file.type),
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress(
            { percent: 67 + Math.round((loaded / total) * 50).toFixed(2) },
            file
          )
        },
      })
      // post fileName link to ding object
      let res = await api.post('/api/ding/addItemImag', {
        imagKey: data.key,
        id,
      })

      onSuccess(res, file)
      dispatch({ type: UPLOAD_DING_ITEM_IMAG_SUCCESS, payload: res.data })
    } catch (error) {
      onError(error)
    }
  }

export const deleteDingItemImagAction = (id, key) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DING_ITEM_IMAG, payload: id })
    const { data } = await api.delete('/upload/delete/dingItemImag', {
      id,
      key,
    })
    dispatch({
      type: SUCCESS_DELETE_DING_ITEM_IMAG,
      payload: { a1id: data.a1id, imagKey: data.imagKey },
    })
  } catch (err) {
    console.log(err)
  }
}

export const dingItemLoaded = () => (dispatch) => {
  dispatch({ type: DING_ITEM_IMAG_LOADED })
}
