import React, { useState, useEffect, useCallback } from 'react'
import {
  PageHeader,
  message,
  Input,
  Table,
  Spin,
  Empty,
  Badge,
  Descriptions,
} from 'antd'
import api from '../../../utils/api'
import './GiftEventItemSearch.css'
const { Search } = Input

export default function GiftEventItemSearch() {
  const [loading, setLoading] = useState(true)
  const [shopeeItemEventDetail, setShopeeItemEventDetail] = useState([])
  const [displayShopeeItemId, setDisplayShopeeItemId] = useState('')
  const [searchText, setSearchText] = useState('')
  const handleGetShopeeItemEventDetail = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get('api/orderProcess/events/items')
      setShopeeItemEventDetail(
        data.map((ob) => ({ ...ob, key: ob.shopeeItemId }))
      )
      setLoading(false)
    } catch (err) {
      setLoading(false)
      message.error('出錯了，通知一下智圓' + err)
    }
  }, [])
  useEffect(() => {
    handleGetShopeeItemEventDetail()
  }, [handleGetShopeeItemEventDetail])
  const displayShopeeItems = shopeeItemEventDetail.filter((ob) =>
    searchText
      ? ob.shopeeItemId.includes(searchText) ||
        ob.shopeeItemName.includes(searchText)
      : true
  )
  const displayShopeeItem = displayShopeeItems.find(
    (item) => item.shopeeItemId === displayShopeeItemId
  )
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      <PageHeader title='商品參與活動' subTitle='查詢某個商品所有參與的活動' />
      {loading ? (
        <div className='tab-tab'>
          <Spin className='spin-page' size='large' />
        </div>
      ) : (
        <div
          className='row mx-0 ml-2'
          style={{
            flexGrow: 1,
            overflow: 'hidden',
            display: 'flex',
          }}
        >
          <div
            className='px-0 col-71'
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              flexWrap: 'unset',
            }}
          >
            <Search
              onChange={(e) => {
                setTimeout(() => setSearchText(e.target.value), 0)
              }}
              placeholder='輸入商品名稱或商品編號'
            />
            <Table
              className='empty-grow-table-without-header'
              style={{
                flexBasis: 0,
                flexGrow: 1,
                flexShrink: 0,
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touchAction',
                minHeight: 'calc(100% - 32px)',
              }}
              hideSelectAll
              size='small'
              dataSource={displayShopeeItems}
              pagination={false}
              showHeader={false}
              rowClassName={(record, index) =>
                record.shopeeItemId === displayShopeeItemId
                  ? 'ant-table-row-selected clickable'
                  : 'clickable'
              }
              onRow={(record) => ({
                onClick: () => setDisplayShopeeItemId(record.shopeeItemId),
              })}
              columns={[
                {
                  dataIndex: 'shopeeItemName',
                  render: (value, record) => (
                    <div style={{ whiteSpace: 'pre' }} key={record._id}>
                      {record.events.length !== 0 ? (
                        <Badge count={record.events.length} color='#52c41a' />
                      ) : null}
                      <a
                        className='mr-1'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`https://shopee.tw/product/18173562/${record.shopeeItemId}`}
                      >
                        {record.shopeeItemId}
                      </a>

                      {value}
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div
            className='col-65 px-0 pt-4'
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              flexWrap: 'unset',
            }}
          >
            {displayShopeeItem ? (
              <div
                className='px-2'
                style={{
                  overflowY: 'auto',
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {displayShopeeItem.events.length === 0 ? (
                  <Empty description='此商品沒有參加任何活動' />
                ) : (
                  displayShopeeItem.events.map((event) => (
                    <Descriptions
                      className='pb-4'
                      title={event.name}
                      key={event.id}
                      bordered
                    >
                      <Descriptions.Item label='活動內容' span={4}>
                        {event.description}
                      </Descriptions.Item>
                      <Descriptions.Item label='活動情況' span={4}>
                        {event.onGoing ? '進行中' : '非進行中'}
                      </Descriptions.Item>
                    </Descriptions>
                  ))
                )}
              </div>
            ) : (
              <Empty description='選擇一個蝦皮商品來檢視有參加的活動' />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
