import React from 'react'
import { Route, Switch, NavLink } from 'react-router-dom'
import './PickingRecords.css'
import Container from 'react-bootstrap/Container'
import TotalNotGoods from './TotalNotGoods'
import PickingRecords from './PickingRecords'
import { Menu } from 'antd'

const PickingPage = ({ match }) => {
  return (
    <Container className='page' fluid='xl'>
      <div className='d-flex flex-column picking-page'>
        <Menu
          defaultSelectedKeys={['1']}
          mode='horizontal'
          className='inner-menu'
        >
          <Menu.Item key='1'>
            <NavLink
              className='nav-link'
              to={`${match.path}/record`}
              activeClassName='active'
            >
              揀貨單
            </NavLink>
          </Menu.Item>
          <Menu.Item key='2'>
            <NavLink
              className='nav-link'
              to={`${match.path}/notgoods`}
              activeClassName='active'
            >
              不良品調整
            </NavLink>
          </Menu.Item>
        </Menu>
        {/* <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink
            className="nav-link"
            to={`${match.path}/record`}
            activeClassName="active"
          >
            揀貨單
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            to={`${match.path}/notgoods`}
            activeClassName="active"
          >
            不良品調整
          </NavLink>
        </li>
      </ul> */}
        <Switch>
          <Route path={`${match.path}/notgoods`} component={TotalNotGoods} />
          <Route path={`${match.path}/record`} component={PickingRecords} />
        </Switch>
      </div>
    </Container>
  )
}

export default PickingPage
