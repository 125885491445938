import './MyNavbar.css';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import PropTypes from 'prop-types';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { linkTo } from '../../actions/pageNav';

const MyNavbar = ({ pageNav, linkTo }) => {
  const onClick = (e) => {
    linkTo(e.target.name);
  };

  return (
    <Navbar
      className='pt-2 pb-1 p-1 my-navbar'
      collapseOnSelect
      expand='lg'
      bg='dark'
      variant='dark'
    >
      <Container fluid className='justify-content-center p-0'>
        <Col lg={136}>
          <Row className='justify-content-between'>
            <Link
              onClick={() => linkTo('/')}
              className='navbar-brand mynavIcon'
              to='/'
            >
              <img
                alt=''
                src='/get_started128.png'
                width='30'
                height='30'
                className='d-inline-block mx-2 mb-1'
              />{' '}
              Touch Whale
            </Link>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className='mr-auto pl-lg-0 collapse-nav-total'>
                <Link
                  className={pageNav.picking ? pageNav.picking.join(' ') : ''}
                  name='picking'
                  onClick={(e) => onClick(e)}
                  role='button'
                  to='/picking/record'
                >
                  揀貨
                </Link>
                <Link
                  className={pageNav.vocab ? pageNav.vocab.join(' ') : ''}
                  name='vocab'
                  onClick={(e) => onClick(e)}
                  role='button'
                  to='/vocab'
                >
                  單字
                </Link>
                <Link
                  className={
                    pageNav.store_data ? pageNav.store_data.join(' ') : ''
                  }
                  name='store_data'
                  onClick={(e) => onClick(e)}
                  role='button'
                  to='/store_data/dashboard'
                >
                  前台觀察
                </Link>
                <Link
                  className={
                    pageNav.purchasing ? pageNav.purchasing.join(' ') : ''
                  }
                  name='purchasing'
                  onClick={(e) => onClick(e)}
                  role='button'
                  to='/purchasing/suggest'
                >
                  採購
                </Link>
                <Link
                  className={
                    pageNav.order_processing
                      ? pageNav.order_processing.join(' ')
                      : ''
                  }
                  name='order_processing'
                  onClick={(e) => onClick(e)}
                  role='button'
                  to='/order_processing/ding'
                >
                  寫單
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Row>
        </Col>
      </Container>
    </Navbar>
  );
};

MyNavbar.propTypes = {
  linkTo: PropTypes.func.isRequired,
  pageNav: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pageNav: state.pageNav,
});

export default connect(mapStateToProps, { linkTo })(MyNavbar);
