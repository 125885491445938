import React, { useState } from 'react'
import { Button, Form, DatePicker, Upload, List, Typography } from 'antd'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { downloadPurchaseSuggestion } from '../../../actions/purchaseAction'
import moment from 'moment'
import api from '../../../utils/api'

const Suggest = ({ downloadPurchaseSuggestion, purchaseExcelLoading }) => {
  const [range, setRange] = useState([])
  const [suggestionErrors, setSuggestionErrors] = useState([])
  const { RangePicker } = DatePicker

  const uploadDataCenterExcel = async ({
    action,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) => {
    const formData = new FormData()
    formData.append('purchaseSuggestionCleaned', file)
    try {
      setSuggestionErrors([])
      await api.uploadAndDownload('api/purchase/upload/suggestion', formData, {
        withCredentials,
        headers,
        onUploadProgress: ({ total, loaded }) => {
          onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            file
          )
        },
      })
      onSuccess(null, file)
    } catch (error) {
      onError(error)
      try {
        let errorJsonBack = JSON.parse(await error.response.data.text()).errors
        if (!!errorJsonBack.length) {
          setSuggestionErrors(errorJsonBack)
        } else {
          setSuggestionErrors([{ message: '不知道原因的錯誤' }])
        }
      } catch (error1) {
        setSuggestionErrors([{ message: '不知道原因的錯誤' }])
      }
    }
  }
  return (
    <div className='my-5 tab100'>
      <Form
        name='downloadPurchaseSuggestion'
        layout='inline'
        onFinish={() =>
          downloadPurchaseSuggestion(range ? range : [moment(), moment()])
        }
        className='ml-4'
      >
        <Form.Item label='銷量計算的時間區間'>
          <RangePicker
            size='small'
            onChange={(dates) => {
              setRange(dates)
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            size='small'
            type='primary'
            shape='round'
            icon={<DownloadOutlined />}
            htmlType='submit'
            loading={purchaseExcelLoading}
          >
            下載建議進貨資料
          </Button>
        </Form.Item>
      </Form>
      <Upload name='touch_whale_excel' customRequest={uploadDataCenterExcel}>
        <Button className='ml-4 mt-4' icon={<UploadOutlined />}>
          {' '}
          上傳採購建議資料 Excel
        </Button>
      </Upload>
      {suggestionErrors.length ? (
        <List
          className='ml-4 mt-4 errorList'
          dataSource={suggestionErrors}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text mark>錯誤訊息： {item.message}</Typography.Text>
            </List.Item>
          )}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    purchaseExcelLoading: state.purchase.purchase_excel.loading,
    test_json: state.ding.test_json,
  }
}

export default connect(mapStateToProps, { downloadPurchaseSuggestion })(Suggest)
