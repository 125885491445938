import {
  DOWNLOAD_PURCHASE_SUGGESTION,
  START_DOWNLOAD_PURCHASE_SUGGESTION,
  FAIL_DOWNLOAD_PURCHASE_SUGGESTION,
} from '../actions/types'
const initialState = {
  purchase_excel: { loading: false },
}

export default function purchaseReducer(state = initialState, action) {
  const { type } = action

  switch (type) {
    case START_DOWNLOAD_PURCHASE_SUGGESTION:
      return {
        ...state,
        purchase_excel: { ...state.purchase_excel, loading: true },
      }
    case DOWNLOAD_PURCHASE_SUGGESTION:
      return {
        ...state,
        purchase_excel: { ...state.purchase_excel, loading: false },
      }
    case FAIL_DOWNLOAD_PURCHASE_SUGGESTION:
      return {
        ...state,
        purchase_excel: { ...state.purchase_excel, loading: false },
      }
    default:
      return state
  }
}
