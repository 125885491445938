import api from '../utils/api'
import {
  REQUEST_ORDERS_FROM_DING,
  SUCESS_REQUEST_ORDERS_FROM_DING,
  FAIL_REQUEST_ORDERS_FROM_DING,
  GENERATE_ALL_CORRESPOND,
  GENERATE_HAS_NO_CORRESPOND,
  DO_GENERATE_CORRESPOND,
  CLEAR_GENERATE,
  RENEW_RANGE,
  GIFT_SET,
  RENEW_GIFT_SET,
  SEND_ORDER_GENERATE_DATA,
  SUCCESS_SEND_ORDER_GENERATE_DATA,
  SET_UP_EVENT_EDIT,
  FAIL_SET_UP_EVENT_EDIT,
  SUCCESS_SET_UP_EVENT_EDIT,
  SUCCESS_REQUEST_DING_SHOPEE_CORR,
  REQUEST_DING_SHOPEE_CORR,
  CHANGE_EVENT_ITEM,
  SUCCESS_CHANGE_EVENT_ITEM,
  FAIL_CHANGE_EVENT_ITEM,
  RENEW_ALL_DATA_FROM_DING,
  SUCCESS_RENEW_ALL_DATA_FROM_DING,
  FAIL_RENEW_ALL_DATA_FROM_DING,
  BACK_TO_RANGE,
  BACK_TO_GIFT_SET,
  FAIL_DO_SHIPPINGNAME_CORR,
  DO_SHIPPINGNAME_CORR,
  SUCESS_DO_SHIPPINGNAME_CORR,
  UPDATE_ORDER_TEXT,
  UPLOAD_EXCEL_ORDERS_FROM_SHOPEE,
  SUCCESS_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE,
  FAIL_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE,
} from './types'
import { message } from 'antd'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import XLSX from 'xlsx'
import { Validator } from 'jsonschema'

const readUploadedFileAsText = (inputFile) => {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    temporaryFileReader.onload = (e) => {
      let data
      if (!e) {
        data = new Uint8Array(temporaryFileReader.result)
      } else {
        data = new Uint8Array(e.target.result)
      }
      resolve(data)
    }
    temporaryFileReader.readAsArrayBuffer(inputFile)
  })
}

export const uploadJJRelatedData =
  ({ file, onError, headers, onProgress, onSuccess, withCredentials }) =>
  async (dispatch) => {
    try {
      const formData = new FormData()
      formData.append('jj', file)
      await api.post('api/orderProcess/shopee_corrs/upload', formData, {
        withCredentials,
        headers: {
          'Content-Type': encodeURI(file.type),
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            file
          )
        },
      })
      onSuccess(null, file)
    } catch (err) {
      onError(err)
    }
  }

export const uploadOrdersFromShopeeExcel =
  ({ file, onError, headers, onProgress, onSuccess, withCredentials }) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_EXCEL_ORDERS_FROM_SHOPEE })
      const formData = new FormData()
      formData.append('shopee_order_excel', file)
      const { data } = await api.post(
        'api/orderProcess/shopee_order/upload',
        formData,
        {
          withCredentials,
          headers: {
            'Content-Type': encodeURI(file.type),
          },
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file
            )
          },
        }
      )
      onSuccess(null, file)
      dispatch({
        type: SUCCESS_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE,
        payload: { id: data },
      })
    } catch (err) {
      message.error('出了一點問題，重新整理看看')
      message.error(err.response.data.message)
      onError(err)
      dispatch({ type: FAIL_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE })
    }
  }

export const getShippingCorrFromShopeeExcel =
  ({
    file,
    onError,
    headers,
    onProgress,
    onSuccess,
    withCredentials,
    oldText,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: DO_SHIPPINGNAME_CORR, payload: 'shopee' })
      let fileBuffer = await readUploadedFileAsText(file)
      let shippingcorr = await XLSX.read(fileBuffer, { type: 'array' })
      let sheetNames = shippingcorr.SheetNames
      let sheetIndex = 1
      shippingcorr = XLSX.utils.sheet_to_json(
        shippingcorr.Sheets[sheetNames[sheetIndex - 1]]
      )
      let shopeeExcelSchema = {
        id: 'shopeeExcelSchema',
        type: 'array',
        items: {
          properties: {
            '訂單編號 (單)': { type: 'string' },
            '包裹查詢號碼 (單)': { type: 'string' },
          },
          required: ['訂單編號 (單)'],
        },
      }
      let vd = new Validator()
      let rvd = vd.validate(shippingcorr, shopeeExcelSchema)
      if (rvd.valid) {
        let shippingcorrMap = new Map()
        for (let i = 0; i < shippingcorr.length; i++) {
          shippingcorrMap.set(
            shippingcorr[i]['訂單編號 (單)'],
            shippingcorr[i]['包裹查詢號碼 (單)']
          )
        }
        shippingcorr = Object.fromEntries(shippingcorrMap)

        let newText = oldText.replace(
          /(超商單號：)(\S+)(\s+\S+\s+訂單編號：)(\S+)/g,
          (match, pre, conv, middle, no) => {
            let newText
            if (shippingcorr[no]) {
              // has corr
              newText = pre + shippingcorr[no] + middle + no
            } else {
              // no corr
              newText = pre + '沒有生成到，或是已經被顧客取消了' + middle + no
            }
            return newText
          }
        )
        dispatch({ type: SUCESS_DO_SHIPPINGNAME_CORR, payload: newText })
        message.success('完成對應囉～～')
        onSuccess(null, file)
      } else {
        console.log(rvd.errors)
        throw new Error('Excel 格式好像有問題喔～～')
      }
    } catch (error) {
      console.log(error)
      message.error('出了一點問題，重新整理看看')
      message.error(error.toString(), 2)
      onError(error)
      dispatch({ type: FAIL_DO_SHIPPINGNAME_CORR })
    }
  }

export const requestOrdersFromDing = () => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_ORDERS_FROM_DING })
    const { data } = await api.get('api/orderProcess/orderGenerate/ding_shin')
    dispatch({ type: SUCESS_REQUEST_ORDERS_FROM_DING, payload: { id: data } })
  } catch (error) {
    message.error('出了一點問題，重新整理看看')
    message.error(error.toString(), 2)
    console.log(error)
    dispatch({ type: FAIL_REQUEST_ORDERS_FROM_DING })
  }
}

export const generateNext = () => async (dispatch, getState) => {
  try {
    let state = getState()
    let generateId = state.orderProcess.generate.generateId
    switch (state.orderProcess.generate.view.current) {
      case 0:
        if (generateId) {
          dispatch({ type: DO_GENERATE_CORRESPOND })
          let { data } = await api.get(
            `api/orderProcess/orderGenerate/doCorr/${generateId}`
          )
          switch (data.status) {
            case 'corr_success':
              dispatch({ type: GENERATE_ALL_CORRESPOND, payload: data })
              break
            case 'no_correspondence':
              dispatch({ type: GENERATE_HAS_NO_CORRESPOND, payload: data })
              break
            default:
              throw new Error('Unrecognized status')
          }
        } else {
          dispatch({ type: CLEAR_GENERATE })
          message.error('出了一點問題，重新下載試試', 2)
        }
        break
      case 1:
        break
      case 2:
        dispatch({ type: GIFT_SET })
        break
      case 3:
        // request data
        if (generateId) {
          dispatch({ type: SEND_ORDER_GENERATE_DATA })
          let { data } = await api.post(
            `api/orderProcess/orderGenerate/checkAndGenerate/${generateId}`,
            {
              range: state.orderProcess.generate.view.range,
              gift: state.orderProcess.generate.view.gift,
            }
          )
          dispatch({ type: SUCCESS_SEND_ORDER_GENERATE_DATA, payload: data })
        } else {
          dispatch({ type: CLEAR_GENERATE })
          message.error('出了一點問題，重新下載試試', 2)
        }

        break
      case 4:
        dispatch({ type: CLEAR_GENERATE })
        break
      default:
        break
    }
  } catch (error) {
    message.error('出了一點問題，確認網路以後重新整理看看')
    message.error(error.toString(), 2)
    dispatch({ type: CLEAR_GENERATE })
    console.log(error)
  }
}
export const generatePrev = () => async (dispatch, getState) => {
  let state = getState()
  switch (state.orderProcess.generate.view.current) {
    case 0:
      dispatch({ type: CLEAR_GENERATE })
      break
    case 1:
      dispatch({ type: CLEAR_GENERATE })
      break
    case 2:
      dispatch({ type: CLEAR_GENERATE })
      break
    case 3:
      dispatch({ type: BACK_TO_RANGE })
      break
    case 4:
      dispatch({ type: BACK_TO_GIFT_SET })
      break
    default:
      dispatch({ type: CLEAR_GENERATE })
      break
  }
}

export const renewRange = (from, to, startNum) => (dispatch) => {
  dispatch({ type: RENEW_RANGE, payload: { from, to, startNum } })
}

export const renewGiftSet = (event) => (dispatch) => {
  dispatch({ type: RENEW_GIFT_SET, payload: event })
}

export const setUpEventEdit = () => async (dispatch) => {
  try {
    dispatch({ type: SET_UP_EVENT_EDIT })
    dispatch({ type: REQUEST_DING_SHOPEE_CORR })
    let [{ data: corr }, { data: events }] = await Promise.all([
      api.get('api/ding/shopee_corrs/'),
      api.get('api/orderProcess/events'),
    ])
    dispatch({ type: SUCCESS_REQUEST_DING_SHOPEE_CORR, payload: corr })
    dispatch({ type: SUCCESS_SET_UP_EVENT_EDIT, payload: events })
  } catch (error) {
    message.error('出了一點問題，確認網路以後重新整理看看')
    message.error(error.toString(), 2)
    dispatch({ type: FAIL_SET_UP_EVENT_EDIT })
    console.log(error)
  }
}

export const changeEventItem =
  (eventId, newCheckTF, tag, corr_id) => async (dispatch) => {
    try {
      // tag: 'default' remove item (also all tags) else remove identifier === tag
      // corr_id => [eventId]
      dispatch({
        type: CHANGE_EVENT_ITEM,
        payload: { eventId, newCheckTF, tag, corr_id },
      })
      await api.put('api/orderProcess/events/items', {
        eventId,
        newCheckTF,
        tag,
        corr_id,
      })
      dispatch({ type: SUCCESS_CHANGE_EVENT_ITEM })
    } catch (error) {
      message.error('出了一點問題，確認網路以後重新整理看看')
      message.error(error.toString(), 2)
      dispatch({ type: FAIL_CHANGE_EVENT_ITEM })
      console.log(error)
    }
  }

export const reNewAllDataFromDing = () => async (dispatch) => {
  try {
    dispatch({ type: RENEW_ALL_DATA_FROM_DING })
    await api.get('api/orderProcess/getAllDataFromDing')
    dispatch({ type: SUCCESS_RENEW_ALL_DATA_FROM_DING })
  } catch (error) {
    message.error('出了一點問題，確認網路以後重新整理看看')
    message.error(error.toString(), 2)
    dispatch({ type: FAIL_RENEW_ALL_DATA_FROM_DING })
    console.log(error)
  }
}

export const downloadStockWarning = () => async (dispatch, getState) => {
  const state = getState()
  let warning = state.orderProcess.generate.view.check.warning
  const wb = new ExcelJS.Workbook()

  const ws = wb.addWorksheet('庫存警告')

  ws.columns = [
    { header: '鼎新品號', key: 'a1id' },
    { header: '鼎新品名', key: 'a1name' },
    { header: '鼎新庫存', key: 'qty' },
    { header: '安全庫存', key: 'safeQty' },
    { header: '安全庫存抓的天數', key: 'safeQtyTotalDays' },
    { header: '安全庫存銷量統計天數', key: 'safeQtyDataDays' },
    { header: '今日銷量', key: 'todaySales' },
    { header: '建議進量', key: 'suggestBuyNumber' },
  ]
  ws.addRows(warning)

  const buf = await wb.xlsx.writeBuffer()

  saveAs(new Blob([buf]), '庫存警告.xlsx')
}

export const doShippingNameCorrFromDing = (oldText) => async (dispatch) => {
  try {
    dispatch({ type: DO_SHIPPINGNAME_CORR, payload: 'ding' })
    let { data: shippingcorr } = await api.get(
      'api/orderProcess/orderGenerate/ding_shin_conv'
    )
    let newText = oldText.replace(
      /(超商單號：)(\S+)(\s+\S+\s+訂單編號：)(\S+)/g,
      (match, pre, conv, middle, no) => {
        let newText
        if (shippingcorr[no]) {
          // has corr
          newText = pre + shippingcorr[no] + middle + no
        } else {
          // no corr
          newText = pre + '沒有生成到，或是已經被顧客取消了' + middle + no
        }
        return newText
      }
    )
    message.success('完成對應囉～～')
    dispatch({ type: SUCESS_DO_SHIPPINGNAME_CORR, payload: newText })
  } catch (error) {
    message.error('出了一點問題，無法對應，確認網路以後重新整理看看')
    message.error(error.toString(), 2)
    dispatch({ type: FAIL_DO_SHIPPINGNAME_CORR })
    console.log(error)
  }
}

export const updateOrderText = (text) => (dispatch) => {
  dispatch({ type: UPDATE_ORDER_TEXT, payload: text })
}
