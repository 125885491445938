import './DataCorrStep.style.css'
import React from 'react'
import { connect } from 'react-redux'
import { Spin, Table } from 'antd'

const noCorrColumn = [
  {
    title: '蝦皮商品名稱',
    dataIndex: 'ProductName',
    width: '50%',
  },
  {
    title: '蝦皮選項名稱',
    dataIndex: 'Spec',
    width: '50%',
  },
]

const DataCorrStep = ({ loading, noCorr, origin }) => {
  return (
    <>
      {loading ? (
        <Spin className='spin-page' size='large' />
      ) : (
        <div>
          <h6 className='m-3 mt-4'>
            {' '}
            {noCorr.length === 0
              ? '品項都有對到優'
              : `以下的品項沒有對應喔，先去「${origin}」對應完之後，從上一步開始優`}
          </h6>
          <Table size='small' columns={noCorrColumn} dataSource={noCorr} />
        </div>
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  let origin
  switch (state.orderProcess.generate.view.corr.origin) {
    case 'ding':
      origin = '鼎新雲端電商/蝦皮對應'
      break
    case 'shopee':
      origin = '蝦皮商品的主商品貨號、商品選項貨號'
      break
    default:
      origin = '不確定？？'
  }
  return {
    loading: state.orderProcess.generate.view.corr.loading,
    noCorr: state.orderProcess.generate.view.corr.noCorr,
    origin,
  }
}
export default connect(mapStateToProps, null)(DataCorrStep)
