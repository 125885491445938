import React from 'react'
import { Route, Switch, NavLink, useLocation } from 'react-router-dom'
import './store_data.css'
import { Menu } from 'antd'
import Dashboard from './pages/Dashboard'
import TestApi from './pages/TestApi'
import Manage from './pages/Manage'
import Living from './pages/Living'

const StoreData = ({ match }) => {
  let location = useLocation()
  let tabRegs = new RegExp(`${match.path}\\/(?<path>\\w+)`)
  let path = tabRegs.exec(location.pathname).groups.path
  return (
    <div className='d-flex flex-column store-data-page'>
      <Menu selectedKeys={[path]} mode='horizontal' className='inner-menu'>
        <Menu.Item key='dashboard'>
          <NavLink
            className='nav-link'
            to={`${match.path}/dashboard`}
            activeClassName='active'
          >
            下載資料
          </NavLink>
        </Menu.Item>
        <Menu.Item key='living'>
          <NavLink
            className='nav-link'
            to={`${match.path}/living`}
            activeClassName='active'
          >
            居家生活趨勢
          </NavLink>
        </Menu.Item>
        <Menu.Item key='test'>
          <NavLink
            className='nav-link'
            to={`${match.path}/test`}
            activeClassName='active'
          >
            API測試
          </NavLink>
        </Menu.Item>
        <Menu.Item key='manage'>
          <NavLink
            className='nav-link'
            to={`${match.path}/manage`}
            activeClassName='active'
          >
            爬蟲管理
          </NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path={`${match.path}/dashboard`} component={Dashboard} />
        <Route path={`${match.path}/test`} component={TestApi} />
        <Route path={`${match.path}/manage`} component={Manage} />
        <Route path={`${match.path}/living`} component={Living} />
      </Switch>
    </div>
  )
}

export default StoreData
